@keyframes progress-bar-stripes {
    from {
        background-position: $progress-height 0;
    }
    to {
        background-position: 0 0;
    }
}

.dds__progress {
    @include border-radius($progress-border-radius);
    @include box-shadow($progress-box-shadow);
    background: $progress-bg;
    display: flex;
    font-size: $progress-font-size;
    font-size: 1rem;
    height: $progress-height;
    height: 100%;
    overflow: hidden;

    ol {
        display: flex;
        flex-direction: row;
        list-style: none;
        padding-left: 0;
        width: 100%;

        > li {
            width: 100%;
        }
    }

    .dds__btn-link {
        display: flex;
        justify-content: center;

        &:hover {
            text-decoration: underline;
        }
    }

    .dds__progress-bar-text {
        display: flex;
        justify-content: center;
    }

    a {
        text-decoration: none;

        &.dds__disabled {
            pointer-events: none;

            svg.dds__check {
                visibility: hidden;
            }

            .dds__btn-link {
                color: $dell-black;
            }
        }

        &:focus {
            outline: 0.0625rem solid $dell-black;
            outline-offset: -0.0625rem;
        }
    }

    .dds__progress-bar {
        align-items: center;

        svg.dds__check {
            fill: $dell-white;
            height: 1rem;
            width: 1rem;
        }

        &.dds__bar-complete {
            background-color: $dell-sea-blue;
        }

        &.dds__bar-in-progress {
            background-color: $dell-sky;
        }

        &.dds__bar-not-started {
            background-color: $dell-white;
        }

        &.dds__bar-disabled {
            background-color: $dell-gray;
        }

        &.dds__bar-progress-disabled {
            background-color: $dell-granite;
        }
    }
}

.dds__progress-bar {
    @include transition($progress-bar-transition);
    background-color: transparent;
    box-shadow: inset 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.4), inset 0 -0.0625rem 0 rgba(0, 0, 0, 0.15);
    color: $progress-bar-color;
    display: flex;
    flex-direction: column;
    height: 1.25rem;
    justify-content: center;
    text-align: center;
    white-space: nowrap;

    .dds__progress-bar-standard {
        background-color: $dell-sea-blue;
        height: inherit;
    }
}

.dds__progress-bar-striped {
    @include gradient-striped();
    background-size: $progress-height $progress-height;
}

.dds__progress-bar-animated {
    animation: progress-bar-stripes $progress-bar-animation-timing;
}

.dds__dropdown-progress {
    .dds__dropdown-item {
        //default
        color: $dell-sea-blue;

        &.dds__disabled {
            //completed stage
            color: $dell-black;

            svg.dds__check {
                fill: $dell-black;
            }

            //incomplete stage
            &:not(.dds__completed) {
                color: $dropdown-link-disabled-color;

                svg.dds__check {
                    visibility: hidden;
                }
            }
        }

        // current
        &.dds__current {
            color: $dell-black;

            svg.dds__check {
                visibility: hidden;
            }
        }
    }

    .dds__dropdown-progress-no-checkmark {
        padding-left: 3.125rem;
    }
}

.dds__progress-wrapper-thin {
    flex-direction: row;

    .dds__col-2 {
        padding-left: 0.625rem;
        padding-right: 0.625rem;

        .dds__progress-label-thin {
            margin-top: 0.9375rem;
        }

        a,
        span {
            color: $dell-moon;
            display: inline;

            &:not(.dds__progress-thin-text):not(.dds__sr-only):hover {
                cursor: not-allowed;
            }

            .dds__check {
                display: none;
                fill: $dell-moon;
                height: 1rem;
                margin-right: 0.25rem;
                width: 1rem;
            }
        }

        .dds__progress-bar-thin {
            background-color: $dell-moon;
            flex: 1 1 auto;
            height: 0.1875rem;
        }

        .dds__progress-circle {
            background-color: $dell-sea-blue;
            display: none;
            height: 1rem;
            min-width: 1rem;
            position: relative;
            width: 1rem;
        }

        &.dds__completed {
            .dds__progress-bar-thin {
                background-color: $dell-sea-blue;
            }

            .dds__progress-label-thin {
                a {
                    .dds__progress-thin-text {
                        color: $dell-sea-blue;
                    }

                    .dds__check {
                        display: inline;
                        fill: $dell-sea-blue;
                    }

                    &:hover {
                        cursor: pointer;
                        text-decoration: none;

                        span {
                            color: $dell-midnight;
                            text-decoration: underline;
                        }

                        svg {
                            fill: $dell-midnight;
                        }
                    }
                }

                span {
                    color: $dell-black;

                    .dds__check {
                        display: inline;
                        fill: $dell-black;
                    }
                }
            }
        }

        &.dds__current {
            top: -0.40625rem; //6.5 pixels, which does matter when zooming
            .dds__progress-bar-thin:first-of-type {
                background-color: $dell-sea-blue;
            }

            .dds__progress-circle {
                display: block;
            }

            .dds__progress-label-thin {
                margin-top: 0.40625rem;

                span:hover {
                    cursor: auto;
                }

                .dds__progress-thin-text {
                    color: $dell-black;
                }
            }
        }
    }
}

.dds__progress-thin-dropdown {
    display: none;
}

@media screen and (max-width: 767.98px) {
    .dds__progress {
        .dds__btn-link {
            display: none;
        }

        .dds__progress-bar-text {
            display: none;
        }
    }

    .dds__progress-wrapper-thin {
        .dds__col-2 {
            padding-left: 0.3125rem;
            padding-right: 0.3125rem;

            .dds__progress-label-thin {
                margin-top: 0.3125rem;

                .dds__progress-thin-text {
                    display: none;
                }
            }
        }
    }

    .dds__progress-thin-dropdown {
        display: block;
    }
}
