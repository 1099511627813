@mixin form-control-focus() {
    &:focus {
        color: $input-focus-color;
        background-color: $input-focus-bg;
        border-color: $input-focus-border-color;
        outline: 0;
        @if $enable-shadows {
            box-shadow: $input-box-shadow, $input-focus-box-shadow;
        } @else {
            box-shadow: $input-focus-box-shadow;
        }
    }
}

@mixin form-validation-state($state, $color) {
    .dds__#{$state}-feedback {
        display: none;
        width: 100%;
        margin-top: $form-feedback-margin-top;
        font-size: $form-feedback-font-size;
        color: $color;
    }

    .dds__#{$state}-tooltip {
        position: absolute;
        top: 100%;
        z-index: 5;
        display: none;
        max-width: 100%;
        padding: $tooltip-padding-y $tooltip-padding-x;
        margin-top: 0.1rem;
        font-size: $tooltip-font-size;
        line-height: $line-height-base;
        color: color-yiq($color);
        background-color: rgba($color, $tooltip-opacity);
        @include border-radius($tooltip-border-radius);
    }

    .dds__form-control,
    .dds__custom-select {
        .dds__was-validated &:#{$state},
        &.dds__is-#{$state} {
            border-color: $color;

            &:focus {
                border-color: $color;
                box-shadow: 0 0 0 $input-focus-width rgba($color, 0.25);
            }

            ~ .dds__#{$state}-feedback,
            ~ .dds__#{$state}-tooltip {
                display: block;
            }
        }
    }

    .dds__form-control-file {
        .dds__was-validated &:#{$state},
        &.dds__is-#{$state} {
            ~ .dds__#{$state}-feedback,
            ~ .dds__#{$state}-tooltip {
                display: block;
            }
        }
    }

    .dds__form-check-input {
        .dds__was-validated &:#{$state},
        &.dds__is-#{$state} {
            ~ .dds__form-check-label {
                color: $color;
            }

            ~ .dds__#{$state}-feedback,
            ~ .dds__#{$state}-tooltip {
                display: block;
            }
        }
    }

    .dds__custom-control-input {
        .dds__was-validated &:#{$state},
        &.dds__is-#{$state} {
            ~ .dds__custom-control-label {
                color: $color;

                &::before {
                    background-color: lighten($color, 25%);
                }
            }

            ~ .dds__#{$state}-feedback,
            ~ .dds__#{$state}-tooltip {
                display: block;
            }

            &:checked {
                ~ .dds__custom-control-label::before {
                    @include gradient-bg(lighten($color, 10%));
                }
            }

            &:focus {
                ~ .dds__custom-control-label::before {
                    box-shadow: 0 0 0 1px $body-bg, 0 0 0 $input-focus-width rgba($color, 0.25);
                }
            }
        }
    }
    .dds__custom-file-input {
        .dds__was-validated &:#{$state},
        &.dds__is-#{$state} {
            ~ .dds__custom-file-label {
                border-color: $color;

                &::after {
                    border-color: inherit;
                }
            }

            ~ .dds__#{$state}-feedback,
            ~ .dds__#{$state}-tooltip {
                display: block;
            }

            &:focus {
                ~ .dds__custom-file-label {
                    box-shadow: 0 0 0 $input-focus-width rgba($color, 0.25);
                }
            }
        }
    }
}

@mixin mixin_dds__msthd-search-input() {
    @extend .dds__input-group-text;
}

@mixin mixin_dds__spinbox {
    display: flex;

    .dds__spinbox-btn {
        @include mixin_dds__btn-secondary;
        background-color: $dell-quartz;
        padding: 0.0625rem 6px !important;

        svg {
            stroke-width: 2;
            stroke: $dell-sea-blue;
            fill: $dell-sea-blue;
            width: 1rem;
            height: 1rem;
        }

        &[disabled],
        &.dds__disabled {
            svg {
                stroke: $dell-granite;
                fill: $dell-granite;
            }
        }
        &:not(:disabled):not(.dds__disabled):hover {
            svg {
                stroke: $dell-midnight;
                fill: $dell-midnight;
            }
        }
    }

    .dds__spinbox-input {
        text-align: center;
        border: 0.0625rem solid $input-border-color;
        padding: 0.375rem 0.0625rem;
    }
}
