// stylelint-disable declaration-no-important, selector-list-comma-newline-after
//
// Headings
//
// Unstyled keeps list items block level, just removes default browser padding and list-style
// @import "../../node_modules/bootstrap/scss/mixins/lists";

// Moved to reboot
// input,
// button,
// select,
// optgroup,
// textarea {
//   margin: 0; // Remove the margin in Firefox and Safari
//   font-family: $font-family-sans-serif;
//   font-size: inherit;
//   line-height: inherit;
// }

// h1, Moved to reboot
// h2,
// h3,
.dds__h1,
.dds__h2,
.dds__h3 {
    color: $headings-color;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    margin-bottom: $headings-margin-bottom;
}

// h4, Moved to reboot
// h5,
// h6,
.dds__h4,
.dds__h5,
.dds__h6 {
    color: $headings-color;
    font-family: $headings-font-family;
    font-weight: $font-weight-normal;
    line-height: $headings-line-height;
    margin-bottom: $headings-margin-bottom;
}

// h1, Moved to reboot
.dds__h1 {
    font-size: $h1-font-size;
    font-weight: 300;
}

// h2, Moved to reboot
.dds__h2 {
    font-size: $h2-font-size;
    font-weight: 300;
}

// h3, Moved to reboot
.dds__h3 {
    font-size: $h3-font-size;
    font-weight: 300;

    &.dds__feature {
        font-weight: 300;
    }
}

// h4, Moved to reboot
.dds__h4 {
    font-size: $h4-font-size;
    font-weight: 400;
}

// h5, Moved to reboot
.dds__h5 {
    font-size: $h5-font-size;
    font-weight: 400;
}

// h6, Moved to reboot
.dds__h6 {
    font-size: $h6-font-size;
    font-weight: 400;
}

.dds__lead {
    font-size: $lead-font-size;
    font-weight: $lead-font-weight;
}

// Type display classes
.dds__display-1 {
    font-size: $display1-size;
    font-weight: $display1-weight;
    line-height: $display-line-height;
}

.dds__display-2 {
    font-size: $display2-size;
    font-weight: $display2-weight;
    line-height: $display-line-height;
}

.dds__display-3 {
    font-size: $display3-size;
    font-weight: $display3-weight;
    line-height: $display-line-height;
}

.dds__display-4 {
    font-size: $display4-size;
    font-weight: $display4-weight;
    line-height: $display-line-height;
}

//
// Horizontal rules
// Moved to reboot
// hr {
//   margin-top: $hr-margin-y;
//   margin-bottom: $hr-margin-y;
//   border: 0;
//   border-top: $hr-border-width solid $hr-border-color;
// }

//
// Emphasis
//
//small, moved to reboot
.dds__small {
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    line-height: $small-line-height;
}

//mark, moved to reboot
.dds__mark {
    background-color: $mark-bg;
    padding: $mark-padding;
}

//
// Lists
//
.dds__list-unstyled {
    @include list-unstyled;
}

// Inline turns list items into inline-block
.dds__list-inline {
    @include list-unstyled;
}

.dds__list-inline-item {
    display: inline-block;

    &:not(:last-child) {
        margin-right: $list-inline-padding;
    }
}

//
// Misc
//
// Builds on `abbr`
.dds__initialism {
    font-size: 90%;
    text-transform: uppercase;
}

// Blockquotes
.dds__blockquote {
    font-size: $blockquote-font-size;
    margin-bottom: $spacer;
}

.dds__blockquote-footer {
    color: $blockquote-small-color;
    display: block;
    font-size: 80%; // back to default font-size

    &::before {
        content: "\2014 \00A0"; // em dash, nbsp
    }
}

.dds__bold-16 {
    font-size: 1rem; // ~16px
    font-weight: 700;
    line-height: 1.25rem; // ~20px
}

.dds__bold-14 {
    font-size: 0.875rem; // ~14px
    font-weight: 700;
    line-height: 1.25rem; // ~20px
}

.dds__bold-12 {
    font-size: 0.75rem; // ~12px
    font-weight: 700;
    line-height: 0.9375rem; // ~15px
}

// Dell Font Ramp
// ------------------------------------------
// font ramp and double byte characters based on media queries

@include media-breakpoint-up(xs) {
    // h1, Moved to reboot
    .dds__h1 {
        font-size: 1.5rem; // ~24px
        line-height: 1.75rem; // ~28px
        // font-weight: 300;
    }

    // h2, Moved to reboot
    .dds__h2 {
        font-size: 1.25rem; // ~20px
        line-height: 1.5rem; // ~24px
        // font-weight: 300;
    }

    // h3, Moved to reboot
    .dds__h3 {
        font-size: 1.0625rem; // ~17px
        line-height: 1.25rem; // ~20px
        // font-weight: 300;

        &.dds__feature {
            font-size: 1.125rem; // ~18px
            line-height: 1.375rem; // ~22px
            // font-weight: 300;
        }
    }

    // h4, Moved to reboot
    .dds__h4 {
        font-size: 1rem; // ~16px
        line-height: 1.25rem; // ~20px
        // font-weight: 400;
    }

    // h5, Moved to reboot
    .dds__h5 {
        font-size: 0.875rem; // ~14px
        line-height: 1.25rem; // ~20px
        // font-weight: 400;
    }

    // h6, Moved to reboot
    .dds__h6 {
        font-size: 0.75rem; // ~12px
        line-height: 1.25rem; // ~20px
        // font-weight: 400;
    }
}

@include media-breakpoint-up(sm) {
    // h1, Moved to reboot
    // .dds__h1 {
    //   font-size: 1.5rem; // ~24px
    //   line-height: 1.75rem; // ~28px
    //   font-weight: 300;
    // }

    // h2, Moved to reboot
    // .dds__h2 {
    //   font-size: 1.25rem; // ~20px
    //   line-height: 1.5rem; // ~24px
    //   font-weight: 300;
    // }

    // h3, Moved to reboot
    // .dds__h3 {
    //   font-size: 1.0625rem; // ~17px
    //   line-height: 1.25rem; // ~20px
    //   font-weight: 300;

    //   &.dds__feature {
    //     font-size: 1.125rem; // ~18px
    //     line-height: 1.375rem; // ~22px
    //     font-weight: 300;
    //   }
    // }

    // h4, Moved to reboot
    // .dds__h4 {
    //   font-size: 1rem; // ~16px
    //   line-height: 1.25rem; // ~20px
    //   font-weight: 400;
    // }

    // h5, Moved to reboot
    // .dds__h5 {
    //   font-size: 1rem; // ~16px
    //   line-height: 1.25rem; // ~20px
    //   font-weight: 400;
    // }

    // h6, Moved to reboot
    // .dds__h6 {
    //   font-size: .875rem; // ~14px
    //   line-height: 1.25rem; // ~20px
    //   font-weight: 400;
    // }

    // .dds__bold-16 {
    //   font-size: 1rem; // ~16px
    //   line-height: 1.25rem; // ~20px
    //   font-weight: 700;
    // }

    // .dds__bold-14 {
    //   font-size: .875rem; // ~14px
    //   line-height: 1.25rem; // ~20px
    //   font-weight: 700;
    // }

    // .dds__bold-12 {
    //   font-size: .75rem; // ~12px
    //   line-height: .9375rem; // ~15px
    //   font-weight: 700;
    // }
}

@include media-breakpoint-up(md) {
    // h1, Moved to reboot
    .dds__h1 {
        font-size: 1.875rem; // ~30px
        line-height: 2.25rem; // ~36px
        // font-weight: 300;
    }

    // h2, Moved to reboot
    .dds__h2 {
        font-size: 1.625rem; // ~26px
        line-height: 1.875rem; // ~30px
        // font-weight: 300;
    }

    // h3, Moved to reboot
    .dds__h3 {
        font-size: 1.25rem; // ~20px
        line-height: 1.5rem; // ~24px
        // font-weight: 300;

        &.dds__feature {
            font-size: 1.375rem; // ~22px
            line-height: 1.625rem; // ~26px
            // font-weight: 300;
        }
    }

    // h4, Moved to reboot
    .dds__h4 {
        font-size: 1.125rem; // ~18px
        line-height: 1.375rem; // ~22px
        // font-weight: 400;
    }

    // h5, Moved to reboot
    .dds__h5 {
        font-size: 1rem; // ~16px
        line-height: 1.25rem; // ~20px
        // font-weight: 400;
    }

    // h6, Moved to reboot
    .dds__h6 {
        font-size: 0.875rem; // ~14px
        line-height: 1.25rem; // ~20px
        // font-weight: 400;
    }

    // .dds__bold-16 {
    //   font-size: 1rem; // ~16px
    //   line-height: 1.25rem; // ~20px
    //   font-weight: 700;
    // }

    // .dds__bold-14 {
    //   font-size: .875rem; // ~14px
    //   line-height: 1.25rem; // ~20px
    //   font-weight: 700;
    // }

    // .dds__bold-12 {
    //   font-size: .75rem; // ~12px
    //   line-height: .9375rem; // ~15px
    //   font-weight: 700;
    // }
}

@include media-breakpoint-up(lg) {
    // h1, Moved to reboot
    .dds__h1 {
        font-size: 2rem; // ~32px
        line-height: 2.5rem; // ~40px
        // font-weight: 300;
    }

    // h2, Moved to reboot
    .dds__h2 {
        font-size: 1.75rem; // ~28px
        line-height: 2rem; // ~32px
        // font-weight: 300;
    }

    // h3, Moved to reboot
    .dds__h3 {
        font-size: 1.25rem; // ~20px
        line-height: 1.5rem; // ~24px
        // font-weight: 300;

        &.dds__feature {
            font-size: 1.5rem; // ~24px
            line-height: 1.75rem; // ~28px
            // font-weight: 300;
        }
    }

    // h4, Moved to reboot
    .dds__h4 {
        font-size: 1.125rem; // ~18px
        line-height: 1.375rem; // ~22px
        // font-weight: 400;
    }

    // h5, Moved to reboot
    .dds__h5 {
        font-size: 1rem; // ~16px
        line-height: 1.25rem; // ~20px
        // font-weight: 400;
    }

    // h6, Moved to reboot
    .dds__h6 {
        font-size: 0.875rem; // ~14px
        line-height: 1.25rem; // ~20px
        // font-weight: 400;
    }

    // .dds__bold-16 {
    //   font-size: 1rem; // ~16px
    //   line-height: 1.25rem; // ~20px
    //   font-weight: 700;
    // }

    // .dds__bold-14 {
    //   font-size: .875rem; // ~14px
    //   line-height: 1.25rem; // ~20px
    //   font-weight: 700;
    // }

    // .dds__bold-12 {
    //   font-size: .75rem; // ~12px
    //   line-height: .9375rem; // ~15px
    //   font-weight: 700;
    // }
}
