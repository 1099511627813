.dds__overlay.dds__show {
    background-color: $modal-backdrop-bg;
    height: 100vh;
    left: 0;
    opacity: $modal-backdrop-opacity !important;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: $zindex-modal-backdrop;
    &.dds__fade {
        opacity: 0;
    }
}
