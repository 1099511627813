.dds__popover {
    @include reset-text();
    @include border-radius($popover-border-radius);
    @include box-shadow($popover-box-shadow);
    background-clip: padding-box;
    background-color: $popover-bg;
    border: $popover-border-width solid $popover-border-color;
    display: block;
    font-size: $popover-font-size;
    left: 0;
    max-width: $popover-max-width;
    padding: 1rem 1.25rem 1.375rem;
    position: absolute;
    top: 0;
    word-wrap: break-word;
    z-index: $zindex-popover;

    .dds__arrow {
        display: block;
        height: $popover-arrow-height;
        margin: 0 $border-radius-lg;
        position: absolute;
        width: $popover-arrow-width;

        &::before,
        &::after {
            border-color: transparent;
            border-style: solid;
            content: "";
            display: block;
            position: absolute;
        }
    }

    .dds__close {
        background-color: transparent;
        border: none;
        font-size: 1rem;
        opacity: 1;
        padding: 0.625rem;
        right: 0.25rem;
        top: 0.625rem;

        &:hover {
            opacity: 1 !important;
        }

        &:focus {
            outline: $custom-focus-outline;
        }

        .dds__close-x {
            font-size: 1rem;
        }
    }
}

.dds__bs-popover-top {
    margin-bottom: $popover-arrow-height;

    .dds__arrow {
        bottom: calc((#{$popover-arrow-height} + #{0.045rem}) * -1) !important;
    }

    .dds__arrow::before,
    .dds__arrow::after {
        border-width: $popover-arrow-height ($popover-arrow-width / 2) 0;
    }

    .dds__arrow::before {
        border-top-color: $popover-arrow-outer-color;
        bottom: 0;
    }

    .dds__arrow::after {
        border-top-color: $popover-arrow-color;
        bottom: $popover-border-width;
    }
}

.dds__bs-popover-right {
    margin-left: $popover-arrow-height;

    .dds__arrow {
        height: $popover-arrow-width;
        left: calc((#{$popover-arrow-height} + #{0.045rem}) * -1) !important;
        margin: $border-radius-lg 0;
        width: $popover-arrow-height;
    }

    .dds__arrow::before,
    .dds__arrow::after {
        border-width: ($popover-arrow-width / 2) $popover-arrow-height ($popover-arrow-width / 2) 0;
    }

    .dds__arrow::before {
        border-right-color: $popover-arrow-outer-color;
        left: 0;
    }

    .dds__arrow::after {
        border-right-color: $popover-arrow-color;
        left: $popover-border-width;
    }
}

.dds__bs-popover-bottom {
    margin-top: $popover-arrow-height;

    .dds__arrow {
        top: calc((#{$popover-arrow-height} + #{0.045rem}) * -1) !important;
    }

    .dds__arrow::before,
    .dds__arrow::after {
        border-width: 0 ($popover-arrow-width / 2) $popover-arrow-height ($popover-arrow-width / 2);
    }

    .dds__arrow::before {
        border-bottom-color: $popover-arrow-outer-color;
        top: 0;
    }

    .dds__arrow::after {
        border-bottom-color: $popover-arrow-color;
        top: $popover-border-width;
    }

    .dds__popover-header::before {
        border-bottom: $popover-border-width solid $popover-header-bg;
        content: "";
        display: block;
        left: 50%;
        margin-left: ($popover-arrow-width / -2);
        position: absolute;
        top: 0;
        width: $popover-arrow-width;
    }
}

.dds__bs-popover-left {
    margin-right: $popover-arrow-height;

    .dds__arrow {
        height: $popover-arrow-width;
        margin: $border-radius-lg 0;
        right: calc((#{$popover-arrow-height} + #{0.045rem}) * -1) !important;
        width: $popover-arrow-height;
    }

    .dds__arrow::before,
    .dds__arrow::after {
        border-width: ($popover-arrow-width / 2) 0 ($popover-arrow-width / 2) $popover-arrow-height;
    }

    .dds__arrow::before {
        border-left-color: $popover-arrow-outer-color;
        right: 0;
    }

    .dds__arrow::after {
        border-left-color: $popover-arrow-color;
        right: $popover-border-width;
    }
}

.dds__bs-popover-auto {
    &[x-placement^="top"] {
        @extend .dds__bs-popover-top;
    }

    &[x-placement^="right"] {
        @extend .dds__bs-popover-right;
    }

    &[x-placement^="bottom"] {
        @extend .dds__bs-popover-bottom;
    }

    &[x-placement^="left"] {
        @extend .dds__bs-popover-left;
    }
}

.dds__popover-header {
    $offset-border-width: calc(#{$border-radius-lg} - #{$popover-border-width});
    @include border-top-radius($offset-border-width);
    background-color: $dell-white;
    border-bottom: none;
    color: $popover-header-color;
    font-size: $font-size-base;
    margin-bottom: 0;
    padding: 0 1.5rem 0.625rem 0;

    &:empty {
        display: none;
    }

    &::before {
        content: none !important; //using important to enforce on all popover headers
    }
}

.dds__popover-body {
    color: $popover-body-color;
    padding: 0.625rem 0 0;

    p {
        font-size: 0.875rem;
    }

    .dds__popover-image {
        display: flex;
        justify-content: center;
        padding: 0.625rem;
    }
}

.dds__popover-title-container {
    border-bottom: 0.0625rem solid #ebebeb;
}

@media screen and (max-width: 767.98px) {
    .dds__popover {
        margin: 0.5rem;
        max-width: none;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        .dds__arrow {
            display: none;
        }
    }

    .dds__popover-header::before {
        border-bottom: none !important;
    }

    .dds__overlay {
        visibility: visible !important;
    }
}
