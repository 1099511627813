select.dds__form-control {
    color: $dell-black;
    padding: 0.5rem;

    optgroup {
        option {
            font-style: normal;
        }

        &:disabled {
            color: $dell-black !important;
        }
    }
}

.dds__spinbox-btn {
    border-color: $btn-border-gray !important; // must be reset after dds__buttons $theme-colors @each is applied
    &:disabled {
        border-color: $dell-secondary-disabled !important;
    }
}

.dds__spinbox-horizontal {
    @include mixin_dds__spinbox;
    height: 2.375rem;

    .dds__spinbox-btn {
        height: 100%;
        width: 2.125rem;
    }

    .dds__spinbox-btn:first-child {
        border-right: 0;
    }

    .dds__spinbox-btn:last-child {
        border-left: 0;
    }

    .dds__spinbox-input {
        height: 100%;
        width: 2.375rem;
    }
}

.dds__spinbox-vertical {
    @include mixin_dds__spinbox;
    flex-direction: column;
    width: 2.375rem;

    .dds__spinbox-btn {
        height: 2.125rem;
        width: 100%;
    }

    .dds__spinbox-btn:first-child {
        border-bottom: 0;
    }

    .dds__spinbox-btn:last-child {
        border-top: 0;
    }

    .dds__spinbox-input {
        height: 2.25rem;
        width: 100%;
    }
}

.dds__datepicker {
    position: relative;
}

.dds__datepicker-form {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;

    .dds__form-control {
        width: auto;
    }

    .dds__datepicker-btn {
        padding: 0.375rem 0.75rem;
        line-height: 1.75;
        border: 0.0625rem solid $btn-border-gray;
        background: $dell-quartz;
        margin-left: 0.25rem;

        svg.dds__calendar {
            width: 1.25rem;
            height: 1.25rem;
            fill: $dell-blue;
        }

        &:hover {
            background: $dell-ash;
            cursor: pointer;

            svg.dds__calendar {
                fill: $dell-midnight;
            }
        }
    }
}

.dds__datepicker-calendar {
    position: absolute;
    background-color: $dell-white;
    border: 0.0625rem solid $dell-black;
    padding: 0.625rem;
    overflow: hidden;
    width: 20.375rem; //326px
    z-index: $zindex-dropdown;

    .dds__calendar-wrapper {
        display: none !important;
    }

    .dds__datepicker-nav-btn {
        background: none;
        border: 0;
        color: $dell-blue;
        cursor: pointer;
        line-height: normal;
        outline: none;
        overflow: visible;
        text-align: center;
        padding: 0.375rem 0.75rem;
        line-height: 1.75;

        &:hover {
            background: $dell-sky;

            .dds__svg-icons {
                fill: $dell-midnight;
            }
        }

        .dds__svg-icons {
            fill: $dell-blue;
            height: 1.25rem;
            width: 1.25rem;
        }

        &.dds__disabled {
            background: $dell-mist;
            cursor: not-allowed;

            .dds__svg-icons {
                fill: $dell-granite;
            }

            &:hover {
                background: $dell-mist;
                color: $dell-granite;
            }
        }
    }

    .dds__datepicker-header {
        align-items: center;
        display: flex;
        padding: 0.625rem;
        text-transform: uppercase;
        border-bottom: 0.0625rem solid $dell-ash;
    }

    .dds__datepicker-header__label {
        text-align: center;
        width: 100%;
    }

    .dds__datepicker-week {
        display: flex;
        flex-wrap: wrap;

        span {
            flex-direction: column;
            flex: 0 0 14.28%;
            max-width: 14.28%;
            padding: 0.9375rem 0;
            text-align: center;
            text-transform: uppercase;
        }
    }

    .dds__datepicker-body {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 0.625rem;
        border-bottom: 0.0625rem solid $dell-ash;
    }

    .dds__calendar-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 0.625rem;

        .dds__btn {
            margin: 0 0.25rem;
        }
    }

    .dds__datepicker-date {
        align-items: center;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        flex: 0 0 14.28%;
        max-width: 14.28%;
        padding: 0.125rem;

        .dds__datepicker-calendar-day {
            border: 0.0625rem solid #fff;
            background: #fff;
            color: $dell-blue;
            padding: 0.5rem;
            width: 100%;

            &:hover {
                background: $dell-sky;
                color: $dell-midnight;
                cursor: pointer;
            }
        }
    }

    .dds__datepicker-date-active {
        &.dds__datepicker-date-outdated .dds__datepicker-calendar-day {
            background-color: $dell-quartz;

            &:hover {
                background: $dell-sky;
                color: $dell-midnight;
                cursor: pointer;
            }
        }

        &.dds__datepicker-date-outdated.dds__datepicker-date-selected .dds__datepicker-calendar-day {
            background-color: $dell-blue;
        }
    }

    .dds__datepicker-date-selected {
        .dds__datepicker-calendar-day {
            background-color: $dell-blue;
            color: $dell-white;
        }
    }

    .dds__datepicker-date-disabled {
        .dds__datepicker-calendar-day {
            background: $dell-mist;
            color: $dell-granite;

            &:hover {
                cursor: not-allowed;
                background: $dell-mist;
                color: $dell-granite;
            }
        }
    }
}

.user-is-tabbing {
    .dds__datepicker-calendar {
        .dds__datepicker-calendar-day {
            &:focus {
                outline: 0.0625rem solid #000 !important;
                outline-offset: 0.0625rem !important;
            }
        }

        .dds__datepicker-date-temp-selected {
            .dds__datepicker-calendar-day {
                background-color: $dell-cloud;
                color: $dell-black;
                border: 0.0625rem solid #000;
            }
        }
    }
}

.dds__bar-select {
    height: 2.125rem;

    &.dds__bar-select-selected label {
        background-color: $dell-sea-blue;
    }

    &.dds__bar-select-hover label {
        background-color: $dell-yellow;
    }

    label {
        height: 2.125rem;
        width: 0.75rem;
        padding: 0;
        background-color: $dell-quartz;
        border: 0.0625rem solid $dell-moon;
        margin: 0 0.25rem;
        cursor: pointer;
    }
}

@media screen and (max-width: 767.98px) {
    .dds__calendar-wrapper:not(.dds__hide) {
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        display: block;
        outline: 0;
        overflow: hidden;
        position: fixed;
        z-index: 1050;

        .dds__datepicker-calendar {
            position: relative;
            top: 15%;
            margin: auto;
        }
    }
}

@media screen and (max-width: 575.98px) {
    .dds__datepicker-calendar {
        width: 18.1875rem;
    }
}
