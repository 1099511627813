@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .dds__float#{$infix}-left {
            @include float-left;
        }
        .dds__float#{$infix}-right {
            @include float-right;
        }
        .dds__float#{$infix}-none {
            @include float-none;
        }
    }
}
