.dds__tabs {
    @include mixin_dds__nav-tabs;
    border-bottom-color: $dell-granite;

    li:not(:last-child) {
        margin-right: -0.125rem;
    }

    .dds__flex-tab {
        flex: 1 1 0%;
        min-width: 7.5rem;
    }

    .dds__tab-link {
        @include mixin_dds__tab-link;

        @include mixin_dds__tabs-hover-focus;

        align-items: center;
        background-image: -webkit-linear-gradient(270deg, $dell-white, $dell-quartz);
        background-image: linear-gradient(180deg, $dell-white, $dell-quartz);
        border: $nav-tabs-border-width solid $dell-granite;
        color: $dell-sea-blue;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-bottom: 0.0625rem;
        margin-top: 0.0625rem;
        min-width: 7.5rem;
        padding-top: 0.5625rem; // 9px
        width: 100%;

        span.dds__tab-span_blue {
            color: $dell-sea-blue;
        }

        &.dds__tab-link-centered {
            white-space: normal;
        }

        img {
            margin: 1rem 0;
        }

        span {
            color: $dell-black;
            display: block;
            width: 100%;
            word-break: break-word;
            word-wrap: break-word;

            .dds__h4 {
                color: $dell-sea-blue;
            }
        }

        &.dds__disabled {
            background-color: transparent;
            background-image: none;
            border-color: transparent;
            color: $nav-link-disabled-color;
        }

        &.dds__active {
            border-bottom: none;

            span {
                color: $dell-black;
            }

            .dds__h4 {
                color: $dell-black;
            }

            &:hover {
                cursor: default;
            }
        }
    }

    .dds__tab-link.dds__active,
    .dds__tab-item.dds__show .dds__tab-link {
        background-color: $dell-white;
        background-image: none;
        border-bottom: 0.0625rem solid $dell-white;
        border-left: $nav-tabs-border-width solid $dell-granite;
        border-top: $dell-blue solid 0.3125rem;
        // padding-bottom: 0.5625rem; // 9px
        padding-bottom: 0.5rem;
        padding-top: 0.3125rem;
    }

    &-vertical {
        border-bottom-color: transparent;
        border-right: 0.0625rem solid $dell-granite;
        flex-direction: column;
        margin-top: 0;
        min-height: 12.625rem;
        max-width: 15rem;
        width: 15rem;
        min-width: 15rem;
        margin-right: 0.9375rem;

        li {
            margin-right: -0.08rem !important;

            .dds__tab-link {
                margin-bottom: 0 !important;
                margin-top: 0 !important;
                align-items: flex-start;
                border-bottom: none;
                padding-bottom: 0.5625rem; // 9px
                padding-top: 0.5625rem;
                vertical-align: bottom;
            }

            &:last-child .dds__tab-link,
            &:last-child .dds__tab-link.dds__active {
                border-bottom: 0.0625rem solid $dell-granite;
                padding-top: 0.5625rem;
            }

            .dds__tab-link.dds__active,
            .dds__tab-item.dds__show .dds__tab-link {
                border-bottom: none;
                border-left: 0.3125rem solid $dell-blue;
                border-right: $nav-tabs-border-width solid $dell-white;
                border-top: 0.0625rem solid $dell-granite;
                padding-top: 0.6255rem;
                padding-left: 0.75rem;
                padding-top: 0.5625rem;
                padding-bottom: 0.5625rem;
            }
        }
    }
}

.dds__tabs-container {
    width: 100%;

    .dds__tabs-centered-container {
        border-bottom: 0.0625rem solid $dell-granite;
    }

    .dds__tab-centered {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: 0.0625rem;
        width: 80%;
    }

    &-vertical .dds__icons.dds__tab-icon,
    .dds__icons.dds__tab-icon {
        display: none;
    }

    .dds__modal.dds__modal-offcanvas {
        display: none;
    }

    &-vertical {
        display: flex;
        .dds__tab-content {
            padding-bottom: 0.5625rem;
        }
    }
}

@media screen and (max-width: 767.98px) {
    .dds__tabs,
    .dds___tabs-vertical {
        display: flex;
        flex-direction: column;
        border-bottom: none;
        max-width: none;
        margin-right: 0;
        width: 100%;

        .dds__tab-link {
            align-items: flex-start;
            background-color: $dell-white;
            background-image: none;
            border-bottom: $dell-granite solid $hr-border-width !important;
            border-left: none !important;
            border-right: none !important;
            border-top: none;
            display: flex;
            justify-content: space-between;
            margin: 0;
            margin-bottom: $hr-border-width;
            padding: 1rem !important;
            text-align: left;

            img {
                margin: 0 1rem 0 0;
            }
        }

        .dds__tab-link.dds__active {
            @include mixin_dds__tabs-hover-focus;
            background-image: none;
            border-bottom: $dell-granite solid $hr-border-width;
            border-left: none;
            border-top: none;
            margin: 0;
            margin-bottom: $hr-border-width;
            text-align: left;

            .dds__tab-span_blue {
                color: $dell-sea-blue;
            }
        }
    }

    .dds__tabs-container,
    .dds__tabs-container-vertical {
        li.dds__flex-tab {
            display: flex;
            flex: 1 1 auto;
            margin-bottom: 0;
        }

        .dds__tabs-centered-container {
            border-bottom: none;

            .dds__tab-centered {
                border-bottom: none;
                width: 100%;
            }
        }

        .dds__tabs > :first-child .dds__tab-link {
            border-top: $dell-granite solid $hr-border-width;
        }

        .dds__tabs > li:not(:first-child) .dds__tab-link {
            border-top: none;
        }

        .dds__tab-link {
            border-bottom: $dell-granite solid $hr-border-width;
            flex-direction: row;
            padding: 1rem;

            &.dds__active {
                color: $dell-sea-blue;
                padding-top: 1rem;

                .dds__h4 {
                    color: $dell-sea-blue;
                }
            }

            .dds__icons {
                display: block;
            }
        }
    }

    .dds__tab-content {
        .dds__tab-pane {
            display: none;
        }
    }
}
