.dds__carousel {
    position: relative;
    touch-action: pan-y;
}

.dds__carousel-inner {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.dds__carousel-item {
    align-items: center;
    backface-visibility: hidden;
    display: none;
    perspective: 1000px;
    position: relative;
    width: 100%;
}

.dds__carousel-item.dds__active,
.dds__carousel-item-next,
.dds__carousel-item-prev {
    @include transition($carousel-transition);
    display: block;
}

.dds__carousel-item-next,
.dds__carousel-item-prev {
    position: absolute;
    top: 0;
}

.dds__carousel-item-next.dds__carousel-item-left,
.dds__carousel-item-prev.dds__carousel-item-right {
    transform: translateX(0);

    @supports (transform-style: preserve-3d) {
        transform: translate3d(0, 0, 0);
    }
}

.dds__carousel-item-next,
.dds__active.dds__carousel-item-right {
    transform: translateX(100%);

    @supports (transform-style: preserve-3d) {
        transform: translate3d(100%, 0, 0);
    }
}

.dds__carousel-item-prev,
.dds__active.dds__carousel-item-left {
    transform: translateX(-100%);

    @supports (transform-style: preserve-3d) {
        transform: translate3d(-100%, 0, 0);
    }
}

.dds__carousel-control-prev,
.dds__carousel-control-next {
    align-items: center;
    bottom: 0;
    color: $carousel-control-color;
    display: flex;
    justify-content: center;
    opacity: $carousel-control-opacity;
    position: absolute;
    text-align: center;
    top: 0;
    width: $carousel-control-width;
    @include hover-focus {
        color: $carousel-control-color;
        opacity: 0.9;
        outline: 0;
        text-decoration: none;
    }
}

.dds__carousel-control-prev {
    left: 0;
    @if $enable-gradients {
        background: linear-gradient(90deg, rgba($black, 0.25), rgba($black, 0.001));
    }
}

.dds__carousel-control-next {
    right: 0;
    @if $enable-gradients {
        background: linear-gradient(270deg, rgba($black, 0.25), rgba($black, 0.001));
    }
}

.dds__carousel-indicators {
    @include mixin_dds__carousel-indicators;
    display: flex;
    justify-content: center;
    list-style: none;
    margin-top: 2rem;
    margin-left: $carousel-control-width;
    margin-right: $carousel-control-width;
    padding-left: 0;
    position: relative;
    z-index: 15;

    li {
        display: inline-flex;
        flex: 0 1 auto;

        button {
            display: inline-block;
            border: none;
            background-color: rgba($carousel-indicator-active-bg, 0.5);
            cursor: pointer;
            height: $carousel-indicator-height;
            margin-left: $carousel-indicator-spacer;
            margin-right: $carousel-indicator-spacer;
            position: relative;
            width: $carousel-indicator-width;
        }
    }

    .dds__active {
        button {
            background-color: $carousel-indicator-active-bg;
        }
    }
}

// .dds__carousel-indicators {
//     @include mixin_dds__carousel-indicators;
//     bottom: 10px;
//     display: flex;
//     justify-content: center;
//     left: 0;
//     list-style: none;
//     margin-bottom: -2.8125rem;
//     margin-left: $carousel-control-width;
//     margin-right: $carousel-control-width;
//     padding-left: 0;
//     position: absolute;
//     right: 0;
//     z-index: 15;

//     li {
//         background-color: rgba($carousel-indicator-active-bg, 0.5);
//         cursor: pointer;
//         flex: 0 1 auto;
//         height: $carousel-indicator-height;
//         margin-left: $carousel-indicator-spacer;
//         margin-right: $carousel-indicator-spacer;
//         position: relative;
//         text-indent: -999px;
//         width: $carousel-indicator-width;

//         &::before {
//             content: "";
//             display: inline-block;
//             height: 10px;
//             left: 0;
//             position: absolute;
//             top: -10px;
//             width: 100%;
//         }

//         &::after {
//             bottom: -10px;
//             content: "";
//             display: inline-block;
//             height: 10px;
//             left: 0;
//             position: absolute;
//             width: 100%;
//         }
//     }

//     .dds__active {
//         background-color: $carousel-indicator-active-bg;
//     }
// }

/* CSS detection for Edge */
// @supports (-ms-ime-align:auto) {
//   .dds__carousel {
//     touch-action: pan-y;
//   }
// }

/* CSS detection for IE10+ */
// @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
//   .dds__carousel {
//     touch-action: pan-y;
//   }
// }

.dds__carousel-fade {
    .dds__carousel-item {
        opacity: 0;
        transition-duration: 0.6s;
        transition-property: opacity;
    }

    .dds__carousel-item.dds__active,
    .dds__carousel-item-next.dds__carousel-item-left,
    .dds__carousel-item-prev.dds__carousel-item-right {
        opacity: 1;
    }

    .dds__active.dds__carousel-item-left,
    .dds__active.dds__carousel-item-right {
        opacity: 0;
    }

    .dds__carousel-item-next,
    .dds__carousel-item-prev,
    .dds__carousel-item.dds__active,
    .dds__active.dds__carousel-item-left,
    .dds__active.dds__carousel-item-prev {
        transform: translateX(0);

        @supports (transform-style: preserve-3d) {
            transform: translate3d(0, 0, 0);
        }
    }
}

.dds__carousel-item > a > img {
    height: auto;
    max-width: 100%;
    width: 100%;
}

.dds__carousel-indicators-btn {
    border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.25);
    display: inline-flex;
    margin-top: -0.625rem;
    padding: 0;
    width: 100%;

    li {
        cursor: pointer;
        display: inherit;
        list-style: none;
        width: 100%;
        z-index: 8;

        &:hover:not(.dds__active) > .dds__carousel-btn {
            background-color: $dell-midnight;
        }

        &.dds__active > .dds__carousel-btn {
            background-color: $dell-white;
            color: $dell-black;
            font-weight: bold;
        }

        &.dds__active > div {
            visibility: visible;
        }

        //Adds white space between buttons. Applys margin-left 0.075rem to all carousel-indicators-btn li EXCEPT the first one
        ~ li {
            margin-left: 0.075rem;
        }
    }
}

.dds__carousel-arrow-up {
    border-bottom: 0.625rem solid $dell-white;
    border-left: 0.625rem solid transparent;
    border-right: 0.625rem solid transparent;
    cursor: pointer;
    height: 0;
    margin: 0 auto;
    visibility: hidden;
    width: 0;
}

.dds__carousel-btn {
    background-color: $dell-sea-blue;
    border: 0;
    border-radius: 0;
    color: $dell-white;
    cursor: pointer;
    font-size: 0.875rem;
    height: 100%;
    justify-content: center;
    line-height: 1.5;
    padding: 0.625rem 0.9375rem;
    transition: $carousel-transition;

    &:focus {
        outline: 0.0625rem solid $dell-black;
        outline-offset: 0.125rem;
    }
}
