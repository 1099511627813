.dds__ps-overflow-controls {
    display: flex;
    justify-content: flex-end;

    .dds__btn-primary {
        margin: 0 0.125rem;

        svg {
            height: 1rem;
            pointer-events: none;
            width: 1rem;
        }
    }

    .dds__items-view {
        align-self: center;
        margin-right: 0.375rem;
        position: static;
        right: auto;
        top: auto;
        width: auto;
    }
}

.dds__product-stack-wrapper {
    display: flex;
    list-style-type: none;
    overflow-x: hidden;
    padding: 1.5rem 0 0.5rem;
    // scroll-behavior: smooth;
}

.dds__product-stack {
    // max-width: 18.75rem;
    // min-width: 15rem;
    // margin: 0 0.9375rem;
    white-space: normal;
    list-style: none;
    padding: 0;

    > li {
        display: inline-block;
        min-width: 100%;
        width: 100%;
    }

    .dds__ps-image {
        display: flex;
        justify-content: center;
        max-height: 7.5rem;
        max-width: 100%;
    }

    .dds__ps-base-price {
        .dds__ps-price-adjusted {
            font-size: 1.125rem;
        }

        .dds__ps-price-original {
            font-size: 0.875rem;
        }
    }

    .dds__ps-price-attribute {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dds__btn-link {
            padding: 0;
        }

        &:not(:last-child) {
            margin-bottom: 0.625rem;
        }
    }

    .dds__ps-button .dds__btn {
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 0.5rem;
        }
    }

    .dds__ps-tooltip {
        border-bottom: 0.0625rem dashed $dell-black;
    }

    .dds__ps-hr {
        margin: 0.625rem 0;
    }

    .dds__ps-details .dds__list-group {
        margin: 0;

        .dds__list-group-item-unordered {
            margin-bottom: 0.3125rem;
        }

        .dds__list-group-item-unordered:last-child {
            margin-bottom: 0;
        }
    }

    .dds__ps-description p:last-child {
        margin-bottom: 0;
    }

    .dds__text-berry {
        color: $dell-berry;
    }
}

// Mobile
@media (max-width: 991.98px) {
    .dds__product-stack-wrapper {
        overflow-x: auto;
    }
}
@media (max-width: 767.98px) {
    .dds__ps-overflow-controls .dds__ps-btn-text {
        display: none;
    }
}
