@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
    %grid-column {
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: ($gutter / 2);
        padding-left: ($gutter / 2);
    }

    @each $breakpoint in map-keys($breakpoints) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);
        @for $i from 1 through $columns {
            .dds__col#{$infix}-#{$i} {
                @extend %grid-column;
            }
        }
        .dds__col#{$infix},
        .dds__col#{$infix}-auto {
            @extend %grid-column;
        }

        @include media-breakpoint-up($breakpoint, $breakpoints) {
            .dds__col#{$infix} {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
            }
            .dds__col#{$infix}-auto {
                flex: 0 0 auto;
                width: auto;
                max-width: none;
            }

            @for $i from 1 through $columns {
                .dds__col#{$infix}-#{$i} {
                    @include make-col($i, $columns);
                }
            }

            .dds__order#{$infix}-first {
                order: -1;
            }

            .dds__order#{$infix}-last {
                order: $columns + 1;
            }

            @for $i from 0 through $columns {
                .dds__order#{$infix}-#{$i} {
                    order: $i;
                }
            }
            @for $i from 0 through ($columns - 1) {
                @if not($infix == "" and $i == 0) {
                    .dds__offset#{$infix}-#{$i} {
                        @include make-col-offset($i, $columns);
                    }
                }
            }
        }
    }
}
