.dds__page-item {
    &:first-child {
        .dds__page-link {
            @include border-left-radius($border-radius);
            margin-left: 0;
        }
    }

    &:last-child {
        .dds__page-link {
            @include border-right-radius($border-radius);
        }
    }

    &.dds__active .dds__page-link {
        background-color: $pagination-active-bg;
        border-color: $pagination-active-border-color;
        color: $pagination-active-color;
        z-index: 1;
    }

    &.dds__disabled .dds__page-link {
        background-color: $pagination-disabled-bg;
        border-color: $pagination-disabled-border-color;
        color: $pagination-disabled-color;
        cursor: auto;
        pointer-events: none;
    }
}

.dds__pagination {
    @include list-unstyled();
    @include border-radius();
    display: flex;
    justify-content: space-around;
    padding: 0;

    .dds__pagination-total-items {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 1;
        height: 2.4063rem;
    }

    .dds__svg-icons {
        @include mixin_dds__svg-icons;
        height: 1rem;
        width: 1rem;

        &[name="chevron-right"] {
            margin-bottom: 0.125rem;
            margin-left: 0.3125rem;
            margin-right: -0.25rem;
        }

        &[name="chevron-left"] {
            margin-bottom: 0.125rem;
            margin-left: -0.25rem;
            margin-right: 0.3125rem;
        }

        .dds__svg-icons-item {
            display: none;

            &.dds__show {
                display: inherit;
            }
        }
    }

    &.dds__pagination-justified,
    &.dds__pagination-justified-filter {
        justify-content: flex-end;

        .dds__pagination-list {
            display: flex;
            justify-content: space-between;
        }
    }

    &.dds__pagination-centered {
        .dds__pagination-list {
            display: flex;
            justify-content: center;
        }
    }

    .dds__pagination-list {
        flex-grow: 1;
        margin: 0;
        padding: 0;

        &.dds__pagination-hidden {
            border: 0;
            clip: rect(0 0 0 0);
            height: 0.0625rem;
            margin: -0.0625rem;
            overflow: hidden;
            padding: 0;
            position: absolute;
            white-space: nowrap;
            width: 0.0625rem;
        }

        .dds__page-item {
            align-items: center;
            display: flex;

            .dds__pager-link {
                text-decoration: none;
            }

            &:hover {
                .dds__pager-link {
                    color: $dell-midnight;
                }
            }
        }

        li {
            list-style-type: none;
        }
    }
}

.dds__page-link {
    background-color: $pagination-bg;
    border: $pagination-border-width solid $pagination-border-color;
    color: $pagination-color;
    display: block;
    line-height: $pagination-line-height;
    margin-left: -$pagination-border-width;
    padding: $pagination-padding-y $pagination-padding-x;
    position: relative;

    &:hover {
        background-color: $pagination-hover-bg;
        border-color: $pagination-hover-border-color;
        color: $pagination-hover-color;
        text-decoration: none;
        z-index: 2;
    }

    &:focus {
        box-shadow: $pagination-focus-box-shadow;
        outline: $pagination-focus-outline;
        z-index: 2;
    }

    &:not(:disabled):not(.dds__disabled) {
        cursor: pointer;
    }
}

.dds__page-item {
    &:first-child {
        .dds__page-link {
            @include border-left-radius($border-radius);
            margin-left: 0;
        }
    }

    &:last-child {
        .dds__page-link {
            @include border-right-radius($border-radius);
        }
    }

    &.dds__active .dds__page-link {
        background-color: $pagination-active-bg;
        border-color: $pagination-active-border-color;
        color: $pagination-active-color;
        z-index: 1;
    }

    &.dds__disabled .dds__page-link {
        background-color: $pagination-disabled-bg;
        border-color: $pagination-disabled-border-color;
        color: $pagination-disabled-color;
        cursor: auto;
        pointer-events: none;
    }
}

.dds__pagination-lg {
    @include pagination-size(
        $pagination-padding-y-lg,
        $pagination-padding-x-lg,
        $font-size-lg,
        $line-height-lg,
        $border-radius-lg
    );
}

.dds__pagination-sm {
    @include pagination-size(
        $pagination-padding-y-sm,
        $pagination-padding-x-sm,
        $font-size-sm,
        $line-height-sm,
        $border-radius-sm
    );
}

.dds__perpage {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-left: 1.5rem;

    select {
        padding: 0.375rem 0.0125rem;
        width: 3.675rem;
    }

    label {
        padding-left: 0.5rem;
        white-space: nowrap;
    }
}

.dds__pagination-input-ctnr {
    align-items: center;
    display: flex;

    label {
        padding: 0 0.5rem;
        white-space: nowrap;
    }

    input {
        padding: 0.375rem 0.0125rem;
        width: 2.675rem;
    }
}

@media screen and (max-width: 575.98px) {
    .dds__pagination-justified-filter {
        flex-direction: column;

        .dds__pagination-list {
            width: 100%;
        }
    }

    .dds__pagination {
        .dds__perpage {
            justify-content: flex-start;
            margin-top: 1rem;
            padding: 0;
            width: 100%;
        }

        .dds__pager-link {
            color: transparent;
            font-size: 0;
            line-height: 0;
        }

        .dds__svg-icons {
            &[name="chevron-right"] {
                margin: 0.25rem 0;
            }

            &[name="chevron-left"] {
                margin: 0.25rem 0;
            }
        }
    }
}
