.dds__breadcrumb {
    @include border-radius($breadcrumb-border-radius);
    background-color: $breadcrumb-bg;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: $breadcrumb-margin-bottom;
    padding: $breadcrumb-padding-y $breadcrumb-padding-x;
}

.dds__breadcrumb-item {
    margin-bottom: 0.5rem;

    + .dds__breadcrumb-item {
        color: $dell-carbon;
        font-size: $font-size-sm;
        line-height: $small-line-height;
        align-self: center;
    }

    .dds__home {
        font-size: 0.9375rem;
    }

    &.dds__active {
        color: $breadcrumb-active-color;
    }

    a {
        color: $dell-carbon;
        padding-top: 0.25rem;
        text-decoration: none;
        // -webkit-text-decoration-skip: objects;

        &:hover {
            text-decoration: underline;
        }
    }

    &:not(:last-of-type)::after {
        content: "\232a";
        color: $breadcrumb-divider-color;
        display: inline-block;
        padding: 0 $breadcrumb-item-padding;
        font-family: "dds-icons" !important;
        font-size: 1rem;
        line-height: 1;
        margin-top: -0.125rem;
        vertical-align: middle;
    }

    &:first-of-type a:hover {
        text-decoration: none;
    }
}
