.dds__filter-keyword {
    align-items: center;
    display: flex;
    flex-direction: row;

    label {
        margin: 0;
        margin-right: 0.3125rem;
    }

    .dds__filter-container {
        align-items: center;
        border: 0.0625rem solid $input-border-color;
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;

        .dds__filter-input {
            border: none;
            min-width: 0;
        }

        .dds__filter-btn {
            margin-left: 0.3125rem;
            margin-right: 0.1875rem;
            padding: 0.1875rem;
        }

        .dds__icon-svg {
            fill: $dell-sea-blue;
            height: 1.5rem;
            position: relative;
            width: 1.5rem;
            z-index: 20;
        }
    }
}

.dds__filter-collection-wrapper {
    .dds__filter-sort {
        align-content: flex-end;
        align-items: center;
        display: flex;
        margin-bottom: 0;
        white-space: nowrap;

        label {
            margin-right: 0.5rem;
        }

        .dds__form-control {
            width: fit-content;
        }
    }

    .dds__filter-input-container {
        display: flex;
        flex-wrap: wrap;

        .dds__filter-btn-show {
            background-color: $dell-white;
            border: 0.0625rem solid $btn-border-gray;
            color: $dell-sea-blue;
            width: 100%;

            &:focus {
                box-shadow: none;
            }
        }
    }

    label {
        margin: 0 0.5rem 0 0;
        vertical-align: middle;

        &.dds__filter-clear-all {
            margin-right: 0;
        }
    }

    .dds__filter-collection {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .dds__filter-results {
            display: flex;
            flex-wrap: wrap;
            max-width: 100%;
            width: 100%;
        }

        .dds__filter-item,
        .dds__filter-btn-clear {
            color: $dell-sea-blue;
            display: flex;
            margin-bottom: 0.5rem;
            margin-right: 0.5rem;
            max-width: 100%;
            vertical-align: middle;

            .dds__filter-close {
                background: transparent;
                border: none;
                padding-right: 0;
            }

            .dds__svg-close-x {
                fill: $dell-sea-blue;
                height: 0.875rem;
                min-height: 0.875rem;
                min-width: 0.875rem;
                width: 0.875rem;
            }
        }
    }

    .dds__filter-btn-mobile {
        display: none;
    }

    .dds__modal-offcanvas {
        display: none;
    }

    &.dds__filter-collection-horizontal {
        .dds__filter-input-container {
            > * {
                margin-bottom: 0.5rem;
            }

            .dds__btn-dropdown > .dds__btn-secondary {
                display: flex;
                width: 100%;

                span.dds__text-truncate {
                    display: block;
                }

                svg {
                    margin-left: auto;
                }
            }
        }

        .dds__filter-collection-wrapper {
            align-items: flex-start;
            display: flex;
            flex-direction: row;
            margin-top: 1rem;
            padding: 0 0.9375rem;

            .dds__filter-sort {
                .dds__form-control {
                    width: fit-content;
                }
            }
        }

        .dds__filter-collection {
            align-items: center;
            display: flex;
            flex: 1 1;
            flex-direction: row;
            justify-content: flex-start;

            .dds__filter-results {
                display: flex;
                flex-wrap: wrap;
                max-width: 100%;
                width: 100%;
            }

            .dds__filter-item,
            .dds__filter-btn-clear {
                &.dds__shakey {
                    animation-duration: 0.5s;
                    animation-name: shake;
                    position: relative;
                }
            }
        }
    }

    &.dds__filter-collection-vertical {
        margin-right: 0;
        margin-left: 0;

        .dds__filter-column {
            max-width: 17.8125rem;
            min-width: 11.25rem;
        }

        .dds__filter-collector-accordion {
            padding: 0.625rem;
            border: 0.0625rem solid $dell-ash;
            background-color: $dell-quartz;

            .dds__filter-collector-vertical {
                max-width: 100%;
            }

            div:first-child {
                flex-wrap: wrap;
            }
        }

        .dds__filter-btn-clear {
            margin-bottom: 0.5rem;
        }

        .dds__filter-input-container {
            padding: 0;

            .dds__accordion-filter-container {
                list-style: none;
                padding: 0.625rem;
                border-left: 0.0625rem solid $dell-ash;
                border-right: 0.0625rem solid $dell-ash;
                border-bottom: 0.0625rem solid $dell-ash;
                width: 100%;

                ul {
                    padding-inline-start: 0;
                }

                .dds__form-check {
                    padding: 0;

                    &:hover {
                        background-color: $dell-sky;
                        cursor: pointer;
                    }

                    &:active {
                        color: $dell-white;
                        background-color: $dell-blue;
                    }

                    .dds__form-check-label {
                        .dds__form-check-input {
                            margin-top: 0.3125rem;
                            margin-bottom: 0.3125rem;
                        }

                        .dds__form-check-input + span {
                            margin-top: 0;
                            padding-top: 0.1875rem;
                            padding-bottom: 0.1875rem;
                            margin-left: 0;
                            padding-left: 1.25rem;
                            width: 100%;
                        }

                        .dds__form-check-input:not(:disabled):hover {
                            cursor: pointer;
                        }

                        .dds__form-check-input:not(:disabled) + span {
                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }

                .dds__secondary-accordion-btn span {
                    white-space: nowrap;
                }

                .dds__arrow-tri-solid-right {
                    transform: rotate(270deg);
                    margin-left: auto;
                    width: 1.1875rem;
                }

                .dds__collapsed {
                    .dds__arrow-tri-solid-right {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .dds__filter-results-wrapper {
            .dds__filter-label-results {
                display: flex;
            }

            .dds__filter-sort-wrapper {
                display: flex;
                flex-grow: 1;
                justify-content: flex-end;
            }
        }
    }
}

@media (max-width: 767.98px) {
    .dds__filter-collection-wrapper {
        .dds__filter-results .dds__filter-item:not(.dds__filter-btn-clear) {
            display: none;
        }

        .dds__modal-offcanvas {
            @include mixin_dds__chckbx-filter-state();

            .dds__accordion-card-body,
            .dds__accordion-card-body ul {
                padding-left: 0;

                .dds__form-check {
                    .dds__form-check-label {
                        .dds__form-check-input {
                            margin-top: 0.3125rem;
                            margin-bottom: 0.3125rem;
                        }

                        .dds__form-check-input + span {
                            margin-top: 0;
                            padding-top: 0.1875rem;
                            padding-bottom: 0.1875rem;
                            margin-left: 0;
                            padding-left: 1.25rem;
                            width: 100%;
                        }

                        .dds__form-check-input:not(:disabled) + span {
                            &:hover {
                                background-color: $dell-sky;
                                cursor: pointer;
                            }

                            &:focus {
                                outline: none;
                                background-color: $dell-sky;
                            }

                            &:active {
                                color: $dell-white;
                                background-color: $dell-blue;
                            }
                        }
                    }
                }
            }

            .dds__filter-results {
                .dds__filter-item:not(.dds__filter-btn-clear) {
                    display: flex;
                }
            }

            .dds__offcanvas-modal-topbar {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }

        .dds__filter-btn-mobile {
            display: block;
            margin-bottom: 0.5rem;
        }

        &.dds__filter-collection-horizontal {
            .dds__filter-collection-wrapper {
                padding: 0;
                margin-top: 0;
            }

            .dds__filter-input-container {
                display: none;
            }
        }

        &.dds__filter-collection-vertical {
            > :first-child {
                display: none;
            }

            .dds__filter-results-wrapper {
                padding: 0;

                .dds__filter-results-top-ctnr {
                    flex-wrap: wrap;

                    .dds__filter-label-results {
                        margin-right: 0.5rem;

                        span {
                            margin-right: 0.5rem;
                        }

                        .dds__filter-sort {
                            margin-bottom: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 575.98px) {
    .dds__filter-collection-wrapper {
        .dds__filter-sort label {
            display: none;
        }
    }
}

@keyframes shake {
    0% {
        left: 0;
    }
    10% {
        left: -3px;
    }
    20% {
        left: 3px;
    }
    30% {
        left: -3px;
    }
    40% {
        left: 3px;
    }
    100% {
        left: 0;
    }
}
