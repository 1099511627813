.dds__dropdown {
    position: relative;
}

.dds__dropdown-menu {
    @include border-radius($dropdown-border-radius);
    @include box-shadow($dropdown-box-shadow);
    background-clip: padding-box;
    background-color: $dropdown-bg;
    border: $dropdown-border-width solid $dropdown-border-color;
    color: $body-color;
    display: none;
    float: left;
    font-size: $font-size-base;
    left: 0;
    list-style: none;
    margin: $dropdown-spacer 0 0;
    min-width: $dropdown-min-width;
    padding: $dropdown-padding-y 0;
    position: absolute;
    text-align: left;
    top: 100%;
    z-index: $zindex-dropdown;

    &.dds__show {
        display: block;
    }

    &[x-placement^="top"],
    &[x-placement^="right"],
    &[x-placement^="bottom"],
    &[x-placement^="left"] {
        bottom: auto;
        right: auto;
    }
}

.dds__dropdown-divider {
    @include nav-divider($dropdown-divider-bg);
}

.dds__dropdown-item {
    background-color: transparent;
    border: 0;
    clear: both;
    color: $dropdown-link-color;
    display: block;
    font-weight: $font-weight-normal;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    text-align: inherit;
    white-space: nowrap;
    width: 100%;

    @include hover-focus {
        @include gradient-bg($dropdown-link-hover-bg);
        color: $dropdown-link-hover-color;
        text-decoration: none;
    }

    &.dds__active,
    &:active {
        @include gradient-bg($dropdown-link-active-bg);
        color: $dropdown-link-active-color;
        text-decoration: none;
    }

    &.dds__disabled,
    &:disabled {
        background-color: transparent;
        color: $dropdown-link-disabled-color;
        @if $enable-gradients {
            background-image: none;
        }
    }
}

.dds__mb-0.dds__show > .dds__btn > .dds__arrow-tri-solid-right,
.dds__btn-group.dds__show > .dds__btn-split-arrow > .dds__arrow-tri-solid-right,
.dds__btn-dropdown.dds__show svg.dds__arrow-tri-solid-right {
    transform: rotate(-90deg);
}

.dds__btn-dropdown:not(.dds__btn-group).dds__show {
    width: auto;
}

.dds__btn-group.dds__btn-dropdown {
    max-width: 100%;
}

.dds__btn {
    .dds__arrow-tri-solid-right {
        color: $dell-white;
        cursor: pointer;
        display: inline-block;
        font-size: 1rem;
        line-height: 1.5rem;
        padding-top: 0;
        text-decoration: none !important;
        transform: rotate(90deg);
    }

    &.dds__btn-secondary .dds__arrow-tri-solid-right {
        color: $dell-blue;
    }

    &[disabled] .dds__arrow-tri-solid-right {
        color: $dell-moon;
    }
}

.dds__multi-select {
    @include mixin_dds__chckbx-filter-state();

    .dds__button-dropdown-container {
        padding: 0.3125rem 0;
    }

    .dds__btn.dds__btn-secondary {
        display: flex;
        justify-content: space-between;
    }

    .dds__form-check-label {
        input {
            margin-bottom: 0.625rem;
        }

        span {
            margin-bottom: 0.375rem;
        }
    }
}

.dds__button-dropdown-container {
    //dropdown-menu
    background-color: $dell-white;
    border: 0.0625rem solid $dell-granite;
    box-shadow: $dropdown-box-shadow;
    margin: 0;
    max-width: 93vw;
    min-width: 100%;
    padding: 0.3125rem 0;
    position: absolute;
    width: auto;
    z-index: $zindex-dropdown;

    .dds__dropdown-list-item {
        list-style-type: none;

        .dds__dropdown-item {
            &:hover {
                cursor: pointer;
            }

            &:active {
                background-color: $dell-sky;
                color: $dell-black;
            }
        }

        svg.dds__check {
            fill: $dell-sea-blue;
            height: 0.875rem;
            margin-right: 0.5rem;
            width: 0.875rem;
        }
    }

    &.dds__split-container {
        left: 0;
        max-width: 93vw;
        top: 2.375rem;
        //Medium devices (tablets, less than 992px)
        @media (max-width: 991.98px) {
            max-width: 91vw;
        }
        // Small devices (landscape phones, less than 768px)
        @media (max-width: 767.98px) {
            max-width: 88vw;
        }
        // Extra small devices (portrait phones, less than 576px)
        @media (max-width: 575.98px) {
            max-width: 79vw;
        }
    }
    //Medium devices (tablets, less than 992px)
    @media (max-width: 991.98px) {
        max-width: 91vw;
    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 767.98px) {
        max-width: 88vw;
    }
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) {
        max-width: 79vw;
    }
}

.dds__btn-dropdown {
    .dds__arrow-tri-solid-right {
        display: inline-block;
        height: 1rem;
        min-width: 1rem;
        pointer-events: none;
        stroke: currentColor;
        stroke-width: 0;
        transform: rotate(90deg);
        width: 1rem;
    }
}

.dds__btn-split-arrow {
    align-items: center;
}

.dds__btn-primary.dds__btn-split-arrow {
    border-left: 0.0625rem solid $dell-midnight;
}

.dds__btn-purchase.dds__btn-split-arrow {
    border-left: 0.0625rem solid $dell-primary-purchase-green-hover;
}

.dds__btn-secondary.dds__btn-split-arrow {
    border-left: 0.0625rem solid $btn-border-gray;
}

.dds__btn-dropdown.dds__show > .dds__btn-primary[data-toggle="dds__dropdown"] {
    background-color: $dell-midnight;
    border-color: $dell-midnight;
}

.dds__btn-dropdown.dds__show > .dds__btn-purchase[data-toggle="dds__dropdown"] {
    background-color: $dell-primary-purchase-green-hover;
    border-color: $dell-primary-purchase-green-hover;
}

.dds__btn-dropdown.dds__show > .dds__btn-secondary[data-toggle="dds__dropdown"],
.dds__btn-dropdown.dds__show > .dds__btn-secondary[data-filter="dds__multi-select"] {
    background-color: $dell-ash;
    border-color: $btn-border-gray;
    color: $dell-midnight;

    .dds__arrow-tri-solid-right {
        fill: $dell-midnight;
    }
}

//split button links styles need to be specific to overwrite reboot
.dds__btn-group.dds__btn-dropdown .dds__btn.dds__btn-primary,
.dds__btn-group.dds__btn-dropdown .dds__btn.dds__btn-purchase {
    color: $white;
}

.dds__btn-group.dds__btn-dropdown .dds__btn.dds__btn-secondary {
    color: $dell-sea-blue;
}
