.dds__table {
    background-color: $table-bg;
    margin-bottom: $spacer;
    width: 100%;

    th,
    td {
        border-top: $table-border-width solid $table-border-color;
        padding: $table-cell-padding;
        vertical-align: top;
    }

    thead th {
        border-bottom: (2 * $table-border-width) solid $table-border-color;
        border-bottom: ($table-border-width) solid $table-border-color;
        border-top: none !important;
        vertical-align: bottom;
    }

    tbody + tbody {
        border-top: (2 * $table-border-width) solid $table-border-color;
    }

    thead th:not([data-sortable]) {
        color: $dell-black;
    }

    tr:last-of-type {
        border-bottom: ($table-border-width) solid $table-border-color;
    }

    tr &:hover {
        background-color: $table-hover-bg;

        a {
            color: $link-hover-color;

            svg {
                fill: $link-hover-color;
            }
        }
    }

    input[type="checkbox"] {
        height: 1rem;
        vertical-align: middle;
        width: 1rem;
    }
}

.dds__table-striped {
    tbody tr:nth-of-type(#{$table-striped-order}) {
        background-color: $table-accent-bg;
    }
}

.dds__table-hover {
    tbody tr {
        @include hover {
            background-color: $table-hover-bg;

            a {
                color: $link-hover-color;

                svg {
                    fill: $link-hover-color;
                }
            }
        }
    }
}
// Commenting out includes to unused mixins
// @each $color, $value in $theme-colors {
// 	@include table-row-variant($color, theme-color-level($color, -9));
// }
// @include table-row-variant(active, $table-active-bg);

.dds__table-responsive {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $next: breakpoint-next($breakpoint, $grid-breakpoints);
        $infix: breakpoint-infix($next, $grid-breakpoints);

        &#{$infix} {
            @include media-breakpoint-down($breakpoint) {
                display: block;
                overflow-x: auto;
                width: 100%;

                > .dds__table-bordered {
                    border: 0;
                }
            }
        }
    }
}

.dds__table-cmplx-wrapper {
    .dds__icon-svg {
        height: 1.25rem;
        margin: 0;
        pointer-events: none;
        width: 1.25rem;

        .dds__icon-svg-item {
            display: none;

            &.dds__show {
                display: inherit;
            }
        }
    }
}

.dds__table-cmplx {
    border-collapse: inherit;
    border-spacing: 0;
    line-height: 1.25rem;
    max-width: 100%;
    padding: 0;
    width: 100%;

    tbody {
        tr {
            &.selected {
                background-color: $table-active-bg !important;
            }

            .dds__table-cmplx-row-odd:not(.dds__table-cmplx-row-details),
            .dds__table-cmplx-row-even:not(.dds__table-cmplx-row-details) {
                height: 2.375rem;
            }

            td {
                padding: 0.45rem;
                vertical-align: middle;
                white-space: nowrap;

                .dds__table-cmplx-accordion-btn {
                    background: transparent;
                    border: none;
                    box-shadow: none;
                    cursor: pointer;
                    line-height: 1rem;
                    padding: 0;
                    transform: rotate(90deg);
                    transition: transform 200ms ease 0s;

                    &.dds__collapsed {
                        transform: rotate(0deg);
                    }

                    .dds__icon-svg {
                        fill: $dell-sea-blue;
                    }
                }
            }
        }
    }

    &.dds__table-striped {
        tbody {
            tr.dds__table-cmplx-row-odd {
                background-color: $dell-white;
            }

            tr.dds__table-cmplx-row-even {
                background-color: $table-accent-bg;
            }

            tr:hover {
                background-color: $table-hover-bg;

                a {
                    color: $link-hover-color;

                    svg {
                        fill: $link-hover-color;
                    }
                }
            }
        }
    }

    &.dds__condensed {
        input[type="checkbox"] {
            height: 0.875rem;
            width: 0.875rem;
        }

        tbody {
            tr.dds__table-cmplx-row-odd:not(.dds__table-cmplx-row-details),
            tr.dds__table-cmplx-row-even:not(.dds__table-cmplx-row-details) {
                height: 1.5rem !important;
            }

            tr {
                td {
                    font-size: 0.75rem !important;
                    padding-bottom: 0;
                    padding-left: 0.45rem;
                    padding-right: 0.45rem;
                    padding-top: 0;
                    vertical-align: middle;

                    .dds__icon-svg {
                        height: 0.875rem;
                        width: 0.875rem;
                    }
                }

                td.dds__table-cmplx-accordion-btn > button {
                    font-size: 0.875rem;
                }
            }
        }

        .dds__table-cmplx-row-select {
            padding: 0 0.5714rem;
        }
    }
}

.dds__table-cmplx > thead > tr > th,
.dds__table-cmplx > thead > tr > td,
.dds__table-cmplx > tfoot > tr > td,
.dds__table-cmplx > tfoot > tr > th {
    vertical-align: middle;
    white-space: nowrap;
}

.dds__table-cmplx > thead > tr > th:focus,
.dds__table-cmplx > thead > tr > td:focus,
.dds__table-cmplx > tbody > tr > td:focus {
    outline-color: transparent;
}

.dds__table-complx-details {
    display: none;

    &.dds__show {
        border-top: none;
        display: table-cell;
        padding: 0;
    }
}

.dds__table-complx-details-body {
    display: flex;
    height: auto;
    margin: 0.1875rem;
    padding: 0.8375rem;
}

.dds__table-cmplx-container {
    overflow: auto;
}

.dds__table-cmplx-top,
.dds__table-cmplx-bottom {
    padding: 0.5rem 0;

    .dds__table-cmplx-top-cntr {
        align-items: center;
        display: inherit;
        flex-basis: 0;
        flex-wrap: nowrap;
        height: auto;
        padding: 0;

        label {
            margin-top: 0.25rem;
            white-space: nowrap;
        }
    }

    .dds__table-cmplx-settings {
        display: flex;
        justify-content: flex-end;
        position: relative;
        white-space: nowrap;
        width: 100%;
    }
}

.dds__table-cmplx-selector {
    border: 0.0625rem solid $dell-black;
    padding: 0.375rem;
}

// .dds__table-cmplx-input {
// 	padding: 0.375rem 0.75rem;
// }

.dds__table-cmplx-info {
    margin: 0.4375rem 0;
}

.dds__table-cmplx-top {
    display: flex;
    flex: 1 0 auto;
    flex-flow: row nowrap;
    justify-content: space-between;

    .dds__table-cmplx-settings-button {
        background-color: transparent;
        border: none;
        color: $dell-sea-blue;
        cursor: pointer;
        height: 2.6875rem;
        margin-right: 0.25rem;
    }

    .dds__table-cmplx-settings-button:last-of-type,
    .dds__table-cmplx-settings-button:only-of-type {
        margin-right: 0;
    }

    .dds__table-cmplx-settings-button:hover {
        color: $dell-midnight;
        text-decoration: underline;
    }

    .dds__column-box {
        display: none;

        &.dds__show {
            background-color: $dell-white;
            border: 0.0625rem solid $dell-black;
            display: flex;
            flex-direction: column;
            margin-top: 0;
            padding: 0.625rem;
            width: 15.625rem;
            position: absolute;
            right: 0;
            top: 100%;
            z-index: 12345;

            .dds__table-cmplx-column-cntr {
                display: flex;
                flex-direction: column;
                min-width: 10.5rem;
                max-height: 17.25rem;
                // width: 10.5rem;

                ul {
                    margin: 0;
                    padding: 0;
                    overflow-y: auto;

                    input[type="checkbox"] {
                        cursor: pointer;
                        height: 1rem;
                        margin-left: 0.1875rem;
                        margin-right: 0.5rem;
                        vertical-align: middle;
                        width: 1rem;
                    }

                    .dds__table-cmplx-li {
                        border-bottom: 0.125rem solid transparent;
                        cursor: pointer;
                        list-style: none;
                        list-style-type: none;
                        padding-left: 1.25rem;
                        width: 100%;

                        &:hover {
                            background-color: $table-hover-bg;
                        }
                    }

                    .dds__table-cmplx-drag-li {
                        align-items: center;
                        border-bottom: 0.125rem solid transparent;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: flex-start;
                        list-style: none;
                        list-style-type: none;
                        width: 100%;
                        touch-action: none;

                        label {
                            cursor: grab;
                        }

                        &:hover {
                            background-color: $table-hover-bg;
                            cursor: grab;
                        }

                        [draggable] {
                            user-select: none;
                        }

                        &.dds__border-bottom-black {
                            border-bottom: 0.125rem solid $dell-black;
                        }

                        &.dds__border-top-black {
                            border-top: 0.125rem solid $dell-black;
                        }
                    }
                }

                div:last-of-type {
                    justify-content: flex-end;
                }

                button {
                    border-style: solid;
                    border-width: 0.0625rem;
                    flex: 1;
                    margin-top: 0.5rem;
                }

                button:last-of-type {
                    margin-left: 0.5rem;
                }
            }
        }

        label {
            white-space: nowrap;
        }
    }

    .dds__table-cmplx-column-cntr {
        display: flex;
        position: relative;
    }

    .dds__labels {
        align-items: center;
        display: flex;
        flex: 2;
        justify-content: flex-start;

        .dds__vertical-divider-right {
            margin-right: 0.5rem;
        }
    }
}

.dds__table-cmplx-label {
    .dds__table-cmplx-label-selector {
        border: 0.0625rem solid $dell-black;
        color: $dell-black;
        margin: 0 0.4rem;
        min-width: 10rem;
        padding: 0.2rem;
    }

    label {
        display: inline;
    }
}

.dds__table-cmplx-action {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-right: 1rem;

    .dds__table-cmplx-action-label {
        margin: 0;
        padding: 0;
        padding-right: 0.75rem;
    }
}

.dds__table-cmplx > thead > tr > th {
    border-bottom: 0.0625rem solid #d9d9d9;
    color: $dell-sea-blue;
    text-align: left;
    vertical-align: bottom;
}

.dds__table-cmplx > tfoot > tr > th {
    border-top: 0.0625rem solid #d9d9d9;
    text-align: left;
    vertical-align: bottom;
}

.dds__table-cmplx th {
    text-align: left;
    vertical-align: bottom;
}

.dds__table-cmplx-search {
    display: flex;
    flex-direction: row;
    height: 2.406rem;
    margin-right: 1rem;
    outline: solid 0.0875rem $input-border-color !important;
    width: 100%;

    .dds__icon-svg {
        fill: $dell-sea-blue;
        position: relative;
        right: 0.625rem;
        top: 0.625rem;
        z-index: 20;
    }

    input {
        border: transparent;
        height: auto;
        margin-right: 1.25rem;
        width: 100%;
    }
}

.dds__table-cmplx-sorter {
    cursor: pointer;
    height: 100%;
    margin: 0;
    padding-right: 2rem;
    position: relative;
    width: auto;
}

.dds__table-cmplx-sorter::before,
.dds__table-cmplx-sorter::after {
    border-left: 0.3125rem solid transparent;
    border-right: 0.3125rem solid transparent;
    content: "";
    height: 0;
    position: absolute;
    right: 0.25rem;
    width: 0;
}

.dds__table-cmplx-sorter::before {
    border-bottom: 0.3125rem solid transparent;
    border-top: 0.3125rem solid $dell-sea-blue;
    top: 0.7rem;
}

.dds__table-cmplx-sorter::after {
    border-bottom: 0.3125rem solid $dell-sea-blue;
    border-top: 0.3125rem solid transparent;
    bottom: 0.7rem;
}

th.asc[aria-sort="ascending"] label::before,
th.desc[aria-sort="descending"] label::after {
    opacity: 0;
}

.dds__table-cmplx-empty tbody {
    text-align: center;
}

.dds__table-cmplx-top::after,
.dds__table-cmplx-bottom::after {
    clear: both;
    content: " ";
    display: table;
}

@media screen and (max-width: 767.98px) {
    .dds__table-cmplx {
        overflow-x: scroll;
    }

    .dds__table-cmplx-top {
        align-items: center;
        display: inherit;
        flex-direction: column;
        flex-wrap: nowrap;
        height: auto;
        padding: 0;
        width: 100%;

        .dds__table-cmplx-top-cntr {
            padding: 0.6125rem 0;

            label {
                white-space: nowrap;
            }

            .dds__btn-dropdown {
                width: 100% !important;

                .dds__btn.dds__btn-sm {
                    width: 100%;
                }
            }
        }

        .dds__table-cmplx-settings {
            float: right;
        }

        .dds__column-box.dds__show {
            width: 100%;
        }

        .dds__table-cmplx-placeholder {
            display: none;
        }
    }
}
