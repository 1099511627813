.dds__input-group {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;

    > .dds__form-control,
    > .dds__custom-select,
    > .dds__custom-file {
        flex: 1 1 auto;
        margin-bottom: 0;
        position: relative;
        width: 1%;

        + .dds__form-control,
        + .dds__custom-select,
        + .dds__custom-file {
            margin-left: -$input-border-width;
        }
    }

    > .dds__form-control:focus,
    > .dds__custom-select:focus,
    > .dds__custom-file .dds__custom-file-input:focus ~ .dds__custom-file-label {
        z-index: 3;
    }

    > .dds__custom-file .dds__custom-file-input:focus {
        z-index: 4;
    }

    > .dds__form-control,
    > .dds__custom-select {
        &:not(:last-child) {
            @include border-right-radius(0);
        }
        &:not(:first-child) {
            @include border-left-radius(0);
        }
    }

    > .dds__custom-file {
        align-items: center;
        display: flex;

        &:not(:last-child) .dds__custom-file-label,
        &:not(:last-child) .dds__custom-file-label::after {
            @include border-right-radius(0);
        }
        &:not(:first-child) .dds__custom-file-label {
            @include border-left-radius(0);
        }
    }
}

.dds__input-group-prepend {
    display: flex;

    .dds__btn {
        position: relative;
        z-index: 2;
    }

    .dds__btn + .dds__btn,
    .dds__btn + .dds__input-group-text,
    .dds__input-group-text + .dds__input-group-text,
    .dds__input-group-text + .dds__btn {
        margin-left: -$input-border-width;
    }
}

.dds__input-group-prepend {
    margin-right: -$input-border-width;
}

.dds__input-group-text {
    @include border-radius($input-border-radius);
    align-items: center;
    background-color: $input-group-addon-bg;
    border: $input-border-width solid $input-group-addon-border-color;
    color: $input-group-addon-color;
    display: flex;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
    line-height: $input-line-height;
    margin-bottom: 0;
    padding: $input-padding-y $input-padding-x;
    text-align: center;
    white-space: nowrap;

    input[type="radio"],
    input[type="checkbox"] {
        margin-top: 0;
    }
}

.dds__input-group > .dds__input-group-prepend > .dds__btn,
.dds__input-group > .dds__input-group-prepend > .dds__input-group-text {
    @include border-right-radius(0);
}

.dds__input-group > .dds__input-group-prepend:not(:first-child) > .dds__btn,
.dds__input-group > .dds__input-group-prepend:not(:first-child) > .dds__input-group-text,
.dds__input-group > .dds__input-group-prepend:first-child > .dds__btn:not(:first-child),
.dds__input-group > .dds__input-group-prepend:first-child > .dds__input-group-text:not(:first-child) {
    @include border-left-radius(0);
}
