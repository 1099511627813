// section { Moved to reboot
//   padding: 0.3125rem;
// }

// TODO: Verify if these classes are actually used
.dds__vertical-divider-left {
    border-left: 0.0625rem solid $dell-ash;
    padding-left: 0.5rem;
}

.dds__vertical-thick-divider-left {
    border-left: 0.125rem solid $dell-ash;
    padding-left: 0.5rem;
}

.dds__vertical-divider-right {
    border-right: 0.0625rem solid $dell-ash;
    padding-right: 0.5rem;
    text-align: right;
}

.dds__vertical-thick-divider-right {
    border-right: 0.125rem solid $dell-ash;
    padding-right: 0.5rem;
    text-align: right;
}

hr {
    // margin-top: 1.25rem; Using default from reboot
    // margin-bottom: 1.25rem; Using default from reboot

    &.dds__primary_divider {
        border-top: 0.0625rem solid $dell-granite;
    }

    &.dds__secondary_divider {
        border-top: 0.0625rem solid $dell-ash;
    }

    &.dds__tertiary_divider {
        border-top: 0.0625rem solid $dell-quartz;
    }

    &.dds__shadow_divider {
        border-top: 0.0625rem solid $dell-quartz;
        margin-left: -5%;
        min-height: 1.25rem;
        overflow: hidden;
        position: relative;
        width: 110%;
        z-index: 0;
    }

    &.dds__shadow_divider::before {
        border-radius: 50%;
        box-shadow: 0 0 0.6875rem rgba($dell-black, 0.4);
        content: "";
        height: 0.1875rem;
        left: 5%;
        position: absolute;
        top: -0.1875rem;
        width: 90%;
        z-index: 1;
    }
}

.dds__dell-container {
    @extend .dds__card;
    background-color: $dell-quartz;
    color: $dell-carbon;

    .dds__dell-container-body {
        padding: 1.1875rem;

        .dds__dell-container-title {
            @extend .dds__card-title;
        }

        .dds__dell-container-text {
            @extend .dds__card-text;
        }
    }

    /* Colorized containers */
    &.container-blue {
        background-color: $dell-blue;
        border: none;
        color: $dell-white;

        .dds__dell-container-text {
            color: $dell-white;
        }
    }

    &.container-midnight {
        background-color: $dell-midnight;
        border: none;
        color: $dell-white;

        .dds__dell-container-text {
            color: $dell-white;
        }
    }

    &.container-red {
        background-color: $dell-red;
        border: none;
        color: $dell-white;

        .dds__dell-container-text {
            color: $dell-white;
        }
    }

    &.container-berry {
        background-color: $dell-berry;
        border: none;
        color: $dell-white;

        .dds__dell-container-text {
            color: $dell-white;
        }
    }

    &.container-purple {
        background-color: $dell-purple;
        border: none;
        color: $dell-white;

        .dds__dell-container-text {
            color: $dell-white;
        }
    }

    &.container-gray {
        background-color: $dell-moon;
        border: none;
        color: $dell-white;

        .dds__dell-container-text {
            color: $dell-white;
        }
    }

    &.container-moon {
        background-color: $dell-moon;
        border: none;
        color: $dell-white;

        .dds__dell-container-text {
            color: $dell-white;
        }
    }

    &.container-carbon {
        background-color: $dell-carbon;
        border: none;
        color: $dell-white;

        .dds__dell-container-text {
            color: $dell-white;
        }
    }

    /* Stroked */
    &.stroked {
        background-color: $dell-white;
        border: 0.0625rem solid $dell-ash;
        color: $dell-black;

        .dds__dell-container-title {
            color: $dell-carbon;
        }

        .dds__dell-container-text {
            color: $dell-black;
        }

        /* Colorized containers */
        &.container-blue {
            border-color: $dell-blue;

            .dds__dell-container-title {
                color: $dell-blue;
            }
        }

        &.container-midnight {
            border-color: $dell-midnight;

            .dds__dell-container-title {
                color: $dell-midnight;
            }
        }

        &.container-purple {
            border-color: $dell-purple;

            .dds__dell-container-title {
                color: $dell-purple;
            }
        }

        &.container-green {
            border-color: $dell-green;

            .dds__dell-container-title {
                color: $dell-green;
            }
        }

        &.container-orange {
            border-color: $dell-orange;

            .dds__dell-container-title {
                color: $dell-orange;
            }
        }

        &.container-yellow {
            border-color: $dell-yellow;

            .dds__dell-container-title {
                color: $dell-yellow;
            }
        }

        &.container-berry {
            border-color: $dell-berry;

            .dds__dell-container-title {
                color: $dell-berry;
            }
        }

        &.container-red {
            border-color: $dell-red;

            .dds__dell-container-title {
                color: $dell-red;
            }
        }
        //Expiring container-gray and moving to container-moon, keeping class name here in case a customer is using container-gray class
        &.container-gray {
            border-color: $dell-moon;

            .dds__dell-container-title {
                color: $dell-moon;
            }
        }

        &.container-moon {
            border-color: $dell-moon;

            .dds__dell-container-title {
                color: $dell-moon;
            }
        }

        &.container-carbon {
            border-color: $dell-carbon;

            .dds__dell-container-title {
                color: $dell-carbon;
            }
        }
    }
}
