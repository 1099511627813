.dds__form-control {
    @include box-shadow($input-box-shadow);
    @include transition($input-transition);
    @include form-control-focus();
    background-clip: padding-box;
    background-color: $input-bg;
    border: $input-border-width solid $input-border-color;
    color: $input-color;
    display: block;
    font-size: $font-size-base;
    height: $input-height;
    line-height: $input-line-height;
    padding: $input-padding-y $input-padding-x;
    width: 100%;

    &::-ms-expand {
        background-color: transparent;
        border: 0;
    }

    &::placeholder {
        color: $input-placeholder-color;
        opacity: 1;
    }

    &:disabled,
    &[readonly] {
        background-color: $dell-mist;
        opacity: 1;
    }
    @if $enable-rounded {
        border-radius: $input-border-radius;
    } @else {
        border-radius: 0;
    }
}

select.dds__form-control {
    &:focus::-ms-value {
        background-color: $input-bg;
        color: $input-color;
    }

    &[size],
    &[multiple] {
        height: auto;
    }
}

textarea.dds__form-control {
    height: auto;
}

.dds__form-group {
    margin-bottom: $form-group-margin-bottom;

    label {
        color: $dell-black;
    }
}

.dds__form-text {
    display: block;
    margin-top: $form-text-margin-top;
}

.dds__form-row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;

    > .dds__col,
    > [class*="col-"] {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.dds__form-check-input {
    margin-left: -$form-check-input-gutter;
    margin-top: $form-check-input-margin-y;
    position: absolute;

    &:disabled ~ .dds__form-check-label {
        color: $text-muted;
    }
}

@include form-validation-state("valid", $form-feedback-valid-color);
@include form-validation-state("invalid", $form-feedback-invalid-color);

.dds__form-check {
    @include box-shadow($input-box-shadow);
    @include transition($input-transition);
    @include form-control-focus();
    display: block;
    margin-bottom: 0;
    padding-left: 0;
    position: relative;

    span {
        margin-left: 1.25rem;
    }

    input:disabled ~ span {
        color: $text-muted;
    }

    &::-ms-expand {
        background-color: transparent;
        border: 0;
    }

    &::placeholder {
        color: $input-placeholder-color;
        opacity: 1;
    }
}

.dds__form-check-inline {
    align-items: center;
    display: inline-flex;
    margin-right: $form-check-inline-margin-x;
    padding-left: 0;

    .dds__form-check-input {
        margin-left: 0;
        margin-right: $form-check-inline-input-margin-x;
        margin-top: 0;
        position: static;
    }
}

.dds__form-check-label {
    display: flex;
    line-height: 2.125rem;
    margin-bottom: 0;
    //   margin-left: .125rem;
    vertical-align: middle;

    span {
        line-height: 1.3125rem;
        margin-top: 0.375rem;
    }

    .dds__form-check-input {
        float: left;
        height: 0.9375rem;
        margin-left: 0;
        margin-top: 0.625rem;
        position: absolute;
        width: 0.9375rem;

        &:focus {
            outline: $custom-focus-outline;
            outline-offset: $custom-focus-offset;
        }
    }
}

.dds__inline-radio-btns,
.dds__inline-checkboxes {
    display: flex;
    flex-direction: row;

    .dds__form-check:not(:last-child) {
        margin-right: 1.25rem;
    }
}

.dds__alert-styling {
    background-color: $dell-alert-yellow;
    border: $dell-red solid 0.0625rem;
    color: #000;
    padding: 0.625rem;

    ~ .dds__invalid-feedback {
        display: block;
    }
}

//========= Validation ===================

.dds__was-validated .dds__form-control:invalid,
.dds__form-control.dds__is-invalid,
.dds__was-validated .dds__custom-select:invalid,
.dds__custom-select.dds__is-invalid {
    background-color: $dell-alert-yellow;
    border-color: $dell-red;
    color: $dell-black;
}

.dds__was-validated .dds__form-control:valid,
.dds__form-control.dds__is-valid,
.dds__was-validated .dds__custom-select:valid,
.dds__custom-select.dds__is-valid {
    background-color: none !important;
    border-color: $input-border-color;

    &:focus {
        border-color: $input-focus-border-color !important;
        box-shadow: $input-focus-box-shadow !important;
    }
}

.dds__invalid-feedback {
    margin: 0.25rem 0 0;
}

.dds__invalid-feedback {
    color: $dell-red;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover select:-webkit-autofill,
select:-webkit-autofill:hover {
    border: 0.0625rem solid $input-border-color;
    box-shadow: 0 0 0 62.5rem $dell-white inset; /* stylelint-disable */
    -webkit-box-shadow: 0 0 0 62.5rem $dell-white inset; /* stylelint-enable */
    -webkit-text-fill-color: none;
    transition: none 5000s ease-in-out 0s;
}

select:-webkit-autofill:focus,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus {
    border-color: $input-focus-border-color !important;
    box-shadow: $input-focus-box-shadow !important;
}

.dds__input-shell {
    display: flex;
    flex-direction: column;
    line-height: 1.75;
    position: relative;

    span {
        background-color: transparent;
        color: $input-placeholder-color;
        left: 0.828rem;
        pointer-events: none;
        position: absolute;
        text-transform: uppercase;
        top: 0.445rem;

        i {
            /* any of these 3 will work */
            color: transparent;
            font-style: normal;
        }
    }

    input.dds__input-masked {
        background-color: transparent;
        text-transform: uppercase;
    }

    input.dds__input-masked::-ms-clear {
        display: none;
    }
}

.dds__form-group-upload {
    .dds__form-upload-wrapper {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
    }

    .dds__icons {
        font-size: 1rem;
        margin-right: 0.375rem;
    }

    input[type="file"] {
        position: absolute !important;
        height: 0.063rem;
        width: 0.063rem;
        overflow: hidden;
        clip: rect(0.063rem, 0.063rem, 0.063rem, 0.063rem);

        &:focus + label {
            background-color: $dell-ash;
            border-color: $btn-border-gray;
            color: $dell-midnight;
        }
    }

    .dds__form-upload-btn {
        @include mixin_dds__upload-btn;
        border-color: $btn-border-gray !important;
        color: $dell-sea-blue !important;
        margin: 0;
    }

    .dds__form-upload-placeholder {
        flex: 1;
        display: flex;
        margin-left: 0.5rem;
        font-size: 0.75rem;
        line-height: 0.938rem;
        @media screen and (max-width: 577.98px) {
            flex-basis: 100%;
            margin-left: 0;
            margin-top: 0.5rem;
        }
    }

    .dds__form-upload-target {
        flex: 1;
        display: flex;
        align-self: stretch;
        flex-basis: 100%;
        width: 100%;
        flex-wrap: wrap;

        .dds__form-upload-file-size {
            margin-left: 0.313rem;
            flex: 1;
            display: flex;
            font-size: 0.75rem;
        }

        .dds__form-text {
            margin-top: 0.625rem;
            flex: 1;
            display: flex;
            align-self: stretch;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            border-bottom: solid 0.063rem #cccccc;
            flex-wrap: wrap;

            > button {
                align-self: flex-end;
                i {
                    display: inline-block;
                    transform: translateY(0.125rem);
                }

                &:hover {
                    i {
                        text-decoration: none;
                    }
                }
            }

            .dds__alert {
                flex-basis: 100%;
                margin-bottom: 0.75rem;
                padding-top: 0.625rem;
                padding-bottom: 0.625rem;

                .dds__icons {
                    margin-top: 0.188rem;
                    margin-right: 0.125rem;
                }
            }
        }
    }
}

body.user-is-tabbing {
    .dds__form-group-upload {
        input[type="file"] {
            &:focus + label {
                outline: $custom-focus-outline;
                outline-offset: $custom-focus-offset;
                outline-offset: 0.125rem -webkit-focus-ring-color !important;
            }
        }
    }
}
