.dds__modal-open {
    overflow: hidden;

    .dds__modal {
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.dds__modal {
    bottom: 0;
    display: none;
    left: 0;
    outline: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    z-index: $zindex-modal;

    .dds__close {
        font-size: 1rem;
        opacity: 1;
        position: absolute;
        right: 1rem;
        top: 1rem;
    }
}

.dds__modal-dialog {
    margin: $modal-dialog-margin;
    pointer-events: none;
    position: relative;
    top: 20%;
    width: auto;

    .dds__modal.dds__fade & {
        @include transition($modal-transition);
        transform: translate(0, -25%);
    }

    .dds__modal.dds__show & {
        transform: translate(0, 0);
    }
}

.dds__modal-content {
    @include box-shadow($modal-content-box-shadow-xs);
    background-clip: padding-box;
    background-color: $modal-content-bg;
    border: $modal-content-border-width solid $modal-content-border-color;

    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    outline: 0;
    padding: 1.5rem;
    pointer-events: auto;
    position: relative;
    width: 100%;
}

.dds__modal-header {
    border-bottom: $modal-header-border-width solid $modal-header-border-color;
    display: inline;
    padding: 0.05rem;

    .dds__modal-title {
        line-height: $modal-title-line-height;
        margin-bottom: 0;
        word-wrap: break-word;
    }
}

.dds__modal-title {
    line-height: $modal-title-line-height;
    margin-bottom: 0;
}

.dds__modal-body {
    flex: 1 1 auto;
    padding: $modal-inner-padding;
    position: relative;
}

.dds__modal-footer {
    align-items: center;
    border-top: $modal-footer-border-width solid $modal-footer-border-color;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    padding: $modal-inner-padding;
    padding-bottom: 0;

    padding-right: 0;
    > :not(:first-child) {
        margin-left: 0.25rem;
    }
    > :not(:last-child) {
        margin-right: 0.25rem;
    }

    button.dds__btn {
        margin-bottom: 8px;
    }
}

.dds__modal-header,
.dds__modal-body {
    padding-bottom: 0.375rem;
    padding-left: 0;
}

@include media-breakpoint-up(sm) {
    .dds__modal-dialog {
        margin: $modal-dialog-margin-y-sm-up auto;
        max-width: $modal-md;
    }

    .dds__modal-dialog-centered {
        min-height: calc(100% - (#{$modal-dialog-margin-y-sm-up} * 2));

        &::before {
            height: calc(100vh - (#{$modal-dialog-margin-y-sm-up} * 2));
        }
    }

    .dds__modal-content {
        @include box-shadow($modal-content-box-shadow-sm-up);
    }

    .dds__modal-sm {
        max-width: $modal-sm;
    }
}

@include media-breakpoint-up(lg) {
    .dds__modal-lg {
        max-width: $modal-lg;
    }
}

// LOADING MODAL
.dds__loading-modal {
    .dds__modal-dialog {
        left: 50%;
        margin-left: -7.5rem;
        margin-top: -3.125rem;
        position: absolute;
        top: 50%;
    }

    .dds__modal-body {
        padding: 1rem;
    }
}

// // OFF CANVAS MODAL
.dds__modal-offcanvas {
    background-color: $dell-white;
    bottom: auto;
    height: 100vh;
    left: auto;
    overflow-y: scroll;
    right: -100%;
    transition: right 200ms ease 0s;
    width: 90vw;

    &.dds__show {
        right: 0;

        .dds__container-fluid:not(.dds__modal-body) {
            position: fixed;
            position: sticky;
        }

        &.dds__is-safari {
            padding-bottom: 7.5rem;
        }
    }

    .dds__modal-content {
        border: none;
        padding: 0;
    }

    .dds__container-fluid:not(.dds__modal-body) {
        background-color: $dell-quartz;
        border: 0.0625rem solid $dell-ash;
        position: fixed;
        top: 0;
        z-index: $zindex-modal + 1;
    }

    .dds__modal-body {
        padding: 1.5rem;
        padding-top: 1.5rem;
        transform: translateY(2.75rem); /* stylelint-disable */
        -ms-transform: translateY(2.75rem); /* stylelint-enable */
    }

    &.dds__modal-width-25 {
        width: 25vw;
    }

    &.dds__modal-width-50 {
        width: 50vw;
    }

    &.dds__modal-width-75 {
        width: 75vw;
    }

    &.dds__modal-width-100 {
        width: 100vw;
    }
}

@media screen and (max-width: 991.98px) {
    .dds__modal-offcanvas {
        width: 100vw !important;
    }
}
