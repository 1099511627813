@import "utilities/dds__align";
@import "utilities/dds__background";
@import "utilities/dds__borders";
@import "utilities/dds__display";
@import "utilities/dds__flex";
@import "utilities/dds__float";
@import "utilities/dds__position";
@import "utilities/dds__rotate";
@import "utilities/dds__screenreaders";
@import "utilities/dds__shadows";
@import "utilities/dds__sizing";
@import "utilities/dds__spacing";
@import "utilities/dds__text";
@import "utilities/dds__visibility";

.dds__disabled,
:disabled,
div.dds__btn-dropdown ul.dds__button-dropdown-container li a:disabled.dds__dropdown-item, //need specificity in order to avoid using important
div.dds__btn-dropdown ul.dds__button-dropdown-container li a.dds__disabled.dds__dropdown-item,
div.dds__btn-dropdown ul.dds__button-dropdown-container li button:disabled.dds__dropdown-item,
div.dds__btn-dropdown ul.dds__button-dropdown-container li button.dds__disabled.dds__dropdown-item,
div.dds__btn-dropdown button.dds__btn.dds__disabled svg,
div.dds__btn-dropdown button.dds__btn:disabled svg, //regular dropdown svgs
input:disabled ~ span,
input.dds__disabled ~ span {
    cursor: not-allowed;
}

input[type="search"] {
    /* stylelint-disable */
    -webkit-appearance: none; //Overwrites Safari's built in search bar /* stylelint-enable */
}

@include media-breakpoint-up(xs) {
    i.dds__icons.dds__icon-h1 {
        font-size: 1.5rem + 0.375rem;
    }

    i.dds__icons.dds__icon-h2 {
        font-size: 1.25rem + 0.375rem;
    }

    i.dds__icons.dds__icon-h3 {
        font-size: 1.0625rem + 0.375rem;
    }

    i.dds__icons.dds__icon-h4 {
        font-size: 1rem + 0.375rem;
    }

    i.dds__icons.dds__icon-h5 {
        font-size: 0.875rem + 0.375rem;
    }
}

@include media-breakpoint-up(sm) {
    i.dds__icons.dds__icon-h1 {
        font-size: 1.875rem + 0.375rem;
    }

    i.dds__icons.dds__icon-h2 {
        font-size: 1.625rem + 0.375rem;
    }

    i.dds__icons.dds__icon-h3 {
        font-size: 1.25rem + 0.375rem;
    }

    i.dds__icons.dds__icon-h4 {
        font-size: 1.125rem + 0.375rem;
    }

    i.dds__icons.dds__icon-h5 {
        font-size: 1rem + 0.375rem;
    }
}

@include media-breakpoint-up(md) {
    i.dds__icons.dds__icon-h1 {
        font-size: 2rem + 0.375rem;
    }

    i.dds__icons.dds__icon-h2 {
        font-size: 1.75rem + 0.375rem;
    }

    i.dds__icons.dds__icon-h3 {
        font-size: 1.25rem + 0.375rem;
    }

    i.dds__icons.dds__icon-h4 {
        font-size: 1.125rem + 0.375rem;
    }

    i.dds__icons.dds__icon-h5 {
        font-size: 1rem + 0.375rem;
    }
}

@include media-breakpoint-up(lg) {
    i.dds__icons.dds__icon-h1 {
        font-size: 2rem + 0.375rem;
    }

    i.dds__icons.dds__icon-h2 {
        font-size: 1.75rem + 0.375rem;
    }

    i.dds__icons.dds__icon-h3 {
        font-size: 1.25rem + 0.375rem;
    }

    i.dds__icons.dds__icon-h4 {
        font-size: 1.125rem + 0.375rem;
    }

    i.dds__icons.dds__icon-h5 {
        font-size: 1rem + 0.375rem;
    }
}

@include media-breakpoint-up(xl) {
    i.dds__icons.dds__icon-h1 {
        font-size: 2rem + 0.375rem;
    }

    i.dds__icons.dds__icon-h2 {
        font-size: 1.75rem + 0.375rem;
    }

    i.dds__icons.dds__icon-h3 {
        font-size: 1.25rem + 0.375rem;
    }

    i.dds__icons.dds__icon-h4 {
        font-size: 1.125rem + 0.375rem;
    }

    i.dds__icons.dds__icon-h5 {
        font-size: 1rem + 0.375rem;
    }
}

// a:focus{
//   outline: $custom-focus-outline;
//   outline-offset: $custom-focus-offset;
// }
