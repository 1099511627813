.dds__slider {
    flex: 1 1 100%;
    width: auto;
}

.dds__slider-target,
.dds__slider-target * {
    box-sizing: border-box;
    touch-action: none;
    user-select: none;
}

.dds__slider-target {
    direction: ltr;
}

.dds__slider-base,
.dds__slider-connects {
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 1;
}

.dds__slider-connects {
    overflow: hidden;
    z-index: 0;
}

.dds__slider-connect,
.dds__slider-origin {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform-origin: 0 0;
    transform-style: flat;
    width: 100%;
    will-change: transform;
    z-index: 1;

    :focus {
        outline: none !important;
    }
}

html:not([dir="rtl"]) .dds__slider-horizontal .dds__slider-origin {
    left: auto;
    right: 0;
}

.dds__slider-vertical {
    height: 100%;
}

.dds__slider-vertical .dds__slider-origin {
    width: 0;
}

.dds__slider-horizontal .dds__slider-origin {
    height: 0;
}

.dds__slider-handle {
    backface-visibility: hidden;
    border: 0.0625rem solid $dell-blue;
    position: absolute;
}

.dds__slider-touch-area {
    height: 100%;
    width: 100%;
}

.dds__slider-state-tap .dds__slider-connect,
.dds__slider-state-tap .dds__slider-origin {
    transition: transform 0.3s;
}

.dds__slider-state-drag * {
    cursor: inherit !important;
}

.dds__slider-horizontal {
    height: $slider-height;
}

.dds__slider-horizontal .dds__slider-handle {
    height: 2.375rem;
    left: -1.0625rem;
    top: -0.6875rem;
    width: 1.5rem;
}

.dds__slider-vertical .dds__slider-handle {
    height: 1.5rem;
    left: -0.6875rem;
    top: -0.8125rem;
    width: 2.375rem;
}

html:not([dir="rtl"]) .dds__slider-horizontal .dds__slider-handle {
    left: auto;
    right: -0.75rem;
}

.dds__slider-target {
    background: $dell-white;
    border: $border-default;
    border-radius: $slider-border-radius;
    box-shadow: inset 0 0.0625rem 0.0625rem #f0f0f0, 0 0.1875rem 0.375rem -0.3125rem #bbb;
}

.dds__slider-connects {
    border-radius: $slider-border-radius;
}

.dds__slider-connect {
    background: $dell-blue;
}

.dds__slider-draggable {
    cursor: ew-resize;
}

.dds__slider-vertical .dds__slider-draggable {
    cursor: ns-resize;
}

.dds__slider-handle {
    backface-visibility: hidden;
    background: $dell-white;
    border: 0.0625rem solid $dell-blue;
    border-radius: $border-radius;
    box-shadow: inset 0 0 0.0625rem #fff, inset 0 0.0625rem 0.4375rem #ebebeb, 0 0.1875rem 0.375rem -0.1875rem #bbb;
    cursor: pointer;
    position: absolute;
}

.dds__slider-active {
    box-shadow: inset 0 0 0.0625rem #fff, inset 0 0.0625rem 0.4375rem #ddd, 0 0.1875rem 0.375rem -0.1875rem #bbb;

    .dds__slider-tooltip {
        display: block;
        opacity: 1;
    }
}

.dds__slider-handle::before,
.dds__slider-handle::after {
    background: $dell-blue;
    content: "";
    display: block;
    height: 1.0625rem;
    left: 0.4375rem;
    position: absolute;
    top: 0.5625rem;
    width: 0.125rem;
}

.dds__slider-handle::after {
    left: 0.8125rem;
}

.dds__slider-vertical .dds__slider-handle::before,
.dds__slider-vertical .dds__slider-handle::after {
    height: 0.125rem;
    left: 0.5625rem;
    top: 0.4375rem;
    width: 1.0625rem;
}

.dds__slider-vertical .dds__slider-handle::after {
    top: 0.85rem;
}

[disabled] .dds__slider-connect {
    background: #b8b8b8;
}

[disabled].dds__slider-target,
[disabled].dds__slider-handle,
[disabled] .dds__slider-handle {
    cursor: not-allowed;
}

.dds__slider-value {
    position: absolute;
    text-align: center;
    white-space: nowrap;
}

.dds__slider-value-sub {
    color: $dell-ash;
    font-size: 0.625rem;
}

.dds__slider-marker {
    background: $dell-ash;
    position: absolute;
}

.dds__slider-marker-sub {
    background: $dell-granite;
}

.dds__slider-marker-large {
    background: $dell-granite;
}

.dds__slider-value-horizontal {
    transform: translate(-50%, 50%);
}

.dds__slider-rtl .dds__slider-value-horizontal {
    transform: translate(50%, 50%);
}

.dds__slider-marker-horizontal.dds__slider-marker {
    height: 0.3125rem;
    margin-left: -0.0625rem;
    width: 0.125rem;
}

.dds__slider-marker-horizontal.dds__slider-marker-sub {
    height: 0.625rem;
}

.dds__slider-marker-horizontal.dds__slider-marker-large {
    height: 0.9375rem;
}

.dds__slider-value-vertical {
    padding-left: 1.5625rem;
    transform: translate(0, -50%);
}

.dds__slider-rtl .dds__slider-value-vertical {
    transform: translate(0, 50%);
}

.dds__slider-marker-vertical.dds__slider-marker {
    height: 0.125rem;
    margin-top: -0.0625rem;
    width: 0.3125rem;
}

.dds__slider-marker-vertical.dds__slider-marker-sub {
    width: 0.625rem;
}

.dds__slider-marker-vertical.dds__slider-marker-large {
    width: 0.9375rem;
}

.dds__slider-tooltip {
    @include mixin_dds__slider-tooltip;
    background: #fff;
    border: 0.0625rem solid #d9d9d9;
    border-radius: 0.1875rem;
    color: #000;
    display: block;
    padding: 0.3125rem;
    pointer-events: none;
    position: absolute;
    text-align: center;
    white-space: nowrap;
}

.dds__slider-horizontal .dds__slider-tooltip {
    bottom: 120%;
    left: 50%;
    transform: translate(-50%, 0);
}

.dds__slider-vertical .dds__slider-tooltip {
    right: 120%;
    top: 50%;
    transform: translate(0, -50%);
}

.dds__slider-container {
    flex: 1 1 100%;
    flex-direction: row;

    &.dds__slider-container-vertical {
        align-items: center;
        flex-direction: column;
        width: 1.0625rem;
    }

    .dds__slider-wrapper {
        margin: 0 1.25rem;
    }

    .dds__slider-wrapper-single {
        display: block;
        height: 100%;
        margin-right: 1.25rem;
        width: 100%;
    }

    &.dds__slider-container-vertical .dds__slider-wrapper,
    &.dds__slider-container-vertical .dds__slider-wrapper-single {
        margin: 1rem 1.0625rem;
    }

    .dds__slider-max,
    .dds__slider-min {
        display: flex;
        justify-content: center;
        min-width: 2.125rem;
    }

    &:not(.dds__slider-container-vertical) .dds__slider-min,
    &:not(.dds__slider-container-vertical) .dds__slider-max {
        position: relative;
        top: -0.125rem;
    }

    &:not(.dds__slider-container-vertical) .dds__slider-min {
        justify-content: flex-end;
    }

    &:not(.dds__slider-container-vertical) .dds__slider-max {
        justify-content: flex-start;
    }
}
