.dds__skip-nav {
    background-color: $dell-white;
    border: 0.0625rem solid $dell-granite;
    padding: 0.3125rem 1.875rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $zindex-hidden;
    opacity: 0;

    .dds__btn {
        margin: 0.25rem 0.5rem;
    }
}
