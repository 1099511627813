// When using the Footer the content element above the footer needs to have the following CSS.
// In the example of the Developer site that would be the <main> element.
body main {
    flex-direction: column;
    flex: 1 0 auto;
    min-height: 0.0625rem;
}

footer {
    flex-shrink: 0;
}

.dds__default-footer {
    background-color: $dell-blue;
    bottom: 0;
    color: $dell-white;
    margin-top: 3rem;
    width: 100%;

    .dds__footer-nav {
        background-color: $dell-quartz;
        border-top: 0.0625rem solid rgba(0, 0, 0, 0.2);
        display: flex;
        padding: 1.25rem 0.9375rem;

        .dds__container {
            display: flex;
            justify-content: space-between;
        }

        .dds__page-nav {
            align-content: center;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            list-style: none;
            margin-bottom: 0;
            padding: 0;

            li {
                padding-right: 0.8125rem;

                a {
                    color: $dell-black;
                }
            }
        }

        .dds__footer-icon-ctnr {
            align-items: center;
            background: 0 0;
            border-style: none;
            color: $dell-black;
            cursor: pointer;
            display: flex;
            flex-wrap: nowrap;

            span {
                display: inherit;
                flex-wrap: nowrap;
                margin-left: 0.3125rem;
            }
        }

        .dds__country-selector {
            position: relative;

            .dds__country-list {
                background: $dell-white;
                border: 0.0625rem solid rgba(0, 0, 0, 0.15);
                border-right: none;
                display: none;
                height: 15.625rem;
                overflow-x: hidden;
                overflow-y: scroll;
                -webkit-overflow-scrolling: touch;
                padding: 0;
                position: absolute;
                right: 0;
                top: -16rem;
                width: 15.625rem;

                &.dds__show {
                    display: block;
                }

                li {
                    display: block;
                    height: auto;
                    text-align: left;
                    width: 100%;
                    z-index: -1;
                    position: relative;

                    &:hover {
                        background-color: $dell-sky;
                        cursor: pointer;
                    }

                    a {
                        color: $dell-black;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }

            .dds__footer-icon-ctnr .dds__svg-icons {
                height: 1rem;
                padding-bottom: 0.25rem;
                transform: rotate(90deg);
                width: 1rem;
            }

            &.dds__show .dds__footer-icon-ctnr .dds__svg-icons {
                padding-bottom: 0;
                padding-top: 0.25rem;
                transform: rotate(-90deg);
            }

            .dds__svg-globe-simple {
                height: 1.5rem;
                width: 1.5rem;
            }
        }
    }

    .dds__footer-legal-nav {
        border-bottom: 0.0625rem solid rgba($dell-quartz, 0.5);
        display: flex;
        padding: 1.25rem 0.9375rem;

        ul {
            align-content: center;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            list-style: none;
            margin-bottom: 0;
            padding: 0;

            li {
                padding-right: 0.8125rem;

                a {
                    color: $dell-white;
                }
            }
        }
    }

    .dds__footer-birdseed {
        padding: 1.25rem 0.9375rem;

        a {
            color: $dell-white;
            text-decoration: underline;
        }
    }
}

@media screen and (max-width: 991.98px) {
    .dds__default-footer {
        .dds__container {
            max-width: none;
        }

        .dds__footer-nav,
        .dds__footer-legal-nav {
            .dds__container {
                padding: 0;
            }
        }
    }
}

@media (max-width: 767.98px) {
    .dds__default-footer {
        .dds__container {
            display: block;
            max-width: 100%;
        }

        .dds__footer-nav,
        .dds__footer-legal-nav {
            .dds__container {
                display: block;
                max-width: 100%;
                padding: 0;
            }
        }

        .dds__footer-nav {
            display: flex;
            flex-direction: column;
            padding: 0;

            .dds__page-nav {
                flex-direction: column;

                li {
                    border-bottom: 0.0625rem solid $dell-ash;
                    padding: 0.875rem 0.9375rem;
                    width: 100%;
                }
            }

            .dds__country-selector {
                padding: 0.75rem 0.625rem;

                .dds__country-list {
                    left: 0;
                    margin: 0 0.5rem;
                    min-width: 0;
                    width: auto;
                    max-width: none;
                }
            }
        }

        .dds__footer-legal-nav {
            display: flex;
            flex-direction: column;
            padding: 0;

            ul {
                flex-direction: column;

                li {
                    border-bottom: 0.0625rem solid $dell-ash;
                    padding: 0.875rem 0.9375rem;
                    width: 100%;
                }
            }
        }
    }
}
