@mixin hover {
    &:hover {
        @content;
    }
}

@mixin hover-focus {
    &:hover,
    &:focus {
        @content;
    }
}

@mixin plain-hover-focus {
    &,
    &:hover,
    &:focus {
        @content;
    }
}

@mixin hover-focus-active {
    &:hover,
    &:focus,
    &:active {
        @content;
    }
}

@mixin mixin_dds__tabs-hover-focus {
    @include hover-focus {
        border-color: $dell-granite;
        background-color: $dell-sky;
        color: $dell-midnight;
        background-image: none;
        text-decoration: none;
        -webkit-transition: background-color 250ms ease;
        transition: background-color 250ms ease;

        .dds__tab-span_blue,
        .dds__h4 {
            color: $dell-midnight;
        }
    }
}
