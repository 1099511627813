@mixin button-variant(
    $background,
    $border,
    $hover-background: darken($background, 7.5%),
    $hover-border: darken($border, 10%),
    $active-background: darken($background, 10%),
    $active-border: darken($border, 12.5%)
) {
    color: color-yiq($background);
    @include gradient-bg($background);
    border-color: $border;
    @include box-shadow($btn-box-shadow);

    svg {
        fill: color-yiq($background);
    }

    @include hover {
        color: color-yiq($hover-background);
        @include gradient-bg($hover-background);
        border-color: $hover-border;

        svg {
            fill: color-yiq($hover-background);
        }
    }

    &:focus,
    &.dds__focus {
        @if $enable-shadows {
            box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($border, 0.5);
        } @else {
            box-shadow: 0 0 0 $btn-focus-width rgba($border, 0.5);
        }
    }
    &.dds__disabled,
    &:disabled {
        color: color-yiq($background);
        background-color: $background;
        border-color: $border;

        svg {
            fill: color-yiq($background);
        }
    }

    &:not(:disabled):not(.dds__disabled):active,
    &:not(:disabled):not(.dds__disabled).dds__active,
    .dds__show > &.dds__dropdown-toggle {
        color: color-yiq($active-background);
        background-color: $active-background;
        @if $enable-gradients {
            background-image: none;
        }
        border-color: $active-border;
        svg {
            fill: color-yiq($active-background); //do we need this?
        }
        &:focus {
            @if $enable-shadows {
                box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($border, 0.5);
            } @else {
                box-shadow: 0 0 0 $btn-focus-width rgba($border, 0.5);
            }
        }
    }
}

@mixin button-outline-variant(
    $color,
    $color-hover: color-yiq($color),
    $active-background: $color,
    $active-border: $color
) {
    color: $color;
    background-color: transparent;
    background-image: none;
    border-color: $color;

    svg {
        fill: $color;
    }
    &:hover {
        color: $color-hover;
        background-color: $active-background;
        border-color: $active-border;

        svg {
            fill: $color-hover;
        }
    }

    &:focus,
    &.dds__focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
    }

    &.dds__disabled,
    &:disabled {
        color: $color;
        background-color: transparent;

        svg {
            fill: $color;
        }
    }

    &:not(:disabled):not(.dds__disabled):active,
    &:not(:disabled):not(.dds__disabled).dds__active,
    .dds__show > &.dds__dropdown-toggle {
        color: color-yiq($active-background);
        background-color: $active-background;
        border-color: $active-border;

        svg {
            fill: color-yiq($active-background);
        }

        &:focus {
            @if $enable-shadows and $btn-active-box-shadow != none {
                box-shadow: $btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, 0.5);
            } @else {
                box-shadow: 0 0 0 $btn-focus-width rgba($color, 0.5);
            }
        }
    }
}
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
    padding: $padding-y $padding-x;
    font-size: $font-size;
    line-height: $line-height;
    @if $enable-rounded {
        border-radius: $border-radius;
    } @else {
        border-radius: 0;
    }
}
@mixin mixin_dds__msthd-search-btn() {
    @extend .dds__btn;
    @extend .dds__btn-primary;
}
@mixin mixin_dds__accordion-btn() {
    @extend .dds__btn;
    @extend .dds__btn-link;
}

@mixin mixin_dds__btn-secondary {
    @extend .dds__btn;
    @extend .dds__btn-secondary;
}

@mixin mixin_dds__upload-btn {
    @extend .dds__btn;
    @extend .dds__btn-secondary;
}
