.dds__close {
    color: $close-color;
    float: right;
    font-size: $close-font-size;
    font-weight: $close-font-weight;
    line-height: 1;
    opacity: 0.5;
    text-shadow: $close-text-shadow;

    &:not(:disabled):not(.dds__disabled) {
        cursor: pointer;

        @include hover-focus {
            color: $close-color;
            opacity: 0.75;
            text-decoration: none;
        }
    }
}

button.dds__close {
    background-color: transparent;
    border: 0;
    padding: 0;
}
