@mixin transition($transition...) {
    @if $enable-transitions {
        @if length($transition) == 0 {
            transition: $transition-base;
        } @else {
            transition: $transition;
        }
    }

    @media screen and (prefers-reduced-motion: reduce) {
        transition: none;
    }
}
