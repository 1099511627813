$positions: static, relative, absolute, fixed, sticky;

@each $position in $positions {
    .dds__position-#{$position} {
        position: $position !important;
    }
}

.dds__fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;
}

.dds__fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-fixed;
}

.dds__sticky-top {
    @supports (position: sticky) {
        position: sticky;
        top: 0;
        z-index: $zindex-sticky;
    }
}
