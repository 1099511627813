@mixin mixin_dds__chckbx-filter-state() {
    .dds__form-check {
        padding: 0rem 1.5rem;
        &:hover {
            background-color: $dell-sky;
        }
        &:focus {
            outline: none;
            background-color: $dell-sky;
        }
        &:active {
            color: $dell-white;
            background-color: $dell-blue;
        }
        .dds__form-check-label {
            width: 100%;
            margin-left: 0;
            input,
            span {
                cursor: pointer;
            }
        }
    }
}
