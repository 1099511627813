.dds__hero-banner {
    margin: 0;
    min-height: 0.0625rem;
    padding: 0;
    position: relative;
    width: 100%;

    .dds__card-body {
        bottom: auto;
        margin: 0 0 0 1.25rem;
        max-width: 26.25rem;
        min-width: 27.5rem;
        padding: 1.25rem;
        top: 2rem;
        width: 25%;

        h2,
        h4,
        h5,
        p {
            color: $dell-black;
        }

        a:hover {
            text-decoration: none;
        }
    }
}

.dds__link-picker {
    margin: 0;
    min-height: 0.0625rem;
    padding: 0;
    position: relative;
    width: 100%;

    .dds__card-body {
        bottom: 15%;
        height: 10.375rem;
        left: 12%;
        margin: 1.25rem;
        padding: 1.25rem;
        right: 12%;
        top: auto;
        width: auto;

        h2 {
            color: $dell-carbon;
            font-weight: 300;
        }
    }
}

.dds__die-cut-banner {
    background-image: linear-gradient(270deg, $dell-white, $dell-quartz 50%, $dell-white);
    border-top: 0.0625rem solid $dell-ash;
    display: flex;
    justify-content: center;
    min-height: 2.5rem;

    .dds__die-cut-body {
        display: inline;
        margin: 0.9375rem;

        .dds__die-cut-text {
            display: inline;
        }

        .dds__list-inline {
            display: inline;

            li:first-of-type {
                margin-left: 0.5rem;

                a {
                    white-space: nowrap;
                }
            }
        }
    }
}

.dds__banner-sitewide {
    background-color: $dell-mist;
    border-bottom: 0.0625rem solid $dell-ash;
    min-height: 2.5rem;
    padding: 0.5rem 0;

    .dds__container {
        flex-wrap: nowrap;
    }

    .dds__banner-image {
        align-self: center;
        height: 2.9375rem;
        margin-right: 0.9375rem;
        width: auto;
    }

    .dds__banner-body {
        align-self: center;
        flex: 2 1 auto;

        .dds__banner-text {
            display: inline;
        }

        .dds__list-inline {
            display: inline;

            li:first-of-type {
                margin-left: 0.5rem;

                a {
                    white-space: nowrap;
                }
            }
        }
    }

    .dds__banner-tools {
        border-left: 0.0625rem solid $dell-ash;
        display: flex;
        margin-left: 0.9375rem;
        padding: 0 0.9375rem;

        a {
            align-self: center;
            margin-right: 0.5rem;
            white-space: nowrap;

            svg {
                height: 1.25rem;
                margin-right: 0.0625rem;
                width: 1.25rem;
            }
        }
    }
}

.dds__link-picker-ctnr {
    align-items: center;
    display: flex;
    justify-content: center;
}

.dds__banner-btn-ctnr {
    a:not(:last-child) {
        margin-right: 0.5rem;
    }
}

.dds__picker-btn-ctnr {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 50%;
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;

    .dds__form-select {
        flex: 3 1 0%;

        .dds__display-text-ctnr > a {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }
    }

    .dds__display-text-ctnr {
        flex: 1 1 auto;

        .dds__display-text {
            left: 25%;
            margin-top: 0.625rem;
            position: absolute;
            right: 25%;

            span {
                color: $dell-carbon;
                font-size: 0.75rem;
                line-height: 1rem;
            }
        }

        .dds__display-text-ctnr > a {
            display: inline-block;
            text-align: center;
        }
    }

    div {
        margin: 0 1rem;
    }

    .dds__display-text-ctnr > button {
        width: 100%;
    }
}

.dds__mosaic {
    display: flex;
    flex-direction: row;
    height: 25rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 69.375rem;

    .dds__carousel-inner {
        display: block;
        width: 100%;

        .dds__background-img div.dds__card-img-top {
            background-position: center top;
            background-size: cover;
            height: 100%;
        }
    }

    .dds__carousel-indicators {
        display: none;
    }

    .dds__carousel-item {
        .dds__img-fluid {
            object-fit: cover;
        }

        .dds__card-body {
            .dds__card-link {
                color: $link-color;
                font-size: 1.25rem; // 20px;
                line-height: 1.5rem; // 24px;
                &:hover {
                    color: $link-hover-color;
                }
            }
        }
    }
}

@media screen and (min-width: 767.98px) {
    //medium + above
    .dds__mosaic {
        display: flex;

        .dds__carousel-inner {
            display: inherit;

            .dds__carousel-item {
                display: block;
            }

            .dds__mosaic-item {
                display: flex;
                flex: 1 1 50%;
                margin-right: 0.8125rem;
                width: auto;

                .dds__card-img-top {
                    height: 100%;
                }
            }

            .dds__mosaic-item:first-child {
                flex: 1 1 100%;
            }

            .dds__mosaic-item:nth-child(2) {
                .dds__carousel-item {
                    .dds__card-img-top {
                        height: 100%;

                        .dds__img-fluid {
                            object-fit: cover;
                        }
                    }
                }
            }

            .dds__mosaic-item:last-child {
                flex-direction: column;
                margin-right: 0;

                .dds__carousel-item:first-child {
                    height: 100%;
                    margin-bottom: 0.8125rem;
                }

                .dds__carousel-item:last-child {
                    // margin-top: 6.5px;
                    height: 100%;
                }
            }
        }
    }
}

@media screen and (min-width: 767.98px) and (max-width: 991.98px) {
    // just medium

    .dds__mosaic {
        height: 44.75rem; // 716px;
        width: 43.75rem; // 700px;

        .dds__carousel-inner {
            flex-wrap: wrap;
        }

        .dds__mosaic-item {
            .dds__carousel-item {
                .dds__card-body {
                    position: absolute;
                }
            }
        }

        .dds__mosaic-item:first-child {
            margin-bottom: 0.8125rem;
            margin-right: 0;
            max-height: 18.75rem;

            &.dds__background-img {
                max-height: 21.875rem;
            }
        }

        .dds__mosaic-item:nth-child(2) {
            flex: 1 1;
            max-height: 25rem;
        }

        .dds__mosaic-item:last-child {
            flex: 1 1;
            max-height: 25rem;
        }
    }
}

@media screen and (max-width: 991.98px) {
    @include compressed-state;
}

@media screen and (max-width: 767.98px) {
    //small and xs screens for carousel
    .dds__mosaic {
        align-items: stretch;
        display: flex;
        height: auto;

        .dds__carousel-inner {
            display: block;
            flex-wrap: nowrap;

            .dds__mosaic-item {
                width: 100%;

                &:first-child {
                    flex: none;
                }

                &.dds__background-img div.dds__card-img-top {
                    background-position: center top;
                    background-repeat: no-repeat;
                    background-size: contain;
                    min-height: 16.75rem;
                }
            }
        }

        .dds__card-img-top {
            align-items: center;
            display: flex;
            height: 16.75rem; // 268px;
            justify-content: center;
        }

        img {
            height: 100%;
            width: auto;
        }

        .dds__card-horizontal-bleed {
            align-items: center;
            margin-bottom: 0;
            width: 100%;

            a {
                display: block;
                height: auto;
            }

            .dds__card-body {
                background-color: $dell-mist;
                bottom: 0;
                display: inline-block;
                margin: 0;
                min-height: 7.0625rem;
                padding: 0.9375rem;
                position: relative;
                top: auto;
                width: 100%;
            }
        }

        .dds__mosaic-bottom {
            display: block;
            padding: 0;
            position: static;

            .dds__mosaic-col {
                padding: 0;
                position: static;
            }
        }

        .dds__carousel-indicators {
            align-items: flex-end;
            display: flex;
            margin-bottom: -2.8125rem;
        }
    }

    .dds__banner-sitewide {
        .dds__banner-image {
            display: none;
        }

        .dds__banner-tools {
            display: none;
        }
    }
}

@include media-breakpoint-up(xl) {
    .dds__body-ml-20 {
        .dds__hero-banner > .dds__container {
            margin-left: 0;
        }

        &.dds__active .dds__hero-banner > .dds__container {
            margin-left: auto;
        }
    }
}

@media screen and (min-width: 1320px) {
    .dds__body-ml-20.dds__active .dds__hero-banner > .dds__container .dds__card-body {
        margin-left: 0;
    }
}

@media screen and (min-width: 1800px) {
    .dds__body-ml-20 {
        .dds__hero-banner > .dds__container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transform: translateX(-($navLeft-element-width / 2));
            width: auto;
            margin-left: auto;
        }

        &.dds__active .dds__hero-banner > .dds__container {
            transform: translateX(0%);
        }

        &:not(.dds__active) .dds__hero-banner > .dds__container .dds__card-body {
            margin-left: 0;
        }
    }
}
