@-ms-viewport {
    width: auto !important;
}

.dds__drawer {
    background: $dell-white;
    border-bottom: 0.0625rem solid $dell-granite;
    border-left: 0.1875rem solid $dell-blue;
    border-right: 0.0625rem solid $dell-granite;
    border-top: 0.0625rem solid $dell-granite;
    box-shadow: 0.125rem 0.125rem 0.3125rem 0 rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    color: #444;
    font-size: 0.875rem;
    position: fixed;
    right: -15.875rem;
    top: 14.0625rem;
    transition: right 250ms ease-out;
    width: 15.875rem; //drawer content width + padding left + padding right
    z-index: 1039;

    &.dds__drawer-open {
        right: 0;
    }

    &*:focus {
        outline: $custom-focus-outline;
        outline-offset: $custom-focus-offset;
    }
}

a.dds__drawer-tab {
    background: $dell-blue;
    box-shadow: 0.125rem 0.125rem 0.3125rem 0 rgba(0, 0, 0, 0.3);
    color: $dell-white;
    left: -0.1875rem;
    line-height: 1.75rem;
    padding: 0 0.9375rem;
    position: absolute;
    text-decoration: none;
    top: -0.0625rem;
    transform: rotate(90deg);
    transform-origin: top left;
    z-index: -1;

    &:hover {
        color: $dell-white;
        text-decoration: none;
    }
}

.dds__drawer-tab-text {
    transform: rotate(180deg);
}

.dds__drawer-content {
    background: $dell-white;
    padding: 0.625rem 2.125rem 0.625rem 1.25rem;

    p {
        margin: 0.625rem 0;

        &.dds__drawer-content-ad {
            margin: 0;
        }
    }

    hr {
        border: none;
        border-top: 0.0625rem solid $dell-granite;
    }

    .dds__primary_divider {
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .dds__drawer-txt-mdf {
        width: 8rem;
        color: $dell-carbon;
        display: inline-block;
    }
}

.dds__drawer-content-ad {
    a {
        color: #444;
        display: block;
        line-height: 2.125rem;
        padding-left: 1.5rem + 1rem;
        position: relative;
        text-decoration: none;

        &:hover {
            color: #444;
            text-decoration: underline;
        }

        .dds__drawer-content-ad-logo {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            display: inline-block;
            height: 2.125rem;
            left: 0;
            position: absolute;
            width: 1.5rem;
        }
    }
}

.dds__drawer-list {
    list-style: none;
    margin: 0;
    padding: 0 2.125rem 0 0;

    > li {
        margin: 0.625rem 0;
        position: relative;

        > a {
            color: $dell-sea-blue;
            line-height: 1.5rem;
            text-decoration: none;
            white-space: nowrap;

            span {
                display: inline-block;
                vertical-align: middle;
                white-space: normal;
            }

            &:hover {
                color: $dell-midnight;
                text-decoration: none;

                span {
                    text-decoration: underline;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .dds__drawer {
        border-left: 0.0625rem solid $dell-granite;
        border-top: 0.1875rem solid $dell-blue;
        bottom: -18.75rem;
        left: 0;
        right: auto;
        top: auto;
        transition: bottom 250ms ease-out;

        &.dds__drawer-open {
            bottom: 0;
            right: auto;
        }
    }

    a.dds__drawer-tab {
        background: $dell-blue;
        bottom: 0.625rem;
        display: block;
        height: 2.125rem;
        left: 0.625rem;
        overflow: hidden;
        padding: 0;
        position: fixed;
        top: auto;
        transform: none;
        width: 2.125rem;

        &::before {
            content: "\1f5e8";
            font-family: "dds-icons" !important;
            font-size: 1.5rem;
            font-style: normal;
            left: (2.125rem - 1.5rem) / 2;
            position: relative;
            top: (2.125rem - 1.5rem * 1.15) / 2;
            vertical-align: middle;
        }
    }

    .dds__drawer-tab-text {
        left: 100%;
        position: relative;
        transform: none;
    }
}

.dell-tech-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "dds-icons" !important;
    font-size: 1.5rem;
    font-style: normal;
    padding-right: 0.785rem;
    vertical-align: middle;
}

.dds__drawer-img-icon {
    margin-right: 0.785rem;
    vertical-align: top;
    display: inline-block;
    max-width: 1.56rem;
    max-height: 1.56rem;
}

.dds__drawer-content > .dds__close-x {
    background-color: transparent;
    border: none;
    cursor: pointer;
    opacity: 1;
    position: absolute;
    right: 0.625rem;
    top: 0.375rem;

    &::before {
        color: $dell-black;
        content: $dds__close-x;
        font-size: 0.825rem;
    }
}

.dds__drawer-content > .dds__comment::before {
    content: $dds__comment;
}

.dds__drawer-content > .dds__mail::before {
    content: $dds__mail;
}

.dds__drawer-content > .dds__phone::before {
    content: $dds__phone;
}

.dds__drawer-content > .dds__wrench-single::before {
    content: $dds__wrench-single;
}

.dds__drawer-content > .dds__brand-alienware::before {
    content: $dds__brand-alienware;
}
