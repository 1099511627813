.dds__nav {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

.dds__nav-link {
    display: block;
    padding: $nav-link-padding-y $nav-link-padding-x;

    @include hover-focus {
        text-decoration: none;
    }

    &.dds__disabled {
        color: $nav-link-disabled-color;
    }
}

.dds__nav-tabs {
    border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;

    .dds__nav-item {
        margin-bottom: -$nav-tabs-border-width;
    }

    .dds__nav-link {
        @include border-top-radius($nav-tabs-border-radius);
        border: $nav-tabs-border-width solid transparent;

        @include hover-focus {
            border-color: $nav-tabs-link-hover-border-color;
        }

        &.dds__disabled {
            background-color: transparent;
            border-color: transparent;
            color: $nav-link-disabled-color;
        }
    }

    .dds__nav-link.dds__active,
    .dds__nav-item.dds__show .dds__nav-link {
        background-color: $nav-tabs-link-active-bg;
        border-color: $nav-tabs-link-active-border-color;
        color: $nav-tabs-link-active-color;
    }

    .dds__dropdown-menu {
        @include border-top-radius(0);
        margin-top: -$nav-tabs-border-width;
    }
}

.dds__tab-content {
    > .dds__tab-pane {
        display: none;
    }

    > .dds__active {
        display: block;
    }
}

.dds__nav-anchored.dds__active {
    position: fixed;
    position: sticky;
    top: -0.0625rem;
}

$navLeft-item-border1: 0.0625rem solid $dell-ash;
$navLeft-item-border2: 0.0625rem dotted $dell-ash;
$navLeft-item-borderSelected: 0.0625rem solid $black !important;

.navLeft-defaults {
    overflow-x: hidden;
    overflow-y: auto;

    .dds__navLeft-icon-rotate {
        // a temp rotation needed for initially-expanded accordion arrows
        transform: rotate(90deg) !important;
    }

    .dds__chevron-right {
        // ALL ARROWS/CHEVRONS in our nav-left menu(s), including offcanvas
        max-height: 1.5rem;
        transform: rotate(270deg) !important;

        &::before {
            content: $dds__arrow-tri-solid-right;
        }
    }

    .dds__collapsed {
        // ALL ARROWS/CHEVRONS in our nav-left menu(s), including offcanvas
        .dds__chevron-right {
            transform: rotate(90deg) !important;
        }
    }

    .dds__navLeft-button-optionRight {
        padding-left: 0.75rem !important;

        .dds__chevron-right {
            transform-origin: 45% 60%;
            position: relative;
            left: 95%;
            margin-right: -1.2rem;
        }
    }

    .dds__navLeft-button-optionCenter {
        span {
            margin-left: 0 !important;
        }
    }

    .dds__navLeft-link-selected {
        border-top: $navLeft-item-borderSelected;
        border-bottom: $navLeft-item-borderSelected;
        background-color: $dell-cloud;
    }

    .dds__navLeft-link-selectedPrev {
        border-bottom: 0 !important;
    }

    a {
        @extend .navLeft-listItem-defaults;
    }

    ul {
        list-style: none;
        padding-left: 0;

        li {
            border-bottom: $navLeft-item-border1;
        }
    }

    .dds__accordion {
        margin-bottom: 0;

        h5 {
            position: relative;
            z-index: $zindex-fixed;
        }
    }

    .dds__accordion-card {
        border-bottom: 0 !important;

        .dds__accordion-card-header {
            border-top: 0;
        }

        .dds__accordion-card-body {
            // top level accordions
            padding: 0;
            background-color: $white;

            li {
                border-bottom: $navLeft-item-border1;
            }

            li:last-child {
                border-bottom: 0 !important;
            }
        }

        .dds__accordion-card {
            // Accordions that are children of accordions
            border-bottom: 0 !important;

            .dds__accordion-card-body {
                border-left: 0.375rem solid $dell-blue;
                padding-left: 0.8rem;
                border-top: $navLeft-item-border1;

                .dds__accordion-card-body {
                    // grand-children
                    border-left: 0;
                }

                li {
                    border-bottom: $navLeft-item-border2;
                }
            }

            .dds__accordion-btn {
                .dds__chevron-right {
                    color: $dell-moon;
                }
            }

            .dds__accordion-card {
                // great-grandchildren and beyond
                .dds__collapse {
                    border-left: 0;
                }
            }
        }
    }

    .dds__accordion-btn {
        @extend .navLeft-listItem-defaults;
        padding-top: 0.4375rem !important;

        &:hover span {
            text-decoration: none !important;
        }

        span {
            margin-top: 0.25rem;
        }
    }

    .dds__navLeft-suffix {
        position: fixed;
        bottom: 0;
        width: $navLeft-element-width;
    }
}

.navLeft-listItem-defaults {
    text-decoration: none;
    font-size: 0.875rem;
    display: block;
    padding: 0.5313rem 1.25rem;
    text-decoration: none;
    line-height: 1.25rem;

    &:hover {
        background-color: $dell-sky;
        text-decoration: none !important;
    }
}

.dds__msthd-top {
    // cloned - offCanvas version
    .dds__navLeft {
        @extend .navLeft-defaults;

        .dds__navLeft-button-optionRight {
            .dds__chevron-right {
                left: 97%;
            }
        }

        .dds__accordion-card {
            .dds__accordion-card-body {
                background-color: $white;

                .dds__navLeft-link-selected {
                    background-color: $dell-cloud;
                }

                li {
                    background-color: $white;
                }
            }
        }
    }
}

.dds__navLeft-wrapper {
    background-color: $dell-quartz;
    position: fixed;
    display: flex;
    width: $navLeft-element-width;
    left: 0;
    height: 100%;
    border-right: $navLeft-item-border1;
    transition: transform 200ms ease;
    z-index: 1;

    &.dds__active {
        transform: translate(-$navLeft-element-width);
        transition: transform 200ms ease;
    }

    .dds__navLeft {
        @extend .navLeft-defaults;
        width: 100%;
    }

    .dds__navLeft-btn-toggle {
        background-color: $dell-mist;
        border: $navLeft-item-border1;
        border-left: 0.0625rem solid $dell-mist;
        position: absolute;
        top: 0;
        left: $navLeft-element-width;
        width: 0;
        padding: 0.375rem 0.4rem;

        svg {
            width: 0.875rem;
            height: 0.875rem;
            position: relative;
            top: -0.4rem;
            left: -0.45rem;
            fill: $dell-sea-blue;
        }

        &:hover {
            background-color: $dell-ash !important;
            border: 0.0625rem solid $dell-granite !important;
            border-left: 0.0625rem solid $dell-ash !important;
        }
    }
}

$borderOffset: 0.5rem;

.dds__body-ml-20:not(.dds__container-fluid) {
    // not set as a child class for better initial display
    margin-left: $navLeft-element-width + $borderOffset;
    transition: transform 200ms ease;

    &.dds__container {
        width: inherit;
        padding-left: 0;
    }

    &.dds__active {
        margin-left: $borderOffset;
        transition: transform 200ms ease;

        &.dds__container {
            margin-left: auto;
            padding-left: 0.9375rem;
        }
    }
}

.dds__body-ml-20.dds__container-fluid {
    border-left: $navLeft-element-width + $borderOffset solid transparent;

    &.dds__active {
        border-left: $borderOffset;
        transition: transform 200ms ease;
    }
}

.dds__nav-anchored-vertical {
    flex: 0 0 auto;
    height: auto;
    max-height: 110vh;
    max-width: 255px;
    position: relative;
    position: sticky;
    top: -0.0625rem;
    width: 100%;
    border-right: 1px solid #ccc;
    margin-right: 0.9375rem;

    &-content {
        .modal {
            &-on {
                display: none !important;
            }

            &-off {
                display: block;
            }
        }
    }

    &-container {
        display: flex;
    }

    &-list {
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    &-item {
        list-style-type: none;
        margin-top: -1px;
    }

    &-link {
        @include mixin_dds__tab-link;
        background-image: -webkit-linear-gradient(270deg, $dell-white, $dell-quartz);
        background-image: linear-gradient(180deg, $dell-white, $dell-quartz);
        border: $nav-tabs-border-width solid $dell-granite;
        color: $dell-sea-blue;
        cursor: pointer;
        text-align: left;
        width: calc(100% + 1px);

        &.dds__active {
            color: $dell-black;
            background-color: $dell-white;
            background-image: none;
            border-right: $dell-white solid 0.0625rem;
            border-left: $dell-blue solid 0.3125rem;
            padding-bottom: 0.5rem;
            padding-left: calc(1rem - 0.3125rem);
            cursor: default;
        }

        &:hover {
            background: $dell-sky;
            color: $dell-midnight;
            text-decoration: none;
        }
    }
}

@media screen and (min-width: 991.98px) and (max-width: 1199.98px) {
    // size at which left-nav interferes with display of banners
    .dds__body-ml-20:not(.dds__active) {
        @include compressed-state;
    }
}

@media screen and (max-width: 991.98px) {
    .dds__navLeft-wrapper {
        display: none;
    }

    .dds__body-ml-20:not(.dds__container-fluid) {
        margin-left: 0;

        &.dds__container {
            margin-left: auto;
            padding-left: 0.9375rem;
        }

        &.dds__active {
            margin-left: 0;
        }
    }

    .dds__body-ml-20.dds__container-fluid {
        border-left: 0;

        &.dds__active {
            border-left: 0;
        }
    }
}

@media screen and (max-width: 767.98px) {
    // make this important because anchor navs can be ul or nav and need to inherit stylings from tabs
    .dds__nav-anchored,
    .dds__nav-anchored-vertical {
        display: block !important;
        top: 0;
    }

    .dds__nav-anchored {
        display: block !important;
        top: 0;
    }

    .dds__nav-content {
        div {
            display: none;
        }
    }

    .dds__nav-anchored-vertical {
        border-right: none;
        max-width: 100%;
        position: relative;

        .dds__icons.dds__tab-icon {
            display: block !important; // to avoid rewrite from .dds__nav-tabs
        }

        &-container {
            flex-direction: column;
        }

        &-list:first-child {
            border-top: $dell-granite solid $hr-border-width;
        }

        &-item:first-child {
            border-top: $nav-tabs-border-width solid $dell-granite;
        }

        &-link {
            align-items: center;
            background-color: $dell-white;
            background-image: none;
            border-bottom: $dell-granite solid $hr-border-width;
            border-left: none;
            border-right: none;
            border-top: none;
            display: flex;
            justify-content: space-between;
            margin: 0;
            margin-bottom: $hr-border-width;
            padding: 1rem !important;
            text-align: left;

            img {
                margin: 0 1rem 0 0;
            }

            &.dds__active {
                @include mixin_dds__tabs-hover-focus;
                color: $dell-sea-blue;
                background-image: none;
                border-bottom: $dell-granite solid $hr-border-width;
                border-left: none;
                border-top: none;
                border-right: none;
                margin: 0;
                margin-bottom: $hr-border-width;
                text-align: left;

                .dds__tab-span_blue {
                    color: $dell-sea-blue;
                }
            }
        }
    }
}
