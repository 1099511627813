@each $color, $value in $theme-colors {
    .dds__badge-#{$color} {
        @include badge-variant($value);
    }
}

.dds__badge {
    border-radius: 0.5625rem;
    display: inline-block;
    font-family: Roboto;
    font-size: $font-size-sm;

    font-weight: bold;
    font-weight: $badge-font-weight;
    line-height: 1;
    padding: $badge-padding-y $badge-padding-x;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;

    &:empty {
        display: none;
    }

    &.dds__badge-dark {
        background-color: $dell-carbon;
        color: $dell-white;
    }

    &.dds__badge-red {
        background-color: $dell-red;
        color: $dell-white;
    }
}

.dds__mail-icon-ctnr {
    background-color: $dell-blue;
    color: $dell-white;
    display: inline-block;
    padding: 0.625rem;

    &:hover {
        color: $dell-white;
        cursor: pointer;
        text-decoration: none;

        > span {
            text-decoration: underline;
        }
    }

    &:focus {
        outline: $custom-focus-outline;
        outline-offset: $custom-focus-offset;
    }

    i.dds__icons {
        font-size: 1.75rem;
        position: relative;

        .dds__badge {
            left: 1.125rem;
            position: absolute;
            top: -0.125rem;
        }
    }
}

.dds__calendar-icon-ctnr {
    background-color: $dell-white;
    color: $dell-sea-blue;
    display: inline-block;
    padding: 0.625rem;

    &:hover {
        color: $dell-sea-blue;
        cursor: pointer;
        text-decoration: none;

        > span {
            text-decoration: underline;
        }
    }

    &:focus {
        outline: $custom-focus-outline;
        outline-offset: $custom-focus-offset;
    }

    i.dds__icons {
        font-size: 1.75rem;
        position: relative;

        .dds__badge {
            left: 1.125rem;
            position: absolute;
            top: 0.125rem;
        }
    }
}

// Badge Labels:
.dds__label-badge {
    // TODO: We need a story to convert containers and these into using Sass function, using a mixin...

    border-radius: $badge-pill-border-radius;
    display: inline-block;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    letter-spacing: 0.05rem;
    padding: 0.125rem 0.5rem;
    white-space: nowrap;

    // STROKE Background
    &.dds__label-badge-stroke-berry {
        background-color: #f8eaef;
        border: 0.0625rem solid $dell-berry;
        color: $dell-berry;
    }

    &.dds__label-badge-stroke-wave {
        background-color: #edf7f7;
        border: 0.0625rem solid $dell-wave;
        color: $dell-wave;
    }

    &.dds__label-badge-stroke-sea-blue {
        background-color: #e5f0f8;
        border: 0.0625rem solid $dell-sea-blue;
        color: $dell-sea-blue;
    }

    &.dds__label-badge-stroke-midnight {
        background-color: #e5ecf2;
        border: 0.0625rem solid $dell-midnight;
        color: $dell-midnight;
    }

    &.dds__label-badge-stroke-purple {
        background-color: #f0d9f3;
        border: 0.0625rem solid $dell-purple;
        color: $dell-purple;
    }

    &.dds__label-badge-stroke-white {
        background-color: $dell-white;
        border: 0.0625rem solid $dell-black;
        color: $dell-black;
    }

    &.dds__label-badge-stroke-quartz {
        background-color: $dell-quartz;
        border: 0.0625rem solid $dell-black;
        color: $dell-black;
    }
    // Status Only Labels
    // Custom HEX values are necessary for achieving 4.5:1 contrast ratio
    // They are variations of Dell Primary Purchase Green, Dell Yellow/Orange/Red
    &.dds__label-badge-stroke-green {
        background-color: #edf3e6;
        border: 0.0625rem solid #467b00;
        color: #467b00;
    }

    &.dds__label-badge-stroke-yellow {
        background-color: #fef7e6;
        border: 0.0625rem solid #946b00;
        color: #946b00;
    }

    &.dds__label-badge-stroke-yellow-warning {
        background-color: #fff4d8;
        border: 0.0625rem solid $dell-yellow;
        color: $dell-black;
    }

    &.dds__label-badge-stroke-orange {
        background-color: #fef0e8;
        border: 0.0625rem solid #ba4e0d;
        color: #ba4e0d;
    }

    &.dds__label-badge-stroke-red {
        background-color: #fae7e9;
        border: 0.0625rem solid $dell-red;
        color: $dell-red;
    }

    // SOLID Background
    &.dds__label-badge-solid-berry {
        background-color: $dell-berry;
        border: 0.0625rem solid $dell-berry;
        color: $dell-white;
    }

    &.dds__label-badge-solid-sea-blue {
        background-color: $dell-sea-blue;
        border: 0.0625rem solid $dell-sea-blue;
        color: $dell-white;
    }

    &.dds__label-badge-solid-midnight {
        background-color: $dell-midnight;
        border: 0.0625rem solid $dell-midnight;
        color: $dell-white;
    }

    &.dds__label-badge-solid-purple {
        background-color: $dell-purple;
        border: 0.0625rem solid $dell-purple;
        color: $dell-white;
    }

    &.dds__label-badge-solid-carbon {
        background-color: $dell-carbon;
        border: 0.0625rem solid $dell-carbon;
        color: $dell-white;
    }

    &.dds__label-badge-solid-gray {
        background-color: $dell-gray;
        border: 0.0625rem solid $dell-gray;
        color: $dell-black;
    }

    &.dds__label-badge-solid-light-blue {
        background-color: $dell-light-blue;
        border: 0.0625rem solid $dell-light-blue;
        color: $dell-black;
    }

    &.dds__label-badge-solid-granite {
        background-color: $dell-granite;
        border: 0.0625rem solid $dell-granite;
        color: $dell-black;
    }
    // Status Only
    &.dds__label-badge-solid-status-green {
        background-color: $dell-primary-purchase-green;
        border: 0.0625rem solid $dell-primary-purchase-green;
        color: $dell-white;
    }

    &.dds__label-badge-solid-status-yellow {
        background-color: $dell-yellow;
        border: 0.0625rem solid $dell-yellow;
        color: $dell-black;
    }

    &.dds__label-badge-solid-status-orange {
        background-color: $dell-orange;
        border: 0.0625rem solid $dell-orange;
        color: $dell-black;
    }

    &.dds__label-badge-solid-status-red {
        background-color: $dell-red;
        border: 0.0625rem solid $dell-red;
        color: $dell-white;
    }
}
