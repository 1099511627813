.dds__card {
    @include border-radius($card-border-radius);
    @include mixin_dds__card;
    background-clip: border-box;
    background-color: $card-bg;
    border: $card-border-width solid $card-border-color;
    display: flex;
    flex-direction: column;
    min-width: 0;
    overflow: hidden;
    position: relative;
    word-wrap: break-word;

    > hr {
        margin-left: 0;
        margin-right: 0;
    }

    > .dds__list-group:first-child {
        .dds__list-group-item:first-child {
            @include border-top-radius($card-border-radius);
        }
    }

    > .dds__list-group:last-child {
        .dds__list-group-item:last-child {
            @include border-bottom-radius($card-border-radius);
        }
    }
}

.dds__card-body {
    flex: 1 1 auto;
    padding: $card-spacer-x;
}

.dds__card-title {
    margin-bottom: $card-spacer-y;
}

.dds__card-text:last-child {
    margin-bottom: 0;
}

.dds__card-link {
    @include hover {
        text-decoration: none;
    }

    + .dds__card-link {
        margin-left: $card-spacer-x;
    }
}

.dds__card-img {
    @include border-radius($card-inner-border-radius);
    width: 100%;
}

.dds__card-img-top {
    @include border-top-radius($card-inner-border-radius);
    @include mixin_dds__card-imag-top;
    display: block;
    width: 100%;
}

.dds__card-horizontal-bleed {
    @include mixin_dds__card;
}

.dds__hero-banner,
.dds__link-picker,
.dds__card-horizontal-bleed,
.dds__consumer-product-card {
    .dds__card-body {
        background-color: rgba(255, 255, 255, 1);
        bottom: 0;
        padding: 0.9375rem;
        position: absolute;
        width: 100%;
    }
}

a.dds__card-link {
    + .dds__card-link {
        margin-left: 0;
    }

    &:hover {
        text-decoration: underline;
    }
}

.dds__consumer-product-card .dds__card-body {
    background-color: rgba(255, 255, 255, 0.9);
    bottom: auto;
    margin: 1.25rem;
    padding: 1.25rem;
    right: 0;
    top: 0;
    width: 55%;
}

//Show Hides
.dds__accordion {
    .dds__card:not(:first-of-type):not(:last-of-type) {
        border-bottom: 0;
        border-radius: 0;
    }

    .dds__card:not(:first-of-type) {
        .dds__card-header:first-child {
            border-radius: 0;
        }
    }

    .dds__card:first-of-type {
        border-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .dds__card:last-of-type {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

.dds__show-hide-container {
    display: flex;
    flex-direction: row;
    padding: 0 0.5rem;
}

.dds__accordion-card {
    border: none;

    &:last-of-type {
        border-bottom: 0.0625rem solid $dell-granite;
    }
}

.dds__accordion-card-header {
    background: none;
    border-bottom: none !important;
    border-top: 0.0625rem solid $dell-granite;
    flex: inherit;
    padding: 0;
}

.dds__accordion-card-body {
    padding: 0.9375rem;
    padding-left: 3rem;
    padding-top: 0;
    position: relative !important;
}

.dds__accordion-btn {
    @include mixin_dds__accordion-btn;
    font-size: 1rem;
    margin: 0;
    padding: 0.5rem 0.75rem;
    width: 100%;

    span {
        text-align: left;
        white-space: normal;
    }

    &:not(:disabled):not(.dds__disabled):active {
        box-shadow: none !important;
        outline: none;
    }

    &:hover {
        text-decoration: none !important;

        .dds__chevron-right {
            color: $dell-midnight;
        }

        span {
            text-decoration: underline;
        }
    }

    &:focus {
        outline: $custom-focus-outline;
        outline-offset: -0.0625rem;
        text-decoration: none;

        &:hover {
            span {
                text-decoration: underline;
            }
        }
    }
}

.dds__accordion-btn.dds__collapsed {
    padding: 0.5rem 0.75rem;

    .dds__chevron-right {
        transform: rotate(0deg);
    }
}

.dds__accordion-btn .dds__chevron-right {
    color: $dell-sea-blue;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    line-height: 1.75rem;
    padding-right: 0.1875rem;
    padding-top: 0;
    transform: rotate(90deg);
    transition: transform 200ms ease 0s;
    vertical-align: middle;
}

.dds__secondary-accordion-btn {
    @include mixin_dds__accordion-btn;
    padding: 0;
    width: 100%;

    span {
        text-align: left;
        white-space: normal;
    }

    &:not(:disabled):not(.dds__disabled):active {
        box-shadow: none !important;
        outline: none;
    }

    &:hover {
        text-decoration: none !important;

        svg {
            fill: $dell-midnight;
        }

        .dds__arrow-tri-solid-right {
            color: $dell-midnight;
        }

        span {
            text-decoration: underline;
        }
    }

    &:focus {
        text-decoration: none;
    }

    span:hover {
        text-decoration: underline;
    }
}

.dds__secondary-accordion-body {
    padding: 0;
}

.dds__secondary-accordion .dds__collapsed .dds__arrow-tri-solid-right {
    transform: rotate(0deg);
}

.dds__secondary-accordion-btn > .dds__arrow-tri-solid-right {
    color: $dell-sea-blue;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    line-height: 1.5rem;
    padding-right: 0.1875rem;
    padding-top: 0;
    text-decoration: none !important;
    transform: rotate(90deg);
    transition: transform 200ms ease 0s;
}

.dds__secondary-accordion-btn > svg {
    fill: $dell-sea-blue;
    height: 1rem;
}
//show more show less text change logic
[aria-expanded="false"] > .dds__less,
[aria-expanded="true"] > .dds__more {
    display: none;
}

.dds__show-hide {
    nav {
        a {
            &:focus {
                outline: $custom-focus-outline;
                outline-offset: -0.0125rem !important;
            }
        }
    }

    ul {
        list-style: none;
        padding-left: 0.625rem;

        &.dds__inner {
            padding-left: 0;
        }
    }
}

// Value Prop SCSS
.dds__value-prop .dds__btn-link {
    background-color: $dell-quartz;
    border-color: $dell-ash;
    border-style: solid;
    border-width: 0.0625rem 0.0625rem 0.0625rem 0;
    display: flex;
    flex-direction: column;
    min-height: 8.125rem;
    text-align: center;

    &:first-child {
        border-left: 0.0625rem solid $dell-ash;
    }

    .dds__value-prop-text {
        padding-bottom: 1.5rem;

        p {
            color: $dell-black;
        }
    }

    i {
        color: $dell-blue;
        font-size: 3rem;
        margin: 1.25rem;
    }

    &:focus {
        text-decoration: none;
    }

    &:hover {
        background-color: $dell-white;
        text-decoration: none;

        h5 {
            text-decoration: underline;
        }
    }
}

.dds__col-xl-3 {
    .dds__card-horizontal-bleed,
    .dds__link-picker,
    .dds__hero-banner {
        .dds__card-body {
            background-color: $dell-mist;
            bottom: 0;
            display: inline-block;
            margin: 0;
            padding: 0.9375rem;
            position: relative;
            top: auto;
            width: 100%;
        }
    }

    .dds__consumer-product-card .dds__card-body {
        background-color: $dell-mist;
        border-radius: 0;
        bottom: 0;
        display: inline-block;
        margin: 0;
        padding: 0.9375rem;
        position: relative;
        top: auto;
        width: 100%;
    }
}

.dds__card-wrapper-stroked {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 68.75rem; // 1100px;

    .dds__card-stroked {
        align-items: center;
        border: 0.0625rem solid $dell-ash;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 0.75rem;
        margin-right: 0.75rem;
        min-width: 10rem;
        position: relative; //needed forFpa checkbox
        width: 12.75rem;

        &:not(.dds__active):hover {
            background-color: $dell-quartz;
            border-color: $dell-blue;
            border-style: solid;
        }

        .dds__card-label-stroked {
            border: none;
            box-sizing: content-box;
            height: 100%;
            margin: 0;
            padding: 0 0.5625rem; // 11px 9px 1px;
            width: 100%;

            .dds__card-label-container {
                align-items: center;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: space-between;
                padding: 0.625rem 0.5rem 0.0625rem;
                text-align: center;
                width: 100%;

                .dds__card-title-stroked,
                .dds__card-txt-stroked-secondary,
                .dds__card-txt-stroked-supporting {
                    flex-wrap: wrap;
                    max-width: 100%;
                }

                .dds__icon-stroked-container {
                    display: flex;
                    justify-content: center;

                    .dds__icons {
                        font-size: 24px;
                        line-height: 24px;
                        margin-right: 8px;
                    }
                }
            }

            .dds__checkbox {
                cursor: pointer;
            }
        }

        .dds__card-txt-stroked-secondary {
            color: $dell-berry;
            font-weight: bold;
        }

        .dds__card-img-stroked {
            margin-bottom: 0.625rem; // 10px;
            max-height: 6.25rem;
            max-width: 100%;
        }

        //DISABLED RADIO BUTTONS + LINKS
        &.dds__disabled {
            background-color: $dell-mist;

            &:hover {
                border: 0.0625rem solid $dell-ash;
                background-color: $dell-mist;
            }

            .dds__card-label-container {
                cursor: not-allowed;
                color: $dell-moon;

                img {
                    opacity: 0.5;
                }

                .dds__card-txt-stroked-secondary,
                .dds__card-txt-stroked-supporting {
                    color: $dell-moon;
                }
            }
        }
    }
}

fieldset:disabled,
fieldset.dds__disabled {
    .dds__card-wrapper-stroked {
        .dds__card-stroked,
        .dds__card-stroked.dds__active,
        .dds__card-stroked:hover {
            background-color: $dell-mist;
            border-color: $dell-ash;

            &:hover {
                border-color: $dell-ash;
                background-color: $dell-mist;
            }

            .dds__card-label-container {
                cursor: not-allowed;
                color: $dell-moon;

                img {
                    opacity: 0.5;
                }

                .dds__card-txt-stroked-secondary,
                .dds__card-txt-stroked-supporting {
                    color: $dell-moon;
                }
            }
        }
    }
}

@media screen and (max-width: 1199.98px) {
    .dds__card-wrapper-stroked {
        max-width: 56.25rem; // 900px;
    }
}

@media screen and (max-width: 991.98px) {
    .dds__hero-banner,
    .dds__link-picker,
    .dds__card-horizontal-bleed,
    .dds__consumer-product-card {
        .dds__card-body {
            background-color: $dell-mist;
            bottom: 0;
            display: inline-block;
            margin: 0;
            padding: 0.9375rem;
            position: relative;
            top: auto;
            width: 100%;
        }
    }

    .dds__consumer-product-card {
        border: 0.0625rem solid $card-border-color;
    }

    .dds__card-wrapper-stroked {
        max-width: 50rem; // 800px;
        .dds__card-stroked {
            width: 10rem;

            .dds__card-img-stroked {
                max-height: 6.25rem;
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .dds__card-wrapper-stroked {
        flex-direction: column;
        max-width: 56.25rem; // 900px
        .dds__card-stroked {
            display: flex;
            width: 100%;

            .dds__card-label-stroked {
                .dds__card-label-container {
                    align-items: flex-start;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding: 0.6875rem 0.5625rem;

                    .dds__card-img-stroked {
                        display: flex;
                        margin-right: 0.625rem;
                        max-height: 4.5rem;
                        order: 1;
                    }

                    .dds__card-title-stroked {
                        display: flex;
                        flex: 1;
                        flex-grow: 2;
                        margin: auto 0;
                        margin-right: 0.625rem;
                        min-width: 40%;
                        order: 2;
                        text-align: left;
                    }

                    .dds__card-txt-stroked-secondary {
                        display: flex;
                        flex-basis: 100%;
                        justify-content: center;
                        margin: auto;
                        margin-top: 0.625rem;
                        order: 4;
                    }

                    .dds__card-txt-stroked-supporting {
                        display: flex;
                        margin: auto 0;
                        // margin-bottom: 0.625rem;
                        order: 3;
                    }

                    .dds__icon-stroked-container {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }

    .dds__accordion-card-body {
        padding-left: 1.4375rem;
    }

    .dds__value-prop .dds__btn-link {
        border-color: $dell-ash;
        border-style: solid;
        border-width: 0 0.0625rem 0.0625rem;
        display: flex;
        flex-direction: row;
        min-height: 0;
        text-align: left;

        &:first-child {
            border-top: 0.0625rem solid $dell-ash;
        }

        .dds__value-prop-text {
            align-self: center;
            display: flex;
            flex-direction: column;
            padding: 0.75rem 0 0;
            width: 100%;
        }

        i {
            align-self: center;
            flex-direction: column;
            margin-left: 0.625rem;
        }
    }
}
