.dds__container-overflow {
    position: relative;
    touch-action: pan-y;
    width: 100%;

    .dds__overflow-transition {
        transition: all 200ms ease;
    }
}

.dds__overflow-control {
    align-items: center;
    display: none;
    justify-content: center;
    margin: auto;
    opacity: 0;
    position: absolute;
    width: 2.1875rem;

    .dds__icon-svg {
        display: flex;
        fill: $dell-sea-blue;
        fill-opacity: 1;
        margin: -0.45rem;
        pointer-events: none;
    }

    &.dds__overflow-control-btn {
        background: rgba(255, 255, 255, 0.75);
        border: 0.0625rem solid $dell-granite;
        margin-top: 0.0625rem;
        top: 0;
        z-index: $zindex-overflow-control;

        .dds__icon-svg {
            height: 1.5rem;
            width: 1.5rem;
        }

        &:hover {
            background-color: $dell-ash;
            border: 0.0625rem solid $dell-blue;
            cursor: pointer;

            .dds__icon-svg {
                fill: $dell-midnight;
            }
        }
    }

    &.dds__overflow-control-svg {
        background: $dell-white;
        border: none;
        padding: 0.3125rem;
        z-index: 1;

        .dds__icon-svg {
            height: 2.75rem;
            pointer-events: none;
            width: 2.75rem;
        }

        &:hover {
            cursor: pointer;

            .dds__icon-svg {
                fill: $dell-midnight;
            }
        }
    }

    &[active],
    &.dds__active {
        display: flex;
        opacity: 1;
    }
}

@media screen and (max-width: 767.98px) {
    .dds__overflow-control.dds__overflow-unresponsive {
        display: none;

        &[active],
        &.dds__active {
            display: none;
        }
    }
}
