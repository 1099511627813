.dds__alert {
    @include border-radius($alert-border-radius);
    background: $dell-alert-yellow;
    border: $alert-border-width solid $dell-quartz;
    margin-bottom: 0;
    padding: $alert-padding-y $alert-padding-x;
    position: relative;

    .dds__error-default-body {
        display: flex;

        .dds__alert-body {
            margin: 0;
        }
    }

    i {
        font-size: 1.25rem; //base font-size + 6px
    }

    button.dds__close-x {
        background-color: transparent;
        border: none;
        padding: 0.625rem;
        position: absolute;
        right: 0;
        top: 0;

        &:hover {
            cursor: pointer;
        }

        &:focus {
            outline: $custom-focus-outline;
        }
    }

    .dds__alert-heading {
        color: inherit;
        display: flex;
        flex-direction: row;
        padding-right: 0.9375rem;

        h4 {
            margin-bottom: 0;
        }
    }

    .dds__alert-error {
        color: $dell-red;
        padding-right: 0.625rem;
        vertical-align: middle;
    }

    .dds__alert-notice {
        color: $dell-orange;
        padding-right: 0.625rem;
        vertical-align: middle;
    }

    .dds__alert-info-cir {
        color: $dell-blue;
        padding-right: 0.625rem;
        vertical-align: middle;
    }

    .dds__task-check {
        color: $dell-green;
        padding-right: 0.625rem;
        vertical-align: middle;
    }

    .dds__alert-body {
        margin-bottom: 0;
        margin-left: 2.125rem;
        padding-right: 0.9375rem;
    }

    ul {
        margin-left: 2.125rem;
        margin-bottom: 0;
        margin-top: 0.625rem;
        padding-inline-start: 0;
        padding-left: 1rem;

        li {
            margin-bottom: 0.3125rem;
        }
    }

    .dds__alert-default-body {
        width: 100%;

        .dds__alert-body {
            margin-left: 0;
        }

        ul {
            margin-left: 0;
        }
    }

    &.dds__catastrophic-error {
        background-color: $dell-alert-red;
        border: 1px solid $dell-red-stroke;
        border-left: none;
        border-right: none;
        border-top: none;
    }

    &.dds__informational-alert,
    &.dds__success-alert {
        background-color: $dell-mist;
        border: 1px solid $dell-quartz;
        border-left: none;
        border-right: none;
        border-top: none;
    }
}

[aria-expanded="false"] > .dds__less,
[aria-expanded="true"] > .dds__more {
    display: none;
}

.dds__dismiss {
    background-color: transparent;
    border: none;
    color: $dell-blue;

    &:hover {
        color: $dell-midnight;
        cursor: pointer;
        text-decoration: underline;
    }

    &:focus {
        outline: $custom-focus-outline;
        outline-offset: $custom-focus-offset;
    }
}

.dds__announcement {
    background-color: $dell-mist;
    border-left: 0.625rem solid $dell-blue;
    padding: 0.625rem 1rem;

    .dds__announcement-title {
        margin: 0.3125rem 0;
    }

    .dds__list-inline {
        margin-top: 0.625rem;
    }
}

.dds__announcement-berry {
    border-color: $dell-berry;
}

.dds__announcement-green {
    border-color: $dell-green;
}

.dds__announcement-midnight {
    border-color: $dell-midnight;
}

.dds__announcement-purple {
    border-color: $dell-purple;
}

.dds__announcement-with-image {
    border-left: none;
    display: flex;

    .dds__announcement-image {
        color: $dell-blue;
        height: 2.5rem;
        margin-right: 0.625rem;
        width: 2.5rem;
    }
}
