@if $enable-print-styles {
    @media print {
        *,
        *::before,
        *::after {
            box-shadow: none !important;
            text-shadow: none !important;
        }

        a {
            &:not(.dds__btn) {
                text-decoration: underline;
            }
        }

        abbr[title]::after {
            content: " (" attr(title) ")";
        }

        pre {
            white-space: pre-wrap !important;
        }

        pre,
        blockquote {
            border: $border-width solid $gray-500;
            page-break-inside: avoid;
        }

        thead {
            display: table-header-group;
        }

        tr,
        img {
            page-break-inside: avoid;
        }

        p,
        h2,
        h3 {
            orphans: 3;
            widows: 3;
        }

        h2,
        h3 {
            page-break-after: avoid;
        }
        @page {
            size: $print-page-size;
        }

        body {
            min-width: $print-body-min-width !important;
        }

        .dds__container {
            min-width: $print-body-min-width !important;
        }

        .dds__navbar {
            display: none;
        }

        .dds__badge {
            border: $border-width solid $black;
        }

        .dds__table {
            border-collapse: collapse !important;

            td,
            th {
                background-color: $white !important;
            }
        }

        .dds__table-bordered {
            th,
            td {
                border: 1px solid $gray-300 !important;
            }
        }

        .dds__table-dark {
            color: inherit;

            th,
            td,
            thead th,
            tbody + tbody {
                border-color: $table-border-color;
            }
        }

        .dds__table .dds__thead-dark th {
            border-color: $table-border-color;
            color: inherit;
        }
    }
}
