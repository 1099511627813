.dds__tooltip {
    @include reset-text();
    display: block;
    font-size: $tooltip-font-size;
    margin: $tooltip-margin;
    opacity: 0;
    position: absolute;
    word-wrap: break-word;
    z-index: -1;

    &.dds__show {
        opacity: $tooltip-opacity;
        z-index: $zindex-tooltip;
    }

    .dds__arrow {
        background-color: $dell-white;
        border-bottom: 0.0625rem solid $dell-granite;
        border-left: 0.0625rem solid $dell-granite;
        display: block;
        height: 0.75rem;
        position: absolute;
        width: 0.75rem;

        &::before {
            border-color: transparent;
            border-style: solid;
            content: "";
            position: absolute;
        }
    }
}

.dds__bs-tooltip-top {
    padding: $tooltip-arrow-height 0;

    .dds__arrow {
        bottom: 0;
        transform: rotate(-45deg);

        &::before {
            border-top-color: $tooltip-arrow-color;
            border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
            top: 0;
        }
    }
}

.dds__bs-tooltip-right {
    padding: 0 $tooltip-arrow-height;

    .dds__arrow {
        left: 0.0625rem;
        transform: rotate(45deg);

        &::before {
            border-right-color: $tooltip-arrow-color;
            border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
            right: 0;
        }
    }
}

.dds__bs-tooltip-bottom {
    padding: $tooltip-arrow-height 0;

    .dds__arrow {
        top: 0;
        transform: rotate(135deg);

        &::before {
            border-bottom-color: $tooltip-arrow-color;
            border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
            bottom: 0;
        }
    }
}

.dds__bs-tooltip-left {
    padding: 0 $tooltip-arrow-height;

    .dds__arrow {
        margin-top: -0.15625rem;
        right: 0.0625rem;
        transform: rotate(-135deg);

        &::before {
            border-left-color: $tooltip-arrow-color;
            border-width: ($tooltip-arrow-width / 2) 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
            left: 0;
        }
    }
}

.dds__bs-tooltip-auto {
    &[x-placement^="top"] {
        @extend .dds__bs-tooltip-top;
    }

    &[x-placement^="right"] {
        @extend .dds__bs-tooltip-right;
    }

    &[x-placement^="bottom"] {
        @extend .dds__bs-tooltip-bottom;
    }

    &[x-placement^="left"] {
        @extend .dds__bs-tooltip-left;
    }
}

.dds__tooltip-inner {
    @include border-radius($tooltip-border-radius);
    background-color: $tooltip-bg;
    border: 0.0625rem solid $dell-granite;
    box-shadow: 0.125rem 0.125rem 1.3125rem -0.3125rem rgba(0, 0, 0, 0.2);
    color: $tooltip-color;
    padding: 0.875rem;
    text-align: center;
}

.dds__tooltip-icon {
    background: transparent;
    border: none;
    color: $dell-blue;
    font-size: 1rem;
    padding: 0.5rem;

    &[x-placement^="top"] {
        padding-top: 1rem;
    }

    &[x-placement^="right"] {
        padding-right: 1rem;
    }

    &[x-placement^="bottom"] {
        padding-bottom: 1rem;
    }

    &[x-placement^="left"] {
        padding-left: 1rem;
    }

    &:focus {
        outline: $custom-focus-outline;
        outline-offset: $custom-focus-offset;
    }
}
