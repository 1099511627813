/////////////////////START MASTHEAD MOBILE MENU
.dds__msthd-offcanvas-menu {
    display: none;
}
/////////////////////END MASTHEAD MOBILE MENU

/////////////////////START BASE MASTHEAD
.dds__msthd {
    background-color: $dell-blue;
    border-bottom: none;
    border-radius: 0;
    color: $dell-white;
    margin-bottom: 0;
    padding: 0;
    width: 100%;

    // Added with Pure CSS
    .dds__svg-icons {
        @include mixin_dds__svg-icons;
        flex-shrink: 0;
        height: 1rem;
        width: 1rem;
    }
}

.dds__msthd-overlay.dds__show {
    background-color: $modal-backdrop-bg;
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: $modal-backdrop-opacity !important;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: $zindex-sticky;
    &.dds__fade {
        opacity: 0;
    }
}

.dds__msthd {
    *:focus {
        outline: $custom-focus-outline;
        outline-offset: -0.125rem !important;
    }
}
/////////////////////END BASE MASTHEAD

/////////////////////START SHARED NAVBAR
.dds__msthd > .dds__navbar {
    background-color: $dell-blue;
    border-bottom: none;
    border-radius: 0;
    color: $dell-white;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;
    padding: 0;
    width: 100%;
}

.dds__msthd > .dds__navbar > .dds__container {
    flex-direction: row;
    flex-wrap: nowrap;
    min-height: 3.75rem;
}
/////////////////////END SHARED NAVBAR

/////////////////////START NAVBAR TOP
.dds__msthd-navbar-top {
    flex-direction: column; // JUST ADDED
    height: 4.125rem;
    z-index: $zindex-fixed;
}

// Top level container class for dds__container
.dds__msthd-top {
    align-items: center;
    border: 0;
    display: flex;
    flex: 1 1 0%;
    justify-content: center;
    margin: 0;

    .dds__row {
        align-items: center;
        flex-wrap: nowrap;
        margin: 0;
        width: 100%;
    }
}
/////////////////////END NAVBAR TOP

/////////////////////START SHADOW
.dds__navbar.dds__msthd-shadow {
    border-top: 0.0625rem solid #0087c7;
    box-shadow: inset 0 0.3125rem 1.125rem -0.3125rem rgba(0, 0, 0, 0.5);
}

.dds__msthd-shadow-gradient {
    background-image: linear-gradient(
        90deg,
        rgba($dell-blue, 1),
        rgba($dell-blue, 0.5) 10%,
        rgba($dell-blue, 0),
        rgba($dell-blue, 0.5) 90%,
        rgba($dell-blue, 1)
    );
    display: flex;
    flex: 1 0 0%;
    padding-top: 0.0625rem;
}
/////////////////////END SHADOW

/////////////////////START DELL ICON
.dds__msthd-dell-icon {
    color: $dell-white; // Used as an anchor needs color
    font-size: 2.5rem;
    padding: 0.8125rem 0.625rem;

    &:hover {
        color: $dell-white; // Used as an anchor needs color
        text-decoration: none;
    }

    &::before {
        content: "\e934";
    }
}
/////////////////////END DELL ICON

/////////////////////START DELL BRAND
.dds__msthd-brand-ctnr {
    max-height: 4.125rem;
    padding: 0.8125rem 0;
    white-space: nowrap;
}
/////////////////////END DELL BRAND

/////////////////////START SEARCH
.dds__msthd-search {
    @include mixin_dds__msthd-dropdown;
    display: flex;
    //margin-top: 0.125rem;
    margin-right: 1.875rem;
    padding: 0 0.625rem;
    width: 100%;
}

.dds__msthd-search-input {
    @include mixin_dds__msthd-search-input;
    background-color: $dell-white;
    border: 0;
    height: auto;
    margin-left: auto;
    text-align: left;
    width: 100%;
}

.dds__msthd-search-btn {
    @include mixin_dds__msthd-search-btn;
    border-left: 0;
    line-height: 1.25rem;
    margin: 0.125rem 0.125rem 0.125rem 0;
    max-width: 10.125rem;
    padding: 0.375rem 0.75rem;

    i {
        padding-right: 0.375rem;
    }
}

.dds__msthd-search-ctnr {
    background-color: $dell-white;
    display: flex;
    height: 2.375rem;
    width: 100%;
    z-index: $zindex-fixed;

    > form {
        box-sizing: unset;
        display: flex;
    }
}
/////////////////////END SEARCH

// SHARED
.dds__msthd-hidden {
    display: none;
}

// TOP NAV

// TOP NAV ELEMENTS
.dds__msthd-top-ctnr {
    // min-width: 5.5rem;
    padding: 0;
    position: relative;

    &:active .dds__btn-dropdown,
    &.dds__show .dds__msthd-icon-ctnr {
        background: $dell-white;
        color: $dell-sea-blue;

        svg {
            fill: $dell-sea-blue;
        }
    }
}

.dds__msthd-top-right-ctnr {
    flex-grow: 1;
    justify-content: flex-end;
}

.dds__msthd-icon-ctnr {
    align-items: center;
    background: transparent;
    border-style: none;
    color: $dell-white;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    height: 4.125rem;
    justify-content: center;
    padding: 0 0.625rem;
    position: relative;
    width: 100%;

    .dds__msthd-label {
        display: inherit;
        // justify-content: space-between;
        flex-wrap: nowrap;
        margin-left: 0.3125rem;
    }

    &.dds__active {
        background: $dell-white;
        color: $dell-sea-blue !important;

        svg {
            fill: $dell-sea-blue;
        }
    }

    &:hover {
        color: $dell-white;
        text-decoration: none;
    }

    svg {
        fill: $dell-white;
        height: 1.5rem;
        pointer-events: none;
        width: 1.5rem;
    }

    &.dds__msthd-logo {
        padding: 0;
    }

    .dds__badge {
        font-size: 0.625rem;
        left: 1.3125rem;
        position: absolute;
        top: 1rem;
    }
}

// FLYOUT CONTAINERS
.dds__msthd-ctnr {
    background: $dell-white;
    border-bottom: 0.25rem $dell-blue solid;
    border-left: 0.0625rem solid $dell-sky;
    border-radius: 0;
    border-right: 0.0625rem solid $dell-sky;
    border-top: 0;
    box-shadow: none;
    color: #444;
    display: none;
    left: auto;
    margin-top: 0;
    padding: 0.9375rem 0;
    position: absolute;
    right: 0;
    width: 20rem;
    z-index: $zindex-dropdown;

    &.dds__active {
        display: block;
    }

    &.dds__country-list {
        background: $dell-white;
        border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.15);
        border-left: 0.0625rem solid rgba(0, 0, 0, 0.15);
        height: 15.625rem;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 0;
        right: 0;
        width: 15.625rem;

        &.dds__show {
            display: block;
        }

        li {
            display: block;
            height: auto;
            text-align: left;
            width: 100%;

            &:hover {
                background-color: $dell-sky;
                cursor: pointer;
            }

            a {
                color: $dell-black;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

// BOTTOM NAV
.dds__msthd-bottom {
    display: flex;
    left: -0.3125rem;
}

.dds__msthd-navbar-bottom {
    display: inherit;
    z-index: $zindex-sticky + 1;
}

// START MENU
.dds__msthd-menu-top {
    align-items: stretch;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.dds__msthd-menu-top-item {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    &:hover,
    &[active] {
        background: $dell-white;

        .dds__msthd-menu-top-link {
            color: $dell-sea-blue;
        }
    }
}

.dds__msthd-menu-top-link {
    color: $dell-white;
    padding: 0.4375rem 1.25rem 0.375rem;

    &:hover,
    &[active] {
        background: $dell-white;
        color: $dell-sea-blue;
        text-decoration: none;
    }
}

.dds__msthd-menu-ctnr {
    display: none;
    margin: 0;
    padding: 0;
}

.dds__msthd-menu-tier-one {
    border-color: rgba(0, 0, 0, 0.15);
    border-style: solid;
    border-width: 0 0.0625rem;
    flex-direction: column;
    top: 2.125rem;
    width: 13.375rem;
}

.dds__msthd-menu-tier-sub {
    border-right: solid 0.0625rem rgba(0, 0, 0, 0.15);
    flex-direction: column;
    left: 13.25rem;
    top: 0;
    width: 13.375rem;
}

.dds__msthd-menu-tier-img {
    border-right: solid 0.0625rem rgba(0, 0, 0, 0.15);
    flex-direction: row;
    height: auto;
    left: 13.25rem;
    text-align: center;
    width: auto;
}

.dds__msthd-menu-item-img {
    background: $dell-white;
    height: auto;
    min-width: 12.5rem;
    padding: 1rem;
    width: 100%;

    a {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        position: relative;

        img {
            margin: auto;
        }

        &:hover {
            text-decoration: none;
        }

        .dds__msthd-img-caption {
            color: $dell-sea-blue;
            font-size: 1.125rem;
            margin: 0.5rem 0 0.3125rem;
        }

        .dds__msthd-img-subtitle {
            color: $dell-black;
            text-align: center;
        }
    }

    &:not(:first-child) {
        border-left: solid 0.0625rem rgba(0, 0, 0, 0.15);
    }

    &:hover {
        background: $dell-sky;
        color: $dell-sea-blue;
    }
}

.dds__msthd-menu-top-item:hover > .dds__msthd-menu-tier-one,
.dds__msthd-menu-item:hover > .dds__msthd-menu-tier-sub,
.dds__msthd-menu-item:hover > .dds__msthd-menu-tier-img,
.dds__msthd-menu-tier-one[active],
.dds__msthd-menu-tier-sub[active],
.dds__msthd-menu-tier-img[active] {
    background: $dell-white;
    border-bottom: solid 0.25rem $dell-blue;
    display: flex;
    list-style: none;
    position: absolute;
    z-index: $zindex-fixed;
}

.dds__msthd-menu-tier-sub::before,
.dds__msthd-menu-tier-img::before {
    //background: -webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.65)),to(rgba(0,0,0,0)));
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.15) 100%);
    bottom: -0.25rem;
    content: "";
    left: -0.75rem;
    opacity: 0.8;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 0.75rem;
}

.dds__msthd-menu-item {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
}

.dds__msthd-menu-link {
    align-items: center;
    color: $dell-sea-blue;
    display: flex;
    justify-content: space-between;
    padding: 0.625rem 1.25rem 0.5625rem;
    width: 100%;

    &:hover {
        background: $dell-sky;
        color: $dell-midnight;
        text-decoration: none;
    }

    svg {
        pointer-events: none;
    }
}
// END MENU

// // MEDIA BEGIN
// // for "smaller-than-Large devices" (so, below 992 px)
// OFF CANVAS MENU
@media screen and (max-width: 991.98px) {
    .dds__msthd {
        .dds__navbar {
            z-index: auto;
        }
    }

    .dds__msthd-dell-icon {
        font-size: 2.25rem;
        padding: 0.1875rem 0;
        //margin-left: 1.875rem;
    }

    .dds__msthd-search-ctnr {
        display: flex;
        flex: 1 1;
        justify-content: flex-end;
    }

    .dds__msthd-top.dds__container {
        padding: 0;
    }

    .dds__msthd-search + .dds__msthd-top-right-ctnr {
        flex-grow: 0;
    }

    .dds__msthd-offcanvas-menu {
        background: $dell-quartz;
        bottom: 0;
        display: flex;
        height: 100vh;
        overflow: hidden;
        overflow-y: auto;
        position: absolute;
        right: -17.25rem;
        top: 0;

        transform: initial;
        transition: transform 200ms ease;
        width: 17.25rem;
        z-index: $zindex-offcanvas;

        &.dds__active {
            transform: translate(0);
            transition: transform 200ms ease;

            &.dds__safari-fix {
                height: 87vh;

                li.dds__msthd-offcanvas-tier-1:last-of-type {
                    padding-bottom: 1.5rem;
                }
            }
        }

        .dds__msthd-offcanvas-nav-item {
            background-color: transparent;
            border: 0;
            border-bottom: 0.0625rem solid $dell-ash;
            clear: both;
            color: $link-color;
            cursor: pointer;
            display: block;
            font-weight: $font-weight-normal;
            padding: 0.4375rem 0 0.5rem 1.25rem;
            text-align: inherit;
            white-space: nowrap;
            width: 100%;

            &:hover {
                @include gradient-bg($dropdown-link-hover-bg);
                color: $link-hover-color;
                text-decoration: none;
            } //$dropdown-item-padding-y $dropdown-item-padding-x;
        }

        ul {
            list-style-type: none;
            // padding: .5rem;
            padding: 0;
            width: 100%;
            //box-shadow: inset 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.5);
        }

        .dds__arrow-tri-solid-right {
            display: inline-block;
            fill: $dell-blue;
            height: 1rem;
            position: absolute;
            right: 0.625rem;
            stroke: $dell-blue;
            stroke-width: 0;
            transform: rotate(90deg);
            width: 1rem;
        }

        .dds__msthd-offcanvas-tier-1 {
            background-color: $dell-quartz;

            &:hover {
                .dds__arrow-tri-solid-right {
                    fill: $dell-midnight;
                }
            }
        }

        .dds__msthd-offcanvas-nav-item:not(.dds__collapsed) {
            .dds__arrow-tri-solid-right:first-of-type {
                transform: rotate(-90deg);
            }
        }

        .dds__msthd-offcanvas-tier-2 {
            background-color: $dell-white;

            .dds__arrow-tri-solid-right {
                display: inline-block;
                fill: $dell-moon;
                height: 1rem;
                stroke: $dell-moon;
                stroke-width: 0;
                transform: rotate(90deg);
                width: 1rem;
            }
        }

        .dds__msthd-offcanvas-tier-3 {
            background-color: $dell-white;
            border-left: 0.25rem solid $dell-blue;
            padding-left: 0.9375rem;

            .dds__msthd-offcanvas-nav-item {
                border-bottom: 0.0625rem dotted $dell-ash;
            }
        }
    }

    .dds__body-off-canvas {
        overflow-x: hidden;
        position: relative;
        transform: initial;
        transition: transform 200ms ease;

        &.dds__active {
            height: 100%;

            overflow-x: hidden;
            overflow-y: hidden;
            transform: translate(-17.25rem);
            transition: transform 200ms ease;
            width: 100%;
        }
    }

    .dds__msthd-hidden {
        display: inherit;
    }

    .dds__msthd > .dds__msthd-navbar-bottom {
        display: none;
    }

    .dds__msthd .dds__msthd-top.dds__container {
        max-width: none;
    }

    .dds__msthd-top-ctnr {
        .dds__msthd-icon-ctnr[data-target$="off-canvas-menu"] {
            display: flex;
        }
    }

    .dds__msthd-icon-ctnr {
        display: flex;
        flex-direction: column;
        //margin-left: auto;
        height: 3.75rem;
        justify-content: center;
        width: auto;
        //width: 3.4375rem;
        //padding: 0.5rem 0.3125rem 0.3125rem 0.3125rem;

        .dds__msthd-label {
            cursor: pointer;
            display: inline-block;
            margin: 0;
            //margin-bottom: 0.3125rem;
            padding-right: 0;
            text-align: center;
            white-space: nowrap;
            width: 2.8125rem;
        }

        .dds__badge {
            //position: absolute;
            //font-size: 0.625rem;
            left: 1.75rem;
            top: 0.1875rem;
        }

        &.dds__msthd-logo {
            padding: 0 0.625rem;
        }
    }

    .dds__msthd-top-ctnr {
        min-width: auto;
        position: static;

        .dds__msthd-ctnr {
            left: 0;
            position: absolute;
            right: 0;
            top: auto;
            width: 100%;
        }
    }

    .dds__msthd-search {
        flex: 1 1;
        justify-content: flex-end;
    }

    .dds__msthd-navbar-top {
        height: 3.75rem;
    }
}
@media screen and (max-width: 767.98px) {
    // .dds__msthd-search {
    //   display: block !important;
    //   // width: auto;
    //   // margin-left: 0;
    //   // margin-top: 0.125rem;
    //   margin-right: 0;
    //   // cursor: pointer;
    //   // padding: 0;

    //   // &.dds__active {
    //   //   background: $dell-white;
    //   //   color: $dell-blue;
    //   // }
    // }
    // }

    // for "smaller-than-Medium devices" (so, below 768 px)
    // @media screen and (max-width: 767.98px) {
    // body {
    //   transform: translateZ(0);
    // }

    .dds__msthd-dell-icon {
        font-size: 2.25rem;
        padding: 0.1875rem 0;
        //margin-left: 1.875rem;
    }

    // .dds__msthd-top-icon {
    //   font-size: 1.5rem;
    //   padding-right: 0;

    // .dds__badge {
    //   position: absolute;
    //   font-size: 0.625rem;
    //   left: 1.75rem;
    //   top: 0.1875rem;
    // }
    // }

    //Container for dds__search
    .dds__msthd-search {
        cursor: pointer;
        display: block !important;
        flex: none;
        margin-left: auto;
        //margin-top: 0.125rem;
        margin-right: 0;
        padding: 0;
        position: static;
        width: auto;

        &.dds__active {
            background: $dell-white;
            color: $dell-blue;
        }
    }

    .dds__msthd-search-ctnr.dds__active {
        background: $dell-white;
        border-bottom: $dell-blue solid 0.25rem;
        display: block !important;
        height: auto;
        left: 0;
        padding: 0.625rem;
        position: absolute;
        right: 0;
        top: auto;
        width: 100%;

        i {
            padding-right: 0;
        }
    }

    .dds__msthd-search-input {
        border-bottom: 0.0625rem solid rgb(204, 204, 204);
        border-left: 0.0625rem solid rgb(204, 204, 204);
        border-top: 0.0625rem solid rgb(204, 204, 204);
    }

    .dds__msthd-search-btn {
        margin: 0;
    }

    // .dds__msthd-signout-ctnr {
    //   position: fixed;
    //   left: 0rem;
    //   right: 0rem;
    //   width: 100%;
    //   top: auto;
    // }

    // .dds__msthd-cart-ctnr {
    //   position: fixed;
    //   left: 0rem;
    //   right: 0rem;
    //   width: 100%;
    //   top: auto;
    // }
}
// MEDIA END
