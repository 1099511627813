.dds__radio-btn-wrapper-stroked {
    @include mixin_dds__radio-btn-wrapper-stroked();

    .dds__card-stroked {
        &.dds__active {
            background-color: $dell-cloud;
            border: 0.125rem solid $dell-blue;

            .dds__card-title-stroked {
                font-weight: bold;
            }

            .dds__card-label-stroked {
                &:hover {
                    border: none;
                }

                .dds__card-label-container {
                    padding: 0.5625rem 0.4375rem 0;
                }
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .dds__radio-btn-wrapper-stroked {
        .dds__card-stroked {
            &.dds__active .dds__card-label-stroked .dds__card-label-container {
                padding: 0.625rem 0.5rem;
            }
        }
    }
}
