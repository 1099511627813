.dds__border {
    border: $border-width solid $border-color !important;
}
.dds__border-top {
    border-top: $border-width solid $border-color !important;
}
.dds__border-right {
    border-right: $border-width solid $border-color !important;
}
.dds__border-bottom {
    border-bottom: $border-width solid $border-color !important;
}
.dds__border-left {
    border-left: $border-width solid $border-color !important;
}

.dds__border-0 {
    border: 0 !important;
}
.dds__border-top-0 {
    border-top: 0 !important;
}
.dds__border-right-0 {
    border-right: 0 !important;
}
.dds__border-bottom-0 {
    border-bottom: 0 !important;
}
.dds__border-left-0 {
    border-left: 0 !important;
}

// Commenting out unused map func
// @each $color, $value in $theme-colors{
//   .dds__border-#{$color}{
//     border-color:$value !important;
//   }
// }

.dds__border-white {
    border-color: $white !important;
}

.dds__rounded {
    border-radius: $border-radius !important;
}
.dds__rounded-top {
    border-top-left-radius: $border-radius !important;
    border-top-right-radius: $border-radius !important;
}
.dds__rounded-right {
    border-top-right-radius: $border-radius !important;
    border-bottom-right-radius: $border-radius !important;
}
.dds__rounded-bottom {
    border-bottom-right-radius: $border-radius !important;
    border-bottom-left-radius: $border-radius !important;
}
.dds__rounded-left {
    border-top-left-radius: $border-radius !important;
    border-bottom-left-radius: $border-radius !important;
}

.dds__rounded-circle {
    border-radius: 50% !important;
}

.dds__rounded-0 {
    border-radius: 0 !important;
}
