@if $enable-grid-classes {
    .dds__container {
        @include make-container();
        @include make-container-max-widths();
    }
}

@if $enable-grid-classes {
    .dds__container-fluid {
        @include make-container();
    }
}

@if $enable-grid-classes {
    .dds__row {
        @include make-row();
    }

    .dds__no-gutters {
        margin-left: 0;
        margin-right: 0;

        > .dds__col,
        > [class*="col-"] {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@if $enable-grid-classes {
    @include make-grid-columns();
}
