.dds__items-view {
    position: absolute;
    text-align: right;
    top: 0;
    width: 97%;
}

// Fix carousel blink on IE 11
.dds__filmstrip-slides {
    backface-visibility: visible;
    display: flex;
    list-style: none;
    padding: 0.125rem 0;
}

.dds__filmstrip-carousel {
    @include mixin_dds__container;
    padding: 1.5rem 0 0;

    h5,
    p {
        white-space: normal;
    }

    .dds__icons {
        background-color: rgba(255, 255, 255, 0.5);
        border: none;
        color: $dell-blue;
        cursor: pointer;
        font-size: 2rem;
        height: 9.375rem;
        position: absolute;
    }

    .dds__chevron-left {
        left: 0;
        top: 0;
    }

    .dds__chevron-right {
        right: 0;
        top: 0;
    }
}

@media screen and (max-width: 767.98px) {
    .dds__filmstrip-carousel {
        .dds__overflow-control {
            &[active],
            &.dds__active {
                display: flex;
            }
        }
    }
}

@media screen and (max-width: 991.98px) {
    .dds__items-view {
        width: 95%;
    }
}
