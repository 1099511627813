.dds__btn-link {
    //At top of file so class="dds__btn dds__btn-link" inherits padding of dds__btn w/o use of !important
    padding: 0.0625rem 0.375rem;
}

.dds__btn {
    @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
    @include transition($btn-transition);
    border: $btn-border-width solid transparent;
    display: inline-block;
    font-weight: $btn-font-weight;
    text-align: center;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    @include hover-focus {
        text-decoration: none;
    }

    &:focus,
    &.dds__focus {
        box-shadow: $btn-focus-box-shadow;
        outline: 0;
    }

    &:focus {
        outline: $custom-focus-outline;
        outline-offset: $custom-focus-offset;
    }

    &.dds__disabled,
    &:disabled {
        @include box-shadow(none);
        opacity: $btn-disabled-opacity;
    }

    &:not(:disabled):not(.dds__disabled) {
        cursor: pointer;
    }

    &:not(:disabled):not(.dds__disabled):active,
    &:not(:disabled):not(.dds__disabled).dds__active {
        @include box-shadow($btn-active-box-shadow);

        &:focus {
            @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
        }
    }

    &:not(:disabled):not(.dds__disabled):not(.dds__btn-link):active {
        box-shadow: inset 0 0.3125rem 0.625rem 0 rgba(0, 0, 0, 0.5) !important;
    }

    &.dds__btn-primary:not(:disabled):not(.dds__disabled):hover,
    &.dds__btn-primary:not(:disabled):not(.dds__disabled):active {
        background-color: $dell-midnight;
        border-color: $dell-midnight;
    }

    &.dds__btn-purchase:not(:disabled):not(.dds__disabled):hover,
    &.dds__btn-purchase:not(:disabled):not(.dds__disabled):active {
        background-color: $dell-primary-purchase-green-hover;
        border-color: $dell-primary-purchase-green-hover;
    }

    &.dds__btn-secondary {
        border-color: $btn-border-gray;
        color: $dell-sea-blue;

        svg {
            fill: $dell-sea-blue;
        }
    }

    &.dds__btn-secondary:not(:disabled):not(.dds__disabled):hover,
    &.dds__btn-secondary:not(:disabled):not(.dds__disabled):active {
        background-color: $dell-ash;
        border-color: $btn-border-gray;
        color: $dell-midnight;

        svg {
            fill: $dell-midnight;
        }
    }

    &.dds__btn-primary:disabled,
    &.dds__btn-primary.dds__disabled {
        background-color: $dell-blue-disabled;
        border-color: $dell-blue-disabled;
        opacity: 1;
    }

    &.dds__btn-purchase:disabled,
    &.dds__btn-purchase.dds__disabled {
        background-color: $dell-primary-purchase-green-disabled;
        border-color: $dell-primary-purchase-green-disabled;
        opacity: 1;
    }

    &.dds__btn-secondary:disabled,
    &.dds__btn-secondary.dds__disabled {
        background-color: $dell-secondary-non-purchase-disabled;
        border-color: $dell-secondary-disabled;
        color: $dell-granite;
        opacity: 1;

        svg {
            fill: $dell-granite;
        }
    }
}

a.dds__btn.dds__disabled,
fieldset:disabled a.dds__btn {
    pointer-events: none;
}

@each $color, $value in $theme-colors {
    .dds__btn-#{$color} {
        @include button-variant($value, $value);
    }
}

// Commenting out imports to unused mixins
// @each $color, $value in $theme-colors {
//   .dds__btn-outline-#{$color} {
//     @include button-outline-variant($value);
//   }
// }

.dds__btn-link {
    background-color: transparent;
    border: none;
    color: $dell-sea-blue;
    cursor: pointer;
    font-weight: $font-weight-normal;

    svg {
        fill: $dell-sea-blue;
    }

    @include hover {
        background-color: transparent;
        border-color: transparent;
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;

        svg {
            fill: $link-hover-color;
        }
    }

    &:focus,
    &.dds__focus {
        border-color: transparent;
        box-shadow: none;
        text-decoration: $link-hover-decoration;
    }

    &:disabled,
    &.dds__disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    }

    &:focus {
        &:hover {
            span {
                text-decoration: underline;
            }
        }
    }

    &:active {
        box-shadow: none !important;
    }
}

.dds__btn-lg {
    @include button-size(
        $btn-padding-y-lg,
        $btn-padding-x-lg,
        $font-size-lg,
        $btn-line-height-lg,
        $btn-border-radius-lg
    );
}

.dds__btn-sm {
    @include button-size(
        $btn-padding-y-sm,
        $btn-padding-x-sm,
        $font-size-sm,
        $btn-line-height-sm,
        $btn-border-radius-sm
    );
}

.dds__btn-block {
    display: block;
    width: 100%;

    + .dds__btn-block {
        margin-top: $btn-block-spacing-y;
    }
}

input[type="submit"],
input[type="reset"],
input[type="button"] {
    &.dds__btn-block {
        width: 100%;
    }
}

body:not(.user-is-tabbing) {
    outline: none !important;
}

.dds__btn-filter-container {
    .dds__btn-filter {
        border: 0.0625rem solid $dell-granite;
        cursor: default;
        height: auto;
        line-height: 1.75;
        margin-right: 0.3125rem;
        padding: 0.375rem 0.75rem;
        white-space: nowrap;

        &:hover {
            &:not(.dds__active) {
                background-color: $dell-ash;
                border: 0.0625rem solid $dell-blue;
                color: $dell-black;
                cursor: pointer !important;
                text-decoration: none;
            }
        }

        &.dds__active {
            border: 0.125rem solid $dell-blue;
            padding: 0.3125rem 0.6875rem;
        }
    }
}

body.user-is-tabbing .dds__filter-item input:focus ~ label {
    outline: $custom-focus-outline;
    outline-offset: $custom-focus-offset;
    outline-offset: 0.125rem -webkit-focus-ring-color !important;
}

body.user-is-tabbing .dds__bar-select input:focus ~ label {
    outline: $custom-focus-outline;
    outline-offset: $custom-focus-offset;
    outline-offset: 0.125rem -webkit-focus-ring-color !important;
}

/* Begin button toggle the box around the slider */
.dds__btn-toggle-container {
    display: flex;

    label {
        align-self: center;
        margin-bottom: 0;
        padding-right: 0.5rem;
    }
}

.dds__btn-toggle {
    border: 0;
    border-radius: 1.5625rem;
    box-shadow: none;
    color: $dell-white;
    cursor: pointer;
    font-size: 0.875rem;
    height: 1.75rem;
    margin-top: 0.0625rem;
    min-width: 3.75rem;
    position: relative;
    transition: background-color 500ms ease;
    visibility: hidden;

    &[aria-checked="false"] {
        background-color: $dell-moon;
        padding: 0.125rem 0.125rem 0.125rem 1.4375rem;

        .dds__toggle-txt-on {
            display: none;
        }

        .dds__toggle-txt-off {
            display: block;
        }
    }

    &[aria-checked="true"] {
        background-color: $dell-blue;
        padding: 0.125rem 1.4375rem 0.125rem 0.125rem;

        .dds__toggle-txt-on {
            display: block;
        }

        .dds__toggle-txt-off {
            display: none;
        }
    }

    span.dds__toggle-txt-on,
    span.dds__toggle-txt-off {
        padding: 0 0.75rem;
        position: relative;
        white-space: nowrap;
    }

    .dds__toggle-txt-on {
        text-align: left;
    }

    .dds__toggle-txt-off {
        text-align: right;
    }

    &.dds__disabled,
    &:disabled {
        opacity: 0.4;
    }

    /* The dds__toggle-circle */
    .dds__toggle-circle {
        background-color: $dell-white;
        border-radius: 50%;
        display: block;
        height: 1.5rem;
        left: 0.125rem;
        position: absolute;
        top: 0.125rem;
        transition: transform 500ms ease;
        width: 1.5rem;
    }
}

@media (min-width: 767.98px) {
    //only add hover state on large devices so that iOS doesn't get hover state
    .dds__btn-toggle {
        &[aria-checked="false"]:hover:not(:active):not(:disabled):not(.dds__disabled) {
            background-color: $dell-carbon;
        }

        &[aria-checked="true"]:hover:not(:active):not(:disabled):not(.dds__disabled) {
            background-color: $dell-midnight;
        }
    }
}
