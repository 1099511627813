@mixin compressed-state {
    .dds__link-picker-ctnr {
        align-items: unset;
        display: flex;
        justify-content: center;
    }

    .dds__hero-banner .dds__container {
        align-items: stretch;
        display: inline-flex;
        flex-direction: row;
        margin: 0 !important;
        max-width: 100% !important;
        padding: 0 !important;
        position: relative;
    }

    .dds__hero-banner .dds__card-body {
        background-color: $dell-mist;
        border-radius: 0;
        display: inline-block;
        margin: 0;
        max-width: 100%;
        min-width: 0;
        padding: 0.9375rem;
        position: relative;
        top: auto;
        width: 100%;
    }

    .dds__link-picker .dds__card-body {
        background-color: $dell-mist;
        border-radius: 0;
        display: inline-block;
        height: auto;
        left: 0;
        margin: 0;
        min-width: 0;
        padding: 0.9375rem;
        position: relative;
        right: 0;
        top: auto;
        width: 100%;

        .dds__picker-btn-ctnr {
            display: flex;
            flex-direction: column;

            div {
                margin: 0;
            }

            .dds__form-select {
                flex: 1 1 auto;
                margin-bottom: 0.5rem;
                width: 100%;
            }

            .dds__display-text-ctnr {
                align-items: center;
                display: flex;
                flex: 1 1 auto;
                justify-content: space-between;
                margin: 0;
                width: 100%;

                > a {
                    width: auto;
                }

                .dds__display-text {
                    margin-top: 0;
                    position: static;
                }
            }
        }
    }
}
