.dds__btn-group,
.dds__btn-group-vertical {
    display: inline-flex;
    position: relative;
    vertical-align: middle;

    > .dds__btn {
        flex: 0 1 auto;
        position: relative;
        @include hover {
            z-index: 1;
        }

        &:focus,
        &:active,
        &.dds__active {
            z-index: 1;
        }
    }

    .dds__btn + .dds__btn,
    .dds__btn + .dds__btn-group,
    .dds__btn-group + .dds__btn,
    .dds__btn-group + .dds__btn-group {
        margin-left: -$btn-border-width;
    }
}

.dds__btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .dds__input-group {
        width: auto;
    }
}

.dds__btn-group {
    > .dds__btn:first-child {
        margin-left: 0;
    }

    > .dds__btn:not(:last-child):not(.dds__dropdown-toggle),
    > .dds__btn-group:not(:last-child) > .dds__btn {
        @include border-right-radius(0);
    }

    > .dds__btn:not(:first-child),
    > .dds__btn-group:not(:first-child) > .dds__btn {
        @include border-left-radius(0);
    }
}

.dds__btn-group-sm > .dds__btn {
    @extend .dds__btn-sm;
}
.dds__btn-group-lg > .dds__btn {
    @extend .dds__btn-lg;
}

.dds__btn-group.dds__show .dds__dropdown-toggle {
    @include box-shadow($btn-active-box-shadow);

    &.dds__btn-link {
        @include box-shadow(none);
    }
}

.dds__btn-group-vertical {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;

    .dds__btn,
    .dds__btn-group {
        width: 100%;
    }

    > .dds__btn + .dds__btn,
    > .dds__btn + .dds__btn-group,
    > .dds__btn-group + .dds__btn,
    > .dds__btn-group + .dds__btn-group {
        margin-left: 0;
        margin-top: -$btn-border-width;
    }

    > .dds__btn:not(:last-child):not(.dds__dropdown-toggle),
    > .dds__btn-group:not(:last-child) > .dds__btn {
        @include border-bottom-radius(0);
    }

    > .dds__btn:not(:first-child),
    > .dds__btn-group:not(:first-child) > .dds__btn {
        @include border-top-radius(0);
    }
}
