@each $prop, $abbrev in (width: w, height: h) {
    @each $size, $length in $sizes {
        .dds__#{$abbrev}-#{$size} {
            #{$prop}: $length !important;
        }
    }
}

.dds__mw-100 {
    max-width: 100% !important;
}
.dds__mh-100 {
    max-height: 100% !important;
}
