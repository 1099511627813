.dds__checkbox-wrapper-stroked {
    @include mixin_dds__checkbox-wrapper-stroked();

    .dds__checkbox-stroked.dds__card-stroked {
        border: none;

        &:not(.dds__active):hover {
            border: none;
        }

        .dds__card-label-stroked {
            padding: 0;

            .dds__checkbox {
                align-items: center;
                background-color: $dell-white;
                border: none;
                border-bottom: 0.0625rem solid $dell-ash;
                border-right: 0.0625rem solid $dell-ash;
                display: flex;
                height: 1.25rem;
                justify-content: center;
                left: 0.0625rem;
                position: absolute;
                top: 0.0625rem;
                width: 1.25rem;

                .dds__icons.dds__check {
                    color: $dell-white;
                }
            }

            input:checked ~ .dds__card-label-container {
                background-color: $dell-cloud;
                border: 0.125rem solid $dell-blue;
                padding: 0.625rem 0.5rem 0.625rem 1.5rem;

                .dds__card-title-stroked {
                    font-weight: bold;
                }
            }

            input:checked ~ .dds__checkbox {
                background-color: $dell-blue;
                border-color: $dell-blue;
            }

            .dds__card-label-container {
                border: 0.0625rem solid $dell-ash;
                padding: 0.6875rem 0.5625rem 0.6875rem 1.5625rem;

                &:hover {
                    background-color: $dell-quartz;
                    border: 0.0625rem solid $dell-blue;
                }
            }
        }
        //DISABLED CHECKBOXES
        .dds__card-label-stroked {
            input:disabled,
            input:disabled:checked {
                /* stylelint-disable */
                & ~ .dds__card-label-container,
                & ~ .dds__checkbox {
                    /* stylelint-enable */
                    cursor: not-allowed;
                    background-color: $dell-mist;

                    i {
                        display: none;
                    }
                }
                /* stylelint-disable */
                & + .dds__card-label-container {
                    /* stylelint-enable */
                    background-color: $dell-mist;
                    border-color: $dell-ash;
                    color: $dell-moon;

                    img {
                        opacity: 0.5;
                    }

                    .dds__card-txt-stroked-secondary {
                        color: $dell-moon;
                    }

                    &:hover {
                        background-color: $dell-mist;
                        border-color: $dell-ash;
                    }
                }
            }

            input:disabled:checked {
                ~ .dds__checkbox {
                    background-color: $dell-ash;
                    border-color: $dell-ash;

                    i {
                        display: block;
                    }
                }

                ~ .dds__card-label-container {
                    // border: 0.125rem solid $dell-blue;
                    padding: 0.625rem 0.5rem 0.625rem 1.5rem;
                }
            }
        }
    }
}

fieldset.dds__disabled {
    //IE 11 doesn't support disabled attr on fieldsets
    .dds__checkbox-wrapper-stroked {
        .dds__checkbox-stroked.dds__card-stroked {
            .dds__card-label-stroked {
                input {
                    /* stylelint-disable */
                    & ~ .dds__card-label-container,
                    & ~ .dds__checkbox {
                        /* stylelint-enable */
                        cursor: not-allowed;
                        background-color: $dell-mist;

                        i {
                            display: none;
                        }
                    }
                    /* stylelint-disable */
                    & + .dds__card-label-container {
                        /* stylelint-enable */
                        background-color: $dell-mist;
                        border-color: $dell-ash;
                        color: $dell-moon;

                        img {
                            opacity: 0.5;
                        }

                        .dds__card-txt-stroked-secondary {
                            color: $dell-moon;
                        }

                        &:hover {
                            background-color: $dell-mist;
                            border-color: $dell-ash;
                        }
                    }
                }

                input:checked {
                    ~ .dds__checkbox {
                        background-color: $dell-ash;
                        border-color: $dell-ash;

                        i {
                            display: block;
                        }
                    }

                    ~ .dds__card-label-container {
                        padding: 0.625rem 0.5rem 0.625rem 1.5rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767.98px) {
    .dds__checkbox-wrapper-stroked .dds__checkbox-stroked.dds__card-stroked {
        .dds__card-label-stroked {
            input:checked ~ .dds__card-label-container {
                padding-left: 2rem;
            }

            .dds__card-label-container {
                padding-left: 2.0625rem;

                &:hover {
                    background-color: $dell-white;
                    border-color: $dell-ash;
                }
            }

            input:disabled ~ .dds__card-label-container {
                padding-left: 2.0625rem;
            }

            input:checked:disabled ~ .dds__card-label-container {
                padding-left: 2rem;
            }
        }
    }

    fieldset:disabled,
    fieldset.dds__disabled {
        .dds__checkbox-wrapper-stroked .dds__checkbox-stroked.dds__card-stroked {
            .dds__card-label-stroked {
                input:checked ~ .dds__card-label-container {
                    padding-left: 2rem;
                }

                .dds__card-label-container {
                    padding-left: 2.0625rem;
                }
            }
        }
    }
}
