.dds__text-monospace {
    font-family: $font-family-monospace;
}

.dds__text-justify {
    text-align: justify !important;
}
.dds__text-nowrap {
    white-space: nowrap !important;
}
.dds__text-truncate {
    @include text-truncate;
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .dds__text#{$infix}-left {
            text-align: left !important;
        }
        .dds__text#{$infix}-right {
            text-align: right !important;
        }
        .dds__text#{$infix}-center {
            text-align: center !important;
        }
    }
}

.dds__text-lowercase {
    text-transform: lowercase !important;
}
.dds__text-uppercase {
    text-transform: uppercase !important;
}
.dds__text-capitalize {
    text-transform: capitalize !important;
}

.dds__font-weight-light {
    font-weight: $font-weight-light !important;
}
.dds__font-weight-normal {
    font-weight: $font-weight-normal !important;
}
.dds__font-weight-bold {
    font-weight: $font-weight-bold !important;
}
.dds__font-italic {
    font-style: italic !important;
}

.dds__text-white {
    color: $white !important;
}

@each $color, $value in $theme-colors {
    @include text-emphasis-variant(".dds__text-#{$color}", $value);
}

.dds__text-body {
    color: $body-color !important;
}
.dds__text-muted {
    color: $text-muted !important;
}

.dds__text-black-50 {
    color: rgba($black, 0.5) !important;
}
.dds__text-white-50 {
    color: rgba($white, 0.5) !important;
}

.dds__text-hide {
    @include text-hide($ignore-warning: true);
}

.dds__text-granite {
    color: $dell-granite;
}
.dds__text-truncate {
    max-width: 100%;
}
.dds__break-word {
    word-break: break-word;
    word-wrap: break-word;
}
.dds__text-wrap {
    white-space: normal;
}
