.dds__navbar {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: $navbar-padding-y $navbar-padding-x;
    position: relative;

    > .dds__container,
    > .dds__container-fluid {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.dds__navbar-expand {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $next: breakpoint-next($breakpoint, $grid-breakpoints);
        $infix: breakpoint-infix($next, $grid-breakpoints);

        &#{$infix} {
            @include media-breakpoint-down($breakpoint) {
                > .dds__container,
                > .dds__container-fluid {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            @include media-breakpoint-up($next) {
                flex-flow: row nowrap;
                justify-content: flex-start;

                .dds__navbar-nav {
                    flex-direction: row;

                    .dds__dropdown-menu {
                        position: absolute;
                    }

                    .dds__nav-link {
                        padding-left: $navbar-nav-link-padding-x;
                        padding-right: $navbar-nav-link-padding-x;
                    }
                }

                > .dds__container,
                > .dds__container-fluid {
                    flex-wrap: nowrap;
                }

                .dds__navbar-collapse {
                    display: flex !important;
                    flex-basis: auto;
                }

                .dds__navbar-toggler {
                    display: none;
                }
            }
        }
    }
}
