.dds__list-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-left: 0;
}

// Commenting out includes to unused mixins
// @each $color, $value in $theme-colors {
//   @include list-group-item-variant($color, theme-color-level($color, -9), theme-color-level($color, 6));
// }

.dds__list-inline {
    margin: 0;

    .dds__list-inline-item {
        &:not(:first-child)::before {
            content: "|";
            padding-right: 0.5rem;
        }

        .dds__list-item {
            padding-right: 0.5rem;
        }
    }
}

.dds__list-group-item-action {
    color: $list-group-action-color;
    text-align: inherit;
    width: 100%;
    @include hover-focus {
        background-color: $list-group-hover-bg;
        color: $list-group-action-hover-color;
        text-decoration: none;
    }

    &:active {
        background-color: $list-group-action-active-bg;
        color: $list-group-action-active-color;
    }
}

.dds__list-group-flush {
    .dds__list-group-item {
        @include border-radius(0);
        border-left: 0;
        border-right: 0;
    }

    &:first-child {
        .dds__list-group-item:first-child {
            border-top: 0;
        }
    }

    &:last-child {
        .dds__list-group-item:last-child {
            border-bottom: 0;
        }
    }
}

.dds__list-group:not(.dds__list-group-flush) {
    list-style-position: outside;
    margin: 1.25rem 0;
    padding-left: 1rem;

    li {
        margin-bottom: 0.625rem;
    }
}

.dds__list-group-unstyled:not(.dds__list-group-flush) {
    list-style: none;
    padding-left: 0;

    .dds__list-group-item-unordered {
        list-style: none;
    }
}

.dds__list-group-item {
    background-color: $list-group-bg;
    border: $list-group-border-width solid $list-group-border-color;
    display: block;
    margin-bottom: -$list-group-border-width;
    padding: $list-group-item-padding-y $list-group-item-padding-x;
    position: relative;

    @include hover-focus {
        text-decoration: none;
        z-index: 1;
    }

    &:first-child {
        @include border-top-radius($list-group-border-radius);
    }

    &:last-child {
        @include border-bottom-radius($list-group-border-radius);
        margin-bottom: 0;
    }

    &.dds__disabled,
    &:disabled {
        background-color: $list-group-disabled-bg;
        color: $list-group-disabled-color;
    }

    &.dds__active {
        background-color: $list-group-active-bg;
        border-color: $list-group-active-border-color;
        color: $list-group-active-color;
        z-index: 2;
    }
}

.dds__list-group-item-unordered {
    list-style-type: disc;
}
