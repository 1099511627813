@mixin img-fluid {
    max-width: 100%;
    height: auto;
}
@mixin img-retina($file-1x, $file-2x, $width-1x, $height-1x) {
    background-image: url($file-1x);
    @media only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
        background-image: url($file-2x);
        background-size: $width-1x $height-1x;
    }
}
