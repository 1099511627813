@mixin nav-divider($color: $nav-divider-color, $margin-y: $nav-divider-margin-y) {
    height: 0;
    margin: $margin-y 0;
    overflow: hidden;
    border-top: 1px solid $color;
}

@mixin mixin_dds__nav-tabs() {
    @extend .dds__nav;
    @extend .dds__nav-tabs;
}

@mixin mixin_dds__tab-link() {
    @extend .dds__nav-link;
}
