.dds__fade {
    @include transition($transition-fade);

    &:not(.dds__show) {
        opacity: 0;
    }
}

.dds__collapse {
    &:not(.dds__show) {
        display: none;
    }
}

.dds__collapsing {
    @include transition($transition-collapse);
    height: 0;
    overflow: hidden;
    position: relative;
}
