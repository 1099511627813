.dds__link-wrapper-stroked {
    @include mixin_dds__link-wrapper-stroked;

    .dds__link-stroked {
        .dds__card-txt-stroked-supporting {
            color: $dell-black;
        }

        &:hover {
            text-decoration: none;
        }
    }
}
