.dds__shadow-sm {
    box-shadow: $box-shadow-sm !important;
}
.dds__shadow {
    box-shadow: $box-shadow !important;
}
.dds__shadow-lg {
    box-shadow: $box-shadow-lg !important;
}
.dds__shadow-none {
    box-shadow: none !important;
}
